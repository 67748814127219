var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fullscreen-wrapper"},[_c('div',{staticClass:"container"},[(_vm.anchors.isEmpty)?_c('h4',[_vm._v(" "+_vm._s(_vm.$t("messages.errorGatewayRequired"))+" ")]):_vm._e()]),(_vm.anchor)?[_c('div',{staticClass:"container fullscreen-wrapper__details"},[_c('div',{staticClass:"gateway-header"},[_c('div',{staticClass:"gateway-selector"},[_c('h3',[_vm._v(_vm._s(_vm.$tc("labels.vehicle", 0)))]),_c('el-select',{attrs:{"placeholder":_vm.$tc('placeholder.selectVehicle', 1)},on:{"change":_vm.updateSelectedAnchor},model:{value:(_vm.selectedAnchorId),callback:function ($$v) {_vm.selectedAnchorId=$$v},expression:"selectedAnchorId"}},_vm._l((_vm.resources.data.anchors),function(anchor){return _c('el-option',{key:anchor.anchorId,attrs:{"label":anchor.label,"value":anchor.anchorId}})}),1)],1),(_vm.resources.data.anchors.some(anchor => anchor.activeRoute))?_c('el-tooltip',{attrs:{"placement":"right","content":_vm.$t('tooltip.overallPredictions'),"hide-after":2000}},[_c('el-button',{attrs:{"size":"mini"},on:{"click":_vm.redirectToGlobalPredictions}},[_c('img',{attrs:{"src":require("@/assets/globe.svg"),"alt":_vm.$t('alt.globe')}})])],1):_vm._e()],1),(_vm.anchor.activeRoute)?_c('div',{staticClass:"fullscreen-wrapper__anchor"},[_c('b',[_vm._v(_vm._s(_vm.$t("labels.label"))+": ")]),_vm._v(" "+_vm._s(_vm.anchor.label)+" "),_c('b',[_vm._v("ID: ")]),_vm._v(" "+_vm._s(_vm.anchor.anchorId .toString(16) .padStart(12, "0") .toUpperCase() .match(/.{1,2}/g) .join(":"))+" "),_c('WifiIcon',{attrs:{"signal":_vm.anchor.rssi,"noConnection":_vm.anchor.lastHeartbeat
              ? _vm.isAnchorConnected(_vm.anchor.lastHeartbeat.createdDate)
              : false}}),(
            _vm.anchor.lastHeartbeat
              ? _vm.isAnchorConnected(_vm.anchor.lastHeartbeat.createdDate)
              : false
          )?_c('span',{staticClass:"anchor__offline"},[_vm._v(" "+_vm._s(_vm.$t("statusMessages.offline"))+" ")]):_vm._e()],1):_vm._e(),_c('div',{staticClass:"fullscreen-wrapper__options"},[(_vm.anchor.activeRoute)?[_c('div',{staticClass:"fullscreen-wrapper__options--left"},[_c('p',[_vm._v(_vm._s(_vm.$t("labels.tableTopEntry"))+":")]),_c('el-checkbox',{attrs:{"border":""},on:{"change":_vm.setData},model:{value:(_vm.basedOnGatewayPosition),callback:function ($$v) {_vm.basedOnGatewayPosition=$$v},expression:"basedOnGatewayPosition"}},[_vm._v(_vm._s(_vm.$t("labels.basedOnGatewayPosition")))]),_c('el-select',{attrs:{"placeholder":_vm.$t('placeholder.basedOnTag')},model:{value:(_vm.tagOnTopOrd),callback:function ($$v) {_vm.tagOnTopOrd=$$v},expression:"tagOnTopOrd"}},_vm._l((_vm.selectData),function(tag){return _c('el-option',{key:`(${tag.ord}) ${tag.label || tag.tagId}`,attrs:{"label":`(${tag.ord}) ${tag.label || tag.tagId}`,"value":tag.ord || ''}})}),1)],1),_c('div',{staticClass:"fullscreen-wrapper__options--right"},[_c('p',[_vm._v(_vm._s(_vm.$t("labels.numberOfTableElements"))+":")]),_c('el-input-number',{attrs:{"min":1,"max":Math.min(_vm.anchor.activeRoute.tags.length, 10)},on:{"change":_vm.setTableData},model:{value:(_vm.numberOfRows),callback:function ($$v) {_vm.numberOfRows=$$v},expression:"numberOfRows"}}),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.toggle}},[_c('i',{staticClass:"el-icon-full-screen"}),_vm._v(" "+_vm._s(_vm.$t("actions.goFullscreen")))])],1)]:_c('h4',[_vm._v(" "+_vm._s(_vm.$t("messages.errorGatewayRoutes.line1"))+" "),_c('router-link',{attrs:{"to":{
              name: 'user-settings',
              params: { tab: 'routes', anchorId: _vm.$route.params.anchorId }
            }}},[_vm._v(_vm._s(_vm.$t("messages.errorGatewayRoutes.line2")))]),_vm._v(" "+_vm._s(_vm.$t("messages.errorGatewayRoutes.line3"))+" ")],1)],2),(_vm.anchor.activeRoute)?_c('fullscreen',{ref:"predictions-fullscreen-mode",staticClass:"fullscreen__on",on:{"change":_vm.fullscreenChange}},[(_vm.predictions - _vm.fullscreen - _vm.mode)?_c('div',{staticClass:"fullscreen-wrapper__anchor"},[_c('b',[_vm._v(_vm._s(_vm.$t("labels.label"))+": ")]),_vm._v(" "+_vm._s(_vm.anchor.label)+" "),_c('b',[_vm._v("ID: ")]),_vm._v(" "+_vm._s(_vm.anchor.anchorId .toString(16) .padStart(12, "0") .toUpperCase() .match(/.{1,2}/g) .join(":"))+" "),_c('WifiIcon',{attrs:{"signal":_vm.anchor.rssi,"noConnection":_vm.anchor.lastHeartbeat
                ? _vm.isAnchorConnected(_vm.anchor.lastHeartbeat.createdDate)
                : false}}),(_vm.anchor.activeRouteStart)?[_vm._v(" | "),_c('b',[_vm._v(_vm._s(_vm.$t("labels.routeTime"))+": ")]),_c('span',{style:({
                color: _vm.computedColor,
                fontWeight: _vm.computedWeight
              })},[_c('span',[_vm._v(" "+_vm._s(_vm.getRouteTotalHours())+_vm._s(_vm.getRouteTotalMinutes())+"m:"+_vm._s(_vm.getRouteTotalSeconds())+"s ")]),(_vm.anchor.activeRouteOnTime)?_c('span',[_vm._v(" ("+_vm._s(_vm.getRouteDelay())+") ")]):_vm._e()])]:_vm._e()],2):_vm._e(),_c('div',{staticClass:"fullscreen-wrapper__details__clock"},[_c('i',{staticClass:"el-icon-timer"}),_vm._v(" "+_vm._s(_vm.moment.tz(this.clientData.timezone).format("HH:mm"))+" ")]),(_vm.isNewRoute)?_c('div',{staticClass:"warning-message-wrapper"},[_c('div',{staticClass:"warning-message"},[_c('i',{staticClass:"el-icon-warning"}),_c('span',[_vm._v(_vm._s(_vm.$t("messages.predictionsNewRoutes")))])])]):_vm._e(),(_vm.predictions - _vm.fullscreen - _vm.mode)?_c('div',{staticClass:"fullscreen__close",on:{"click":function($event){return _vm.toggle()}}},[_c('i',{staticClass:"el-icon-close"})]):_vm._e(),_c('el-table',{ref:"predictions",class:`table__rows--${Math.min(
              Math.max(5, _vm.tableData.length),
              Math.max(_vm.numberOfRows, 5)
            )}`,staticStyle:{"width":"100%"},attrs:{"highlight-current-row":"","row-class-name":_vm.tableRowClassName,"data":_vm.tableData,"border":""}},[_c('template',{slot:"empty"},[_vm._v(" "+_vm._s(_vm.$t("labels.noData"))+" ")]),_c('el-table-column',{attrs:{"resizable":false,"label":"ID"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(_vm.getTagInfo(scope.row.ord) .tagId.toString(16) .toUpperCase() .match(/.{1,2}/g) .join(""))+" ")]}}],null,false,90815019)}),_c('el-table-column',{attrs:{"resizable":false,"label":_vm.$t('labels.label')},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(_vm.getTagInfo(scope.row.ord).label)+" ")]}}],null,false,167274567)}),_c('el-table-column',{attrs:{"resizable":false,"label":_vm.$t('labels.arrivalTime')},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(_vm.moment .tz(_vm.moment.unix(scope.row.prediction), _vm.clientData.timezone) .format("HH:mm"))+" ")]}}],null,false,3816651130)}),_c('el-table-column',{attrs:{"resizable":false,"label":_vm.$t('labels.status')},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.state === 'LEFT')?_c('span',[_c('i',{staticClass:"el-icon-check"}),_vm._v(" "+_vm._s(_vm.$t("statusMessages.left"))+" ")]):(scope.row.state === 'CURRENTLY_HERE')?_c('span',[_c('i',{staticClass:"el-icon-check"}),_vm._v(" "+_vm._s(_vm.$t("statusMessages.currentlyHere"))+" ")]):(scope.row.state === 'ON_THE_WAY')?_c('span',[_c('i',{staticClass:"el-icon-loading"}),_vm._v(" "+_vm._s(_vm.$t("statusMessages.onTheWay"))+" ")]):(scope.row.state === 'WAITING')?_c('span',[_c('i',{staticClass:"el-icon-timer"}),_vm._v(" "+_vm._s(_vm.$t("statusMessages.waiting"))+" ")]):_vm._e()]}}],null,false,540325917)})],2)],1):_vm._e(),_c('PaymentWarning',{attrs:{"isVisible":_vm.clientData.warning == 1 && _vm.showPaymentWarning},on:{"hide-payment-warning":function($event){_vm.showPaymentWarning = false}}})],1)]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }