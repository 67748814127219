<script>
import { Pie } from "vue-chartjs";
import moment from "moment";

export default {
  extends: Pie,

  props: {
    data: {
      type: Array,
      required: false
    },
    labels: {
      type: Array,
      required: true
    },
    colors: {
      type: Array,
      default: () => ["#1F4258", "#36A2EB", "#72a9cd", "#b8d4e6", "#0f212c"]
    }
  },

  data() {
    return {
      type: "pie",
      chartdata: {
        filtered: false,
        labels: [],
        datasets: [
          {
            label: "Motion Distribution",
            backgroundColor: this.colors,
            data: this.data
          }
        ]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: true,
          position: "right",
          labels: {
            fontSize: 14,
            fontColor: "#333",
            padding: 10
          }
        },
        tooltips: {
          callbacks: {
            label: (tooltipItem, data) => {
              const dataset = data.datasets[tooltipItem.datasetIndex];
              const totalTime = dataset.data.reduce(
                (sum, value) => sum + value,
                0
              );
              const timeValue = dataset.data[tooltipItem.index];
              const formatedTime = this.formatLabels(timeValue);
              const percentage = ((timeValue / totalTime) * 100).toFixed(2);
              const label = data.labels[tooltipItem.index] || "";
              return `${label}: ${formatedTime} (${percentage}%)`;
            }
          }
        }
      }
    };
  },

  watch: {
    data: {
      deep: true,
      handler() {
        this.render();
      }
    },
    labels: {
      deep: true,
      handler() {
        this.render();
      }
    },
    colors: {
      deep: true,
      handler() {
        this.chartdata.datasets[0].backgroundColor = this.colors;
        this.render();
      }
    }
  },

  methods: {
    formatLabels(timestamps) {
      return moment.utc(timestamps * 1000).format("HH[h]mm[m]ss[s]");
    },
    render() {
      this.chartdata.datasets[0].data = Array.from(this.data, item =>
        item ? item : null
      );
      this.chartdata.datasets[0].backgroundColor = this.colors;
      this.chartdata.labels = this.labels;
      this.renderChart(this.chartdata, this.options);
    }
  },

  mounted() {
    this.render();
  }
};
</script>
