<template>
  <div class="tag-scanner">
    <div class="tag-scanner__container">
      <div class="tag-scanner__input-section">
        <span class="tag-scanner__input-title">{{
          $t("title.scanTagBeacon")
        }}</span>
        <el-input
          class="tag-scanner__input"
          v-model="scannedTagId"
          clearable
          @input="matchScannedTagId"
          @clear="matchScannedTagId"
          ref="scanInput"
        ></el-input>
      </div>
      <div class="tag-scanner__not-found" v-if="tagIdNotFound.length > 8">
        <span> {{ $t("labels.invalidId") }} </span>
      </div>
      <div class="tag-scanner__not-found" v-else-if="tagIdNotFound.length">
        <span> Id "{{ tagIdNotFound }}" {{ $t("labels.notFound") }} </span>
      </div>
      <div class="tag-scanner__details-section" v-if="tagInformation">
        <div class="tag-scanner__id-display">
          <span class="tag-scanner__id">
            {{ hexTagId }}
          </span>
        </div>
        <div class="tag-scanner__info">
          <div class="tag-scanner__info-detail">
            <span class="tag-scanner__info-label"
              >{{ $t("labels.type") }}:</span
            >
            <span class="tag-scanner__info-value">
              <template v-if="tagInformation.type === 1">{{
                $tc("labels.tag", 1)
              }}</template>
              <template v-else-if="tagInformation.type === 0">{{
                $tc("labels.beacon", 1)
              }}</template>
            </span>
          </div>
          <div class="tag-scanner__info-detail">
            <span class="tag-scanner__info-label"
              >{{ $t("labels.seenAt") }}:</span
            >
            <span class="tag-scanner__info-value">{{
              formattedLastUpdate
            }}</span>
          </div>
          <div class="tag-scanner__info-detail">
            <span class="tag-scanner__info-label"
              >{{ $t("labels.label") }}:</span
            >
            <div class="tag-scanner__actions">
              <span
                class="tag-scanner__info-value"
                v-if="!showEditLabelInput"
                >{{ tagInformation.label }}</span
              >
              <el-input
                class="tag-scanner__info-value"
                v-model="labelToEdit"
                clearable
                v-if="showEditLabelInput"
                ref="labelInput"
                @keydown.enter="handleKeydownEnter"
              ></el-input>
              <el-button
                size="mini"
                @click="showEditLabelInput = true"
                v-if="!showEditLabelInput"
              >
                <i class="el-icon-edit"></i>
              </el-button>
            </div>
          </div>
          <div
            class="tag-scanner__info-detail"
            v-if="tagInformation.type === 1"
          >
            <!-- <span class="tag-scanner__info-label"
              >{{ $t("labels.active") }}:</span
            > -->
            <span class="tag-scanner__info-value">
              <el-switch
                v-model="activeInput"
                active-text="Active"
                inactive-text="Inactive"
                @change="onActiveStatusChange"
              >
              </el-switch>
            </span>
          </div>
          <div class="tag-scanner__info-detail">
            <span class="tag-scanner__info-label"
              >{{ $t("labels.battery") }}:</span
            >
            <span v-if="tagInformation.battery === 3">
              <img src="@/assets/battery-full.svg" alt="full battery" />
            </span>
            <span v-else-if="tagInformation.battery === 2">
              <img src="@/assets/battery-high.svg" alt="high battery" />
            </span>
            <span v-else-if="tagInformation.battery === 1">
              <img src="@/assets/battery-medium.svg" alt="medium battery" />
            </span>
            <span v-else-if="isBatteryLow">
              <img src="@/assets/battery_dead.svg" alt="no battery" />
            </span>
          </div>
          <div
            class="tag-scanner__info-detail"
            v-if="tagInformation.type === 1"
          >
            <span class="tag-scanner__info-label-small"
              >{{ $t("labels.associatedBeacon") }}:</span
            >
            <span class="tag-scanner__info-value" v-if="!showBeaconInput">{{
              toHex(tagInformation.beaconId)
            }}</span>
            <el-input
              class="tag-scanner__info-value"
              v-model="associatedBeaconId"
              clearable
              v-if="showBeaconInput"
              ref="beaconInput"
            ></el-input>
            <el-button
              size="mini"
              @click="onShowBeaconInput()"
              v-if="!showBeaconInput"
            >
              <i class="el-icon-edit"></i>
            </el-button>
          </div>
          <div
            v-if="showEditLabelInput || showBeaconInput"
            class="tag-scanner__buttons"
          >
            <el-button
              @click="
                showEditLabelInput = false;
                showBeaconInput = false;
              "
              class="tag-scanner__custom-button"
              >{{ $t("button.cancel") }}</el-button
            >
            <el-button
              :disabled="
                labelToEdit === tagInformation.label && !showBeaconInput
              "
              class="tag-scanner__submit-button tag-scanner__custom-button"
              @click="updateTag(tagInformation.tagId, labelToEdit)"
              >{{ $t("button.submit") }}</el-button
            >
          </div>
        </div>
      </div>
    </div>
    <!-- <el-dialog
      title="Edit Tag Label"
      width="95%"
      top="2vh"
      :close-on-click-modal="false"
      :visible.sync="isDialogVisible.editLabelDialog"
      @closed="closeDialog('label')"
      v-if="tagInformation"
      class="tag-scanner__dialog"
    >
      <el-input v-model="labelToEdit" style="font-size: 20px"></el-input>
      <div slot="footer" class="tag-scanner__dialog-footer">
        <el-button
          @click="isDialogVisible.editLabelDialog = false"
          class="tag-scanner__footer-button"
          >Cancel</el-button
        >
        <el-button
          :disabled="labelToEdit === tagInformation.label"
          class="tag-scanner__submit-button tag-scanner__footer-button"
          @click="updateTag(tagInformation.tagId, labelToEdit)"
          >Submit</el-button
        >
      </div>
    </el-dialog> -->
    <el-dialog
      top="2vh"
      class="tag-scanner__dialog"
      :title="$t('dialogs.activateTag.title')"
      :visible.sync="isDialogVisible.editActiveDialog"
      :close-on-click-modal="false"
      @closed="activeInput = tagInformation.active"
    >
      <span class="warning"
        >{{
          $t("dialogs.activateTag.tagWarning", {
            action: $t(activeInput ? "actions.activate" : "actions.deactivate")
          })
        }}<br />
      </span>
      <span slot="footer" class="tag-scanner__dialog-footer">
        <el-button
          @click="isDialogVisible.editActiveDialog = false"
          size="small"
          class="tag-scanner__footer-button"
          >{{ $t("button.cancel") }}</el-button
        >
        <el-button
          type="primary"
          @click="updateTag(tagInformation.tagId, labelToEdit, true)"
          size="small"
          class="tag-scanner__submit-button tag-scanner__footer-button"
          >{{ $t("button.save") }}</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import tagsApi from "@/modules/tags/api/tags.api";
// import BatteryIcon from "@/components/icons/BatteryIcon.vue";
import moment from "moment-timezone";
import { mapState } from "vuex";

export default {
  name: "TagScanner",
  // components: {
  //   BatteryIcon
  // },
  data() {
    return {
      isDialogVisible: {
        editLabelDialog: false,
        editActiveDialog: false
      },
      isTagAvailableToReplace: false,
      labelToEdit: "",
      scannedTagId: "",
      tagIdNotFound: "",
      tagInformation: null,
      tags: [],
      tagToScan: "",
      validInput: false,
      warningInput: false,
      tagList: [],
      activeInput: false,
      showEditLabelInput: false,
      associatedBeaconId: null,
      showBeaconInput: false
    };
  },
  computed: {
    ...mapState("clients", {
      clientData: "data"
    }),
    ...mapState("user", {
      resources: "resources"
    }),
    formattedLastUpdate() {
      if (this.tagInformation && this.tagInformation.lastUpdate) {
        return moment
          .tz(
            moment.unix(this.tagInformation.lastUpdate),
            this.clientData.timezone
          )
          .format("lll");
      } else {
        return "";
      }
    },
    lastUpdateMoment() {
      if (this.tagInformation && this.tagInformation.lastUpdate) {
        return moment.tz(
          moment.unix(this.tagInformation.lastUpdate),
          this.clientData.timezone
        );
      } else {
        return null;
      }
    },
    isBatteryLow() {
      return this.tagInformation && this.tagInformation.battery == 0;
    },
    isRecentTag() {
      const oneWeekBeforeToday = moment().subtract(1, "week");
      return this.lastUpdateMoment
        ? this.lastUpdateMoment.isAfter(oneWeekBeforeToday)
        : true;
    },
    hexTagId() {
      return this.tagInformation && this.tagInformation.tagId
        ? this.toHex(this.tagInformation.tagId)
        : "";
    }
  },
  watch: {
    tagInformation(newValue) {
      if (newValue) {
        this.labelToEdit = newValue.label;
        this.activeInput = newValue.active;
        this.associatedBeaconId = newValue.beaconId
          ? this.toHex(newValue.beaconId)
          : null;
      } else {
        this.labelToEdit = "";
        this.activeInput = false;
        this.associatedBeaconId = null;
      }
    },
    showEditLabelInput(newValue) {
      if (newValue) {
        this.$nextTick(() => {
          const inputElement = this.$refs.labelInput?.$el?.querySelector(
            "input"
          );
          if (inputElement) {
            inputElement.focus();
            inputElement.scrollIntoView({ behavior: "smooth" });
          }
        });
      }
    }
  },
  methods: {
    async searchTag(tagId) {
      const foundTag = this.tagList.find(
        tag => this.toHex(tag.tagId) == tagId.toUpperCase()
      );

      if (foundTag) {
        this.tagInformation = foundTag;
        this.scannedTagId = "";
        this.showEditLabelInput = true;
        this.showBeaconInput = true;
        this.$refs.scanInput?.blur();
      } else {
        this.tagInformation = null;
        this.tagIdNotFound = this.scannedTagId;
        this.scannedTagId = "";
        this.$refs.scanInput?.blur();
      }
    },
    async updateTag(tagId, label, updateActiveInput = false) {
      try {
        let res;
        if (this.tagInformation.type === 0) {
          // Beacons
          res = await tagsApi.updateTag(tagId, {
            label: label,
            active: this.tagInformation.active,
            childBeacons: [],
            beaconsToRemove: []
          });
        } else if (this.tagInformation.type === 1) {
          // Tags
          const foundTag = this.tagList.find(
            tag => this.toHex(tag.tagId) == this.associatedBeaconId
          );
          let beaconId = null;
          if (!this.associatedBeaconId || (foundTag && foundTag.type === 0)) {
            beaconId = !this.associatedBeaconId ? null : foundTag.id;
            res = await tagsApi.updateTag(tagId, {
              label: label,
              active: updateActiveInput ? this.activeInput : label !== "",
              childBeacons: [],
              beaconsToRemove: [],
              beaconId: beaconId
            });
          } else {
            this.$notify.error({
              title: "Validation Error",
              message: this.$t("messages.invalidBeaconId")
            });
            return;
          }
        }
        if (res.data) {
          // this.isDialogVisible.editLabelDialog = false;
          this.isDialogVisible.editActiveDialog = false;
          this.showEditLabelInput = false;
          this.showBeaconInput = false;

          this.$notify({
            title: this.$t("statusMessages.success"),
            message: this.$t("messages.tagUpdated"),
            type: "success"
          });
          this.tagInformation.label = res.data.label;
          this.tagInformation.active = res.data.active;
          this.activeInput = res.data.active;
          this.tagInformation.beaconId = res.data.beaconId;
          this.getTagList();
        }
      } catch (error) {
        this.$notify.error({
          title: this.$t("statusMessages.error"),
          message: this.$t("messages.genericError")
        });
      }
    },
    matchScannedTagId(tagId) {
      this.tagIdNotFound = "";
      const isValidTag = tagId?.length === 8;

      if (!isValidTag) {
        this.tagInformation = null;
        this.tagIdNotFound = tagId?.length > 8 ? tagId : "";
      } else {
        this.searchTag(tagId);
      }
    },
    closeDialog(typeOfDialog) {
      if (typeOfDialog === "label") {
        this.labelToEdit = this.tagInformation?.label ?? "";
      } else if (typeOfDialog === "replace") {
        this.tagToReplace = "";
      }
    },
    handleKeyPress(e) {
      if (!this.showEditLabelInput && !this.showBeaconInput) {
        this.$refs?.scanInput?.focus();
      } else if (
        this.showEditLabelInput &&
        (e.key === "Enter" || e.code === "Enter")
      ) {
        this.$refs?.labelInput?.blur();
        if (this.showBeaconInput) {
          this.$refs?.beaconInput?.focus();
        }
      }
    },
    handleKeydownEnter() {
      this.$nextTick(() => {
        const inputElement = this.$refs.labelInput?.$el?.querySelector("input");
        console.log(inputElement);
        if (inputElement) {
          inputElement.blur();
        }
      });
    },
    toHex(str) {
      return str
        ? str
            .toString(16)
            .toUpperCase()
            .match(/.{1,2}/g)
            .join("")
        : "";
    },

    async getTagList() {
      try {
        const res = await tagsApi.getRefreshStatus();
        this.tagList = res.data;
      } catch (error) {
        this.$notify.error({
          title: this.$t("statusMessages.error"),
          message: this.$t("messages.genericError")
        });
      }
    },
    onActiveStatusChange() {
      if (this.tagInformation.active !== this.activeInput) {
        this.isDialogVisible.editActiveDialog = true;
      }
    },

    onShowBeaconInput() {
      this.showBeaconInput = true;
      this.associatedBeaconId = null;
      this.$nextTick(() => {
        const inputElement = this.$refs.beaconInput?.$el?.querySelector(
          "input"
        );
        if (inputElement) {
          inputElement.focus();
        }
      });
    }
  },
  mounted() {
    document.addEventListener("keydown", this.handleKeyPress);
  },

  beforeUnmount() {
    document.removeEventListener("keydown", this.handleKeyPress);
  },

  created() {
    this.getTagList();
  }
};
</script>

<style lang="scss">
.tag-scanner {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
  font-size: 16px;
  min-height: 70vh;

  @media (max-width: $xs) {
    font-size: 18px;
  }

  &__display-error {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    height: 100%;

    @media (max-width: $sm) {
      display: none;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    gap: 50px;
    height: 100%;
    padding: 40px 10px 30px;
    max-width: 500px;
    min-width: 350px;

    @media (max-width: 380px) {
      padding-top: 15px;
    }

    @media (max-width: 500px) {
      min-width: 100%;
      max-width: none;
    }
  }

  &__input-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  .el-switch__label {
    font-size: 16px !important;
  }
  &__dialog {
    .el-dialog {
      width: 400px;

      @media (max-width: $xs) {
        width: 95%;
      }
    }

    .el-dialog__title {
      font-size: 16px;

      @media (max-width: $xs) {
        font-size: 20px;
      }
    }
    .el-dialog__body {
      display: flex;
      flex-direction: column;
      gap: 20px;
      padding-bottom: 5px;
      word-break: auto-phrase;
      font-size: 16px;

      @media (max-width: $xs) {
        font-size: 20px;
      }
    }
    .el-select .el-input--suffix {
      width: 100%;
      font-size: 16px;

      @media (max-width: $xs) {
        font-size: 20px;
      }
    }
  }

  // &__warning-input {
  //   ::v-deep .el-input__inner {
  //     border-color: #e50000 !important;

  //     &:focus {
  //       border-color: #e50000 !important;
  //     }

  //     &:not(:focus) {
  //       border-color: #e50000 !important;
  //     }
  //   }
  // }

  // &__valid-input {
  //   ::v-deep .el-input__inner {
  //     border-color: green !important;

  //     &:focus {
  //       border-color: green !important;
  //     }

  //     &:not(:focus) {
  //       border-color: green !important;
  //     }
  //   }
  // }

  // &__warning-message {
  //   display: flex;
  //   justify-content: center;
  //   color: #e50000;
  //   font-size: 20px;
  // }

  &__dialog-footer {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;
  }

  &__footer-button {
    height: 65px !important;
    font-size: 16px !important;

    @media (max-width: $xs) {
      font-size: 18px !important;
    }

    @media (max-width: 380px) {
      height: 60px !important;
    }
  }

  &__buttons {
    display: flex;
    width: 100%;
    padding-top: 20px;
  }

  &__custom-button {
    height: auto;
    font-size: 14px;
    width: 50% !important;

    @media (max-width: $xs) {
      font-size: 18px;
      height: 65px;
    }

    @media (max-width: 380px) {
      height: 60px;
    }
  }

  &__submit-button {
    background-color: #1f4258;
    color: #ffffff;
    margin-left: 0 !important;
  }

  &__input {
    width: 100%;
  }

  &__not-found {
    height: 100%;
    color: red;
    font-weight: bold;
    padding-top: 20px;
  }

  &__details-section {
    display: flex;
    flex-direction: column;
    gap: 25px;

    @media (max-width: 380px) {
      gap: 20px;
    }
  }

  &__id-display {
    font-size: 24px;
    font-weight: bold;
  }

  &__id {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }

  &__info {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
  }

  &__info-detail {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  &__inactive-state {
    display: flex;
    justify-content: center;
    align-self: center;
    height: 400px;
  }

  &__inactive-state.red-text {
    font-weight: bold;
    color: #e50000;
  }

  &__info-detail.red-text {
    font-weight: bold;
    color: #e50000;
  }

  &__info-label {
    text-align: left;
  }

  &__info-label-small {
    text-align: left;
    width: 110px;
  }

  &__info-value {
    font-weight: bold;
    white-space: nowrap;
  }

  &__actions {
    display: flex;
    align-items: center;
    gap: 10px;
    max-width: calc(100vw - 150px);

    .tag-scanner__info-value {
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: calc(100vw - 150px);
    }
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    width: 100%;
    height: auto;
    font-size: 16px;
    background-color: #1f4258;
    color: #ffffff;
    margin-left: 0 !important;

    &:hover,
    &:active,
    &:focus {
      background-color: #5d707d !important;
      color: #ffffff !important;
      border: none;
    }

    @media (max-width: 380px) {
      font-size: 18px;
      height: 65px;
    }

    @media (max-width: $xs) {
      font-size: 20px;
      height: 70px;
    }
  }
  &__button.red-button {
    background-color: #e50000;

    &:focus {
      background-color: #ff6f61 !important;
    }
  }

  &__button-info {
    display: flex;
    align-items: center;
    gap: 10px;
  }
}
</style>
