<template>
  <div
    :class="[
      'dashboard',
      isEditing ? 'dashboard--editing' : '',
      drawSlot ? 'dashboard--has-slot' : ''
    ]"
  >
    <div class="container" v-if="!clientData.mapImages.length">
      <div class="no-layout-message">
        <h4>
          {{ $t("messages.noLayoutAssociated") }}
        </h4>
      </div>
    </div>
    <div>
      <div class="dashboard__options-bar" v-if="$route.name === 'dashboard'">
        <div class="dashboard__options-bar__left-div">
          <div class="header-element" v-if="sidebarAnchor">
            <div class="gateway">
              <span class="gateway-label" v-if="!isTracker"
                ><b>{{ $tc("labels.vehicle", 0) }}: </b>
              </span>
              <span class="gateway-label" v-else
                ><b>{{ $tc("labels.gateway", 1) }}: </b>
              </span>
              <div class="gateway-selector">
                <el-select
                  v-model="selectedAnchorId"
                  :placeholder="$tc('placeholder.selectGateway', 1)"
                  @change="updateSelectedAnchor"
                  size="small"
                >
                  <el-option
                    v-for="anchor in resources.data.anchors"
                    :key="anchor.anchorId"
                    :label="anchor.label"
                    :value="anchor.anchorId"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
            <span
              v-if="sidebarAnchor.rssi && $route.name === 'dashboard'"
              class="margin-left"
            >
              <WifiIcon
                :signal="sidebarAnchor.rssi"
                :noConnection="
                  isAnchorOffline(
                    sidebarAnchor.powerSource,
                    sidebarAnchor.offlineTs
                  )
                "
                class="options-bar__resource--rssi"
              />
            </span>
            <template
              v-if="
                isAnchorPowerSourceDisconnected(
                  sidebarAnchor.lastHeartbeat
                    ? sidebarAnchor.lastHeartbeat
                    : null
                ) && $route.name === 'dashboard'
              "
            >
              <span>
                <img src="@/assets/battery_dead.svg" alt="no battery" />
              </span>
            </template>
            <span class="header-separator" v-if="$route.name === 'dashboard'"
              >|</span
            >
          </div>
          <div
            v-if="
              resourceInfo.details.lastPositionMetadata &&
                resourceInfo.details.lastPositionMetadata.state &&
                $route.name === 'dashboard'
            "
            class="header-element"
          >
            <b
              v-if="
                resourceInfo.details.lastPositionMetadata.state === 'ENTERED'
              "
              class="gateway"
              >{{ $t("labels.at") }}:
            </b>
            <b
              v-else-if="
                resourceInfo.details.lastPositionMetadata.state === 'LEFT'
              "
              class="gateway"
              >{{ $t("statusMessages.left") }}:
            </b>
            <div class="margin-left">
              <TruncatedTooltip
                :max-width="windowWidth < 1440 ? '18vw' : '10vw'"
                :content="
                  findTagOnLastAnchorPosition(
                    sidebarAnchor.lastPositionMetadata?.tagPositionId
                  )
                "
              >
                {{
                  findTagOnLastAnchorPosition(
                    sidebarAnchor.lastPositionMetadata?.tagPositionId
                  )
                }}
              </TruncatedTooltip>
            </div>
            <span v-if="!isTracker" class="header-separator">|</span>
          </div>

          <div
            v-if="$route.name === 'dashboard' && isTracker && isAzitek"
            class="header-element conditional-blue-text"
          >
            <b class="route-time"> {{ $t("labels.with") }}: </b>
            <span v-if="sidebarAnchor.currentTagId && !sidebarAnchor.offline">
              <span class="route-time__total-hours">
                {{ sidebarAnchor.currentTagLabel }} ({{
                  toHex(sidebarAnchor.currentTagId)
                }})
              </span>
            </span>
            <span v-else>
              ---------------
            </span>
          </div>

          <div
            v-if="$route.name === 'dashboard' && !isTracker && activeRoute"
            class="header-element"
          >
            <span class="route">
              <b>{{ $tc("labels.route", 1) }}: </b> {{ activeRoute.label }}
            </span>
            <span class="header-separator">|</span>
          </div>
          <div
            v-if="$route.name === 'dashboard' && !isTracker"
            class="header-element"
          >
            <b class="route-time"> {{ $t("labels.routeTime") }}: </b>
            <span
              v-bind:style="{
                color: computedColor,
                fontWeight: computedWeight
              }"
              v-if="resourceInfo.details.currentRouteInstance"
            >
              <span class="route-time__total-hours">
                {{ currentTime }}
              </span>
              <span v-if="resourceInfo.details.activeRouteOnTime">
                ({{ getRouteDelay }})
              </span>
            </span>
            <div v-else>
              <span class="route-time__no-time">
                ---------------
              </span>
              <span class="route-time__no-time-small">
                -
              </span>
            </div>
          </div>
        </div>
        <div class="options-bar__highlighted-beacon">
          <template v-if="beaconToHighlight">
            <span class="options-bar__highlighted-beacon__beacon">
              <b>{{ $tc("labels.beacon", 1) }}: </b>
            </span>
            <span
              v-if="beaconToHighlight.label"
              class="options-bar__highlighted-beacon__label"
            >
              {{ beaconToHighlight.label }}
            </span>
            <template v-if="beaconToHighlight.label">
              <span class="options-bar__highlighted-beacon__tag_id">
                {{ "(" }}
              </span>
            </template>
            <span>
              {{
                beaconToHighlight.tagId
                  .toString(16)
                  .toUpperCase()
                  .match(/.{1,2}/g)
                  .join("")
              }}
            </span>
            <template v-if="beaconToHighlight.label">
              {{ ")" }}
            </template>

            <span class="options-bar__edit" @click="editTag()">
              <i class="el-icon-edit"></i>
              <i>{{ $t("actions.edit") }}</i>
            </span>
          </template>
        </div>
        <div class="search-span">
          <el-select
            v-model="search"
            :placeholder="$t('placeholder.selectBeacon')"
            filterable
            size="medium"
            class="search-input"
          >
            <el-option
              v-for="tag in beaconOptions"
              :key="`tag.label${tag.tagId}`"
              :label="
                `(${tag.tagId
                  .toString(16)
                  .toUpperCase()
                  .match(/.{1,2}/g)
                  .join('')}) ${tag.label}`
              "
              :value="tag.id"
            ></el-option>
          </el-select>
        </div>

        <!-- <el-button
          type="primary"
          size="mini"
          plain
          @click="areMapControlsVisible = !areMapControlsVisible"
        >
          <i class="el-icon-view"></i><span>Toggle Controls</span></el-button
        > -->
      </div>

      <!-- <el-button
        type="primary"
        size="mini"
        plain
        @click="areMapControlsVisible = !areMapControlsVisible"
      >
        <i class="el-icon-view"></i><span>Toggle Controls</span></el-button
      > -->
    </div>

    <div
      :class="
        stateAnchors.isEmpty
          ? istracker
            ? 'dashboard__map-wrapper-no-anchors__tracker'
            : 'dashboard__map-wrapper-no-anchors'
          : isTracker
          ? 'dashboard__map-wrapper__tracker'
          : 'dashboard__map-wrapper'
      "
      v-if="clientData.mapImages.length"
    >
      <div id="image-map" class="dashboard__map">
        <div
          :class="dashboardSlotOpen ? 'map-select' : 'map-select-slot'"
          v-if="isLiveMap && isTracker"
        >
          <el-select
            v-model="selectedAnchorIds"
            :placeholder="$t('placeholder.selectGatewaysMap')"
            multiple
            collapse-tags
            size="large"
            @change="updateSelectedAnchors"
            :style="{
              width: `${$t('placeholder.selectGatewaysMap').length * 8.5}px`
            }"
          >
            <el-option
              v-for="anchor in anchors"
              :key="anchor.anchorId"
              :label="anchor.label"
              :value="anchor.anchorId"
              :disabled="
                isAnchorOffline(anchor.powerSource, anchor.offlineTs) ||
                  !anchor.lastAnchorPositionId
              "
            >
              <div class="color-indicator-container">
                <span
                  :style="`background-color: ${anchor.color}`"
                  class="color-indicator"
                ></span>
              </div>
              <span style="float: left">{{ anchor.label }}</span>
            </el-option>
          </el-select>
        </div>
      </div>
      <el-tooltip
        class="item"
        effect="dark"
        :content="$t('tooltip.controlSizeBeacon')"
        placement="right"
      >
        <div
          v-if="areMapControlsVisible"
          :class="
            $route.name === 'user-settings'
              ? 'dashboard__tag-slider-settings'
              : selectedAnchor && !isTracker
              ? 'dashboard__tag-slider'
              : 'dashboard__tag-slider-no-anchor'
          "
        >
          <el-slider
            v-if="currentWidth != 0"
            v-model="resourcesSize"
            vertical
            :min="currentWidth * 0.001"
            :max="currentWidth * 0.005"
            :show-tooltip="false"
            :step="currentWidth * 0.001"
            show-stops
          />
        </div>
      </el-tooltip>
      <el-tooltip
        class="item"
        effect="dark"
        :content="$t('tooltip.fitMapZoom')"
        placement="right"
      >
        <div
          v-if="areMapControlsVisible"
          :class="
            $route.name === 'user-settings'
              ? 'dashboard__fit-to-bounds-settings'
              : selectedAnchor && !isTracker
              ? 'dashboard__fit-to-bounds'
              : 'dashboard__fit-to-bounds-no-anchor'
          "
          @click="fitMapToBounds()"
        >
          <i class="el-icon-menu" />
        </div>
      </el-tooltip>
      <el-button
        v-if="isEditing && $route.name === 'dashboard'"
        :class="[
          !dashboardSlotOpen
            ? 'dashboard__close-edit__sidebar'
            : 'dashboard__close-edit'
        ]"
        @click="verifyChanges"
        type="primary"
        size="mini"
        icon="el-icon-close"
      >
      </el-button>

      <div
        v-if="
          (isLiveMap ||
            (isStatsPage && windowWidth < 1740) ||
            ($route.name === 'stats-globals' && windowWidth < 1740)) &&
            this.clientData.mapImages.length &&
            !isEditing &&
            !stateAnchors.isEmpty
        "
        :class="[
          dashboardSlotOpen
            ? $route.name === 'stats-globals' ||
              this.isStatsPage ||
              windowWidth <= 768
              ? 'toggle-dashboard-slot'
              : 'toggle-dashboard-slot--close'
            : 'toggle-dashboard-slot',
          isLiveMap ? 'toggle-dashboard-slot__bg-white' : ''
        ]"
        @click="onDashboardSlotOpenClick"
      >
        <template>
          <template v-if="!dashboardSlotOpen"
            ><i class="el-icon-back" />
            <template v-if="$route.name === 'dashboard'">
              {{ $t("labels.metrics") }}
            </template>
            <template v-else-if="isStatsPage">
              {{ $t("labels.laps") }}
            </template>
            <template v-else-if="$route.name === 'stats-globals'">
              {{ $t("labels.activity") }}
            </template>
          </template>
          <template
            v-if="
              dashboardSlotOpen &&
                $route.name !== 'stats-globals' &&
                !this.isStatsPage &&
                windowWidth > 768
            "
          >
            <i class="el-icon-close"
          /></template>
          <template v-else-if="dashboardSlotOpen">
            {{ $t("header.liveMap") }}
            <i class="el-icon-right"
          /></template>
        </template>
      </div>

      <div class="dashboard__checkbox-container">
        <div class="checkbox-container-left">
          <div
            class="checkbox-item"
            v-if="
              this.$route.name === 'stats-globals' ||
                this.$route.name === 'stats-laps-details'
            "
          >
            <el-checkbox
              v-model="frequencyHeatmapActive"
              size="medium"
              border
              class="checkbox-item__model"
              >{{ $t("mapCheckBox.frequency") }}</el-checkbox
            >
          </div>
          <div
            class="checkbox-item"
            v-if="
              this.$route.name === 'stats-globals' ||
                this.$route.name === 'stats-laps-details'
            "
          >
            <el-checkbox
              v-model="averageTimeHeatmapActive"
              size="medium"
              border
              class="checkbox-item__model"
              >{{ $t("mapCheckBox.avgTime") }}</el-checkbox
            >
          </div>
        </div>
        <div class="checkbox-container-right">
          <div
            class="checkbox-item"
            v-if="
              this.$route.name === 'stats-globals' ||
                this.$route.name === 'stats-laps-details'
            "
          >
            <el-checkbox
              v-model="spaghettiFrequencyDiagramActive"
              size="medium"
              border
              class="checkbox-item__model"
              :disabled="!tripFrequency"
              >{{ $t("mapCheckBox.segmentFreq") }}</el-checkbox
            >
          </div>
          <div
            class="checkbox-item"
            v-if="
              this.$route.name === 'stats-globals' ||
                this.$route.name === 'stats-laps-details'
            "
          >
            <el-checkbox
              v-model="spaghettiTimeDiagramActive"
              size="medium"
              border
              class="checkbox-item__model"
              :disabled="!tripFrequency"
              >{{ $t("mapCheckBox.segmentAvgTime") }}</el-checkbox
            >
          </div>
        </div>
      </div>

      <div
        :class="['dashboard__slider']"
        v-if="
          this.$route.name === 'stats-globals' ||
            this.$route.name === 'stats-laps-details'
        "
      >
        <el-slider
          v-model="heatmapSize"
          vertical
          :min="1"
          :max="2"
          :step="0.25"
          :show-tooltip="false"
          show-stops
        />
      </div>

      <div
        :class="[
          isTracker ? 'dashboard__slot__tracker' : 'dashboard__slot',
          !dashboardSlotOpen ? 'dashboard__slot__close' : ''
        ]"
        v-if="!stateAnchors.isEmpty"
      >
        <template v-if="drawSlot">
          <slot v-if="$slots.default && dashboardSlotOpen" />

          <LiveMapAside
            v-if="isLiveMap || isTracker"
            :is-live-map="isLiveMap"
            :is-tracker="isTracker"
            :is-editing="isEditing"
            :current-time="currentTime"
            :client-data="clientData"
            :state-anchors="stateAnchors"
            :sidebar-anchor="sidebarAnchor"
            :last-five="lastFive"
            :tag-beacon-list="tagBeaconList"
            :beacon-list="beaconList"
            @fetch-asset-history="fetchAssetHistory"
            @update-highlighted-beacon="updateHighlightedBeacon"
            @show-tag-label-on-map="showTagLabelOnMap"
          />
        </template>
      </div>
    </div>

    <!-- <el-dialog
      title="Edit Beacon"
      :visible.sync="editTagDialog.visible"
      width="1000px"
    >
      <div class="edit-beacon-values">
        <span>
          <b>ID</b>
        </span>
        <span>
          <b>{{ $t("dialogs.defaultEdit.line1") }}</b>
        </span>
        <span>
          <b>{{ $t("dialogs.defaultEdit.line2") }}</b>
        </span>
      </div>
      <div class="edit-beacon-values">
        <span>
          {{
            tagForm.tagId
              .toString(16)
              .toUpperCase()
              .match(/.{1,2}/g)
              .join("")
          }}
        </span>
        <span>
          <template v-if="tagForm.battery">
            <BatteryIcon :battery="tagForm.battery" />
          </template>
          <template v-else>
            {{ $t("message.na") }}
          </template>
        </span>
        <span>
          {{ moment.utc(tagForm.lastSeen * 1000).format("MMM DD YYYY, HH:mm") }}
        </span>
      </div>
      <el-form
        label-position="right"
        :model="tagForm"
        :rules="rules"
        ref="tagForm"
        hide-required-asterisk
        @submit.native.prevent
      >
        <div class="edit-beacon-form">
          <div>
            <el-form-item
              :label="$t('message.setBeaconLabel')"
              prop="label"
              class="edit-beacon-label"
            >
              <el-input
                type="text"
                v-model="tagForm.label"
                autocomplete="off"
                @keyup.enter.native="saveTag(false)"
              />
            </el-form-item>
            <el-form-item
              :label="$t('message.setBeaconState')"
              class="edit-beacon-status"
            >
              <el-checkbox v-model="tagForm.active" size="small">{{
                $t("message.active")
              }}</el-checkbox>
            </el-form-item>
            <el-form-item
              :label="$t('message.setBeaconRange')"
              class="edit-beacon-range-level"
            >
              <span>
                <el-slider
                  v-model="tagForm.threshold"
                  :marks="marks"
                  :step="1"
                  :min="1"
                  :max="16"
                >
                </el-slider>
              </span>
            </el-form-item>
            <el-form-item
              :label="$t('message.replaceBeacon')"
              class="edit-beacon-replace"
            >
              <el-select
                v-model="tagToReplace"
                :placeholder="$t('placeholder.select', { msg: 'Beacon'})"
                filterable
                class="select-tags"
              >
                <el-option
                  v-for="tag in inactiveTags"
                  :key="`tag.label${tag.tagId}`"
                  :label="
                    `(${tag.tagId
                      .toString(16)
                      .toUpperCase()
                      .match(/.{1,2}/g)
                      .join('')}) ${tag.label}`
                  "
                  :value="tag.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="Status"
              class="edit-beacon-status-range"
              v-if="isTracker"
            >
              <el-select
                v-model="tagForm.status"
                placeholder="Select Status"
                filterable
                class="select-status"
              >
                <el-option
                  v-for="status in statusOptions"
                  :key="`tag.status-${status}`"
                  :label="status"
                  :value="status"
                ></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div>
            <el-form-item label="Child Beacons" class="edit-beacon-children">
              <div class="edit-beacon-children">
                <div>
                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="$t('tooltip.childBeaconsShare')"
                    placement="right"
                  >
                    <el-select
                      v-model="childTagToAdd"
                      :placeholder="$t('placeholder.select', { msg: 'Beacon'})"
                      filterable
                      class="edit-beacon-select-tags"
                      :disabled="
                        highlightedBeacon
                          ? highlightedBeacon.parentId != null
                          : highlightedBeacon
                      "
                    >
                      <el-option
                        v-for="tag in orphanTags"
                        :key="`tag.label${tag.tagId}`"
                        :label="
                          `(${tag.tagId
                            .toString(16)
                            .toUpperCase()
                            .match(/.{1,2}/g)
                            .join('')}) ${tag.label}`
                        "
                        :value="tag.tagId"
                      ></el-option>
                    </el-select>
                  </el-tooltip>
                </div>
                <div class="add-tag-btn">
                  <el-button
                    type="primary"
                    size="small"
                    icon="el-icon-plus"
                    circle
                    @click="addTagToGroup"
                  />
                </div>
              </div>
            </el-form-item>
            <div>
              <p class="parent-info" v-if="parentInfo">
                {{ $t("message.thisBeaconChild") }}
                {{
                  parentInfo.tagId
                    .toString(16)
                    .toUpperCase()
                    .match(/.{1,2}/g)
                    .join("") +
                    " (" +
                    parentInfo.label +
                    ")."
                }}
              </p>
              <div
                class="edit-beacon-child-tags"
                v-for="tag in tagsSelected"
                :key="tag.id"
              >
                <div />
                <div class="anchor-list">
                  <div class="background-animation">
                    <span class="anchor-list__id">
                      {{
                        `${tag.id
                          .toString(16)
                          .toUpperCase()
                          .match(/.{1,2}/g)
                          .join("")} (${tag.label})`
                      }}
                    </span>
                  </div>
                  <span class="anchor-list__delete">
                    <el-button
                      size="small"
                      type="danger"
                      class="el-icon-delete"
                      circle
                      @click="removeTagFromSelected(tag)"
                    />
                  </span>
                </div>
              </div>
              <div v-if="tagToEdit.childTags">
                <div
                  class="edit-beacon-child-tags"
                  v-for="tag in tagToEdit.childTags"
                  :key="tag.id"
                >
                  <div></div>
                  <div class="anchor-list">
                    <div class="label-div">
                      <span class="anchor-list__id">
                        {{
                          `${tag.id
                            .toString(16)
                            .toUpperCase()
                            .match(/.{1,2}/g)
                            .join("")} (${tag.label})`
                        }}
                      </span>
                    </div>
                    <span class="anchor-list__delete">
                      <el-button
                        size="small"
                        type="danger"
                        class="el-icon-delete"
                        circle
                        @click="removeTag(tag)"
                      />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="tagForm.error.length"
          class="el-form-item__error el-form-item__error--server"
        >
          {{ tagForm.error }}
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer" style="marginTop: -10%">
        <el-button
          @click="editTagDialog.visible = false"
          :disabled="editTagDialog.loading"
          >{{ $t("button.cancel") }}</el-button
        >
        <el-button
          type="primary"
          @click="confirmSave"
          :loading="editTagDialog.loading"
          >{{ $t("button.save") }}</el-button
        >
      </span>
    </el-dialog> -->

    <!-- <SaveChangesDialog 
      :isVisible="certifyEditTagDialogSave"
      @cancel-save="certifyEditTagDialogSave = false"
      @confirm-save="finalSaveTag"
    /> -->

    <EditDialog
      assetType="beacon"
      :assetPrevInfo="tagToEdit"
      :isVisible="editTagDialog.visible"
      :rules="rules"
      @tag-updated="handleUpdateTag"
      @tag-destroyed="handleTagDestroyed"
      @update-edit-dialog-visibility="value => (editTagDialog.visible = value)"
    />

    <PaymentWarning
      :isVisible="clientData.warning == 1 && showPaymentWarning"
      @hide-payment-warning="showPaymentWarning = false"
    />

    <el-dialog
      :title="$t('dialogs.discardChanges.title')"
      :visible.sync="certifyEditTagDialogDiscard"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :width="windowWidth < 500 ? '95%' : '500px'"
    >
      <span class="warning"
        >{{ $t("dialogs.discardChanges.line1") }} <br />
        {{ $t("dialogs.discardChanges.line2") }} <br />
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="certifyEditTagDialogDiscard = false" size="small">{{
          $t("button.continueEditing")
        }}</el-button>
        <el-button @click="discardChanges" type="danger" size="small">{{
          $t("button.discard")
        }}</el-button>
      </span>
    </el-dialog>

    <el-dialog
      :title="$t('dialogs.discardChanges.title')"
      :visible.sync="discardChangesDialog"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :width="windowWidth < 500 ? '95%' : '500px'"
    >
      <span class="warning"
        >{{ $t("dialogs.discardChanges.line1") }} <br />
        {{ $t("dialogs.discardChanges.line2") }} <br />
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="discardChangesDialog = false" size="small">{{
          $t("button.continueEditing")
        }}</el-button>
        <el-button type="danger" @click="discardBeaconChanges" size="small">{{
          $t("button.discard")
        }}</el-button>
      </span>
    </el-dialog>

    <el-dialog
      :title="$t('dialogs.replaceBeacon.title')"
      :visible.sync="replaceTagDialog.visible"
      width="300px"
    >
      <p>{{ $t("dialogs.replaceBeacon.line1") }}</p>
      <el-select
        v-model="replaceTagForm.newId"
        :placeholder="$t('placeholder.selectBeacon')"
        filterable
        class="select-tags"
      >
        <el-option
          v-for="tag in inactiveTags"
          :key="`tag.label${tag.tagId}`"
          :label="
            `(${tag.tagId
              .toString(16)
              .toUpperCase()
              .match(/.{1,2}/g)
              .join('')}) ${tag.label}`
          "
          :value="tag.id"
        ></el-option>
      </el-select>
      <span slot="footer" class="dialog-footer">
        <el-button
          @click="replaceTagDialog.visible = false"
          :disabled="replaceTagDialog.loading"
          >{{ $t("button.cancel") }}</el-button
        >
        <el-button
          type="primary"
          @click="replaceTag()"
          :loading="replaceTagDialog.loading"
          >{{ $t("button.save") }}</el-button
        >
      </span>
    </el-dialog>

    <el-dialog
      :title="$t('dialogs.information.title')"
      width="420px"
      :show-close="false"
      :visible.sync="gatewaysAffectedDialog"
    >
      <ul>
        <div class="select-route-intro">
          {{ $t("dialogs.information.line1") }}
        </div>
        <ul
          class="select-route-list"
          v-for="item in gatewaysAffected"
          :key="item"
        >
          <li class="select-route-checkbox">{{ item }}</li>
        </ul>
        <div class="button-select-route">
          <el-button
            type="primary"
            size="mini"
            @click="submitGatewaysAffectedDialog"
            >OK</el-button
          >
        </div>
      </ul>
    </el-dialog>

    <el-dialog
      :title="$t('dialogs.assetHistory.title')"
      :visible.sync="tagHistoryDialog.visible"
      @close="tagHistory.history = []"
      custom-class="el-dialog-asset-history"
    >
      <AssetHistoryTable :asset-list="tagHistory.history" />
    </el-dialog>

    <el-dialog
      :title="$t('dialogs.moveBeacon.title')"
      :visible.sync="moveTagDialog.visible"
      :custom-class="'move-beacon'"
      @close="closeMoveTagDialog"
    >
      <span>{{ $t("dialogs.moveBeacon.line1") }}</span
      ><br />

      <el-select
        v-model="moveTagDialog.tagToMoveId"
        :placeholder="$t('placeholder.selectBeacon')"
        filterable
        class="select-tags"
      >
        <el-option
          v-for="tag in tagsToShow"
          :key="`tag.label${tag.tagId}`"
          :label="
            `(${tag.tagId
              .toString(16)
              .toUpperCase()
              .match(/.{1,2}/g)
              .join('')}) ${tag.label}`
          "
          :value="tag.id"
        ></el-option>
      </el-select>

      <div class="radio-div">
        <label class="radio-label" for="all">
          <input
            type="radio"
            id="all"
            v-bind:value="'all'"
            v-model="activeChecked"
            class="radio-input"
          />
          {{ $t("labels.all") }} ({{
            getActiveTagsLength + getInactiveTagsLength
          }})
        </label>
        <label class="radio-label" for="active">
          <input
            type="radio"
            id="active"
            v-bind:value="true"
            v-model="activeChecked"
            class="radio-input"
          />
          {{ $t("labels.active") }} ({{ getActiveTagsLength }})</label
        >
        <label class="radio-label" for="inactive">
          <input
            type="radio"
            id="inactive"
            v-bind:value="false"
            v-model="activeChecked"
            class="radio-input"
          />
          {{ $t("labels.inactive") }} ({{ getInactiveTagsLength }})</label
        >
      </div>

      <el-form
        label-position="right"
        :model="moveBeaconForm"
        :rules="rules"
        ref="moveBeaconForm"
        hide-required-asterisk
        @submit.native.prevent
      >
        <el-form-item
          :label="$t('actions.setBeaconLabel')"
          prop="label"
          class="edit-beacon-move"
        >
          <el-input
            type="text"
            v-model="moveBeaconForm.label"
            autocomplete="off"
            @keyup.enter.native="saveTagLabel()"
          />
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="closeMoveTagDialog">{{
          $t("button.cancel")
        }}</el-button>
        <el-button type="primary" @click="moveTagToPosition">{{
          $t("button.confirm")
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import moment from "moment-timezone";
import { cloneDeep, random, uniq } from "lodash";
import L from "leaflet";
import "leaflet.heat";
import "leaflet-semicircle";
import "leaflet-spline";
import "leaflet-polylinedecorator";
import { mapActions, mapState, mapGetters } from "vuex";
import * as actionTypes from "@/store/action-types";
import * as routerTypes from "@/router/router.types";
import AnchorsApi from "@/modules/anchors/api/anchors.api";
import clientsApi from "@/modules/clients/api/clients.api";
import positionLogsApi from "@/modules/position-logs/api/position-logs.api";
import tagsApi from "@/modules/tags/api/tags.api";
// import BatteryIcon from "@/components/icons/BatteryIcon.vue";
import AssetHistoryTable from "@/components/live-map/components/AssetHistoryTable.vue";
import LiveMapAside from "@/components/live-map/LiveMapAside.vue";
import WifiIcon from "@/components/icons/WifiIcon.vue";
// import SaveChangesDialog from "@/components/SaveChangesDialog.vue";
import EditDialog from "@/components/EditDialog.vue";
import TruncatedTooltip from "@/components/TruncatedTooltip.vue";
import PaymentWarning from "@/components/global/PaymentWarning.vue";

//import Stats from "stats.js";
//import router from "@/router";

export default {
  name: "LiveMap",
  components: {
    WifiIcon,
    AssetHistoryTable,
    LiveMapAside,
    // SaveChangesDialog,
    EditDialog,
    PaymentWarning,
    TruncatedTooltip
  },
  props: {
    showAnchors: {
      type: Boolean,
      default: true
    },

    showNumberedOnly: {
      type: Boolean,
      default: false
    },

    enumerateTags: {
      type: Array,
      default: () => []
    },

    stayedTrip: {
      type: Array,
      default: () => []
    },

    checkpointFrequency: {
      type: Array,
      default: () => []
    },

    tripFrequency: {
      type: Object
    },

    routeSelected: {
      default: () => []
    },

    averageTimeHeatmapPoints: {
      type: Array,
      default: () => []
    },

    frequencyHeatmapPoints: {
      type: Array,
      default: () => []
    },

    averageTimeMaxValue: {
      type: Number,
      default: 1
    },

    frequencyMaxValue: {
      type: Number,
      default: 1
    },

    intervalUpdate: {
      type: Boolean,
      default: true
    },

    tagsToRender: {
      type: Array
    },

    tagsRemoved: {
      type: Array,
      default: () => []
    },

    editTagLocations: {
      type: Boolean,
      default: false
    },

    orders: {
      default: () => []
    },

    isEditing: {
      type: Boolean,
      default: false
    },

    saveChanges: {
      type: Boolean,
      default: false
    },

    cancelChanges: {
      type: Boolean,
      default: false
    },

    isEditingRoute: {
      type: Boolean,
      default: false
    },

    anchorsToShow: {
      type: Array
    },

    lineGraphFilters: {
      type: Object
    },

    tagToShowOnMap: {
      type: Object
    }
  },

  computed: {
    ...mapState("auth", {
      user: "user"
    }),
    ...mapState("user", {
      resources: "resources",
      activeRoute: "activeRoute"
    }),
    ...mapState("clients", {
      stateAnchors: "anchors",
      clientData: "data",
      clientAnchors: "anchors",
      clientLoading: "loading",
      clientError: "error",
      selectedAnchor: "selectedAnchor"
    }),
    ...mapGetters("auth", ["isAdmin", "isTracker", "isAzitek"]),

    getActiveTagsLength() {
      return this.tags.filter(tag => tag.active === true && tag.type == 0)
        .length;
    },

    getInactiveTagsLength() {
      return this.tags.filter(tag => tag.active === false && tag.type == 0)
        .length;
    },

    computedColor() {
      if (this.resourceInfo.details.activeRouteOnTime === null)
        return "#1F4258";
      else {
        if (this.resourceInfo.details.activeRouteOnTime[0] === 0)
          return "#1F4258";
        else if (this.resourceInfo.details.activeRouteOnTime[0] === -1)
          return "green";
        else return "red";
      }
    },
    computedWeight() {
      if (this.resourceInfo.details.activeRouteOnTime === null) return "normal";
      else {
        return this.resourceInfo.details.activeRouteOnTime[0] === 0
          ? "normal"
          : "bold";
      }
    },
    // getRouteTableDelay() {
    //   let hours;
    //   let minutes;

    //   if (this.resourceInfo.details.activeRouteOnTime[1] >= 0) {
    //     hours = Math.floor(
    //       this.resourceInfo.details.activeRouteOnTime[1] / 3600
    //     );
    //     minutes = Math.floor(
    //       (this.resourceInfo.details.activeRouteOnTime[1] % 3600) / 60
    //     );
    //   } else {
    //     hours = Math.ceil(
    //       this.resourceInfo.details.activeRouteOnTime[1] / 3600
    //     );
    //     minutes = Math.ceil(
    //       (this.resourceInfo.details.activeRouteOnTime[1] % 3600) / 60
    //     );
    //   }

    //   let minutesString = Math.abs(minutes).toString();
    //   if (minutes < 10 && minutes > -10)
    //     minutesString = "0".concat(minutesString);

    //   if (Math.abs(hours) >= 1)
    //     return (
    //       (this.resourceInfo.details.activeRouteOnTime[1] < 0 ? "-" : "") +
    //       (minutes > 0 ? hours.toString() : hours.toString()) +
    //       "h" +
    //       (minutes < 10 && minutes > -10
    //         ? "0" + minutesString
    //         : minutesString) +
    //       "m"
    //     );
    //   else
    //     return (
    //       (this.resourceInfo.details.activeRouteOnTime[1] < 0 ? "-" : "") +
    //       "00h" +
    //       minutesString +
    //       "m"
    //     );
    // },

    getRouteDelay() {
      let hours;
      let minutes;

      if (this.resourceInfo.details.activeRouteOnTime[1] >= 0) {
        hours = Math.floor(
          this.resourceInfo.details.activeRouteOnTime[1] / 3600
        );
        minutes = Math.floor(
          (this.resourceInfo.details.activeRouteOnTime[1] % 3600) / 60
        );
      } else {
        hours = Math.ceil(
          this.resourceInfo.details.activeRouteOnTime[1] / 3600
        );
        minutes = Math.ceil(
          (this.resourceInfo.details.activeRouteOnTime[1] % 3600) / 60
        );
      }

      const minutesString = Math.abs(minutes).toString();

      if (Math.abs(hours) >= 1)
        return (
          (minutes > 0 ? "+" + hours.toString() : hours.toString()) +
          "h" +
          (minutes < 10 && minutes > -10
            ? "0" + minutesString
            : minutesString) +
          "m"
        );
      else return (minutes > 0 ? "+" + minutesString : minutesString) + "m";
    },

    getRouteActiveStart() {
      return (
        moment
          .tz(
            moment.unix(
              this.resourceInfo.details.currentRouteInstance.startDate
            ),
            this.clientData.timezone
          )
          .hours() +
        "h" +
        moment
          .tz(
            moment.unix(
              this.resourceInfo.details.currentRouteInstance.startDate
            ),
            this.clientData.timezone
          )
          .minutes() +
        "m"
      );
    },

    drawSlot() {
      return (
        (this.isLiveMap ||
          this.$slots.default ||
          this.$route.name === "stats-globals") &&
        this.dashboardSlotOpen
      );
    },

    beaconToHighlight() {
      return this.highlightedBeacon || this.trackerHighlightedBeacon;
    },

    beaconOptions() {
      return this.isTracker
        ? this.tags.filter(tag => tag.type === 0)
        : this.tags;
    }
  },

  data() {
    return {
      moment,
      image: {
        url: "",
        width: 0,
        height: 0
      },
      showPaymentWarning: true,
      windowWidth: window.innerWidth,
      // marks: {
      //   1: this.$t("labels.min"),
      //   16: this.$t("labels.max")
      // },
      baseRadius: 0,
      anchorMap: null,
      search: "",
      tagToEdit: {},
      discardChangesDialog: false,
      tagToReplace: null,
      childTagToAdd: null,
      anchorsFullInfo: [],
      tagsToDelete: [],
      currentWidth: 0,
      tagsSelected: [],
      tagsToSend: [],
      imageToUpload: null,
      showSidebar: false,
      validRoute: true,
      dashboardSlotOpen: true,
      interval: null,
      averageTimeHeatmapActive: true,
      frequencyHeatmapActive: false,
      spaghettiFrequencyDiagramActive: false,
      spaghettiTimeDiagramActive: false,
      intervalUsageInfo: null,
      intervalLastFive: null,
      intervalResources: null,
      intervalAnchor: null,
      nextTimestamp: null,
      map: null,
      mapBounds: null,
      resourcesSize: 3,
      heatmapSize: 1,
      resourceInfo: {
        type: "",
        details: ""
      },
      highlightedBeacon: null,
      currentHeatmapRadius: 30,
      lastFive: {},
      anchorsGroup: null,
      tagsGroup: null,
      anchors: [],
      sidebarAnchor: null,
      visibleAnchor: -1,
      nextPosition: null,
      tags: [],
      tagHistoryDialog: {
        visible: false,
        loading: false
      },
      tagHistory: {
        tag: null,
        history: [],
        page: 0
      },
      currentRoute: null,
      tagsBeforeEdit: [],
      tagsToUpdate: [],
      tagsEdited: [],
      tagsEditedCircles: [],
      highlightResourceOriginalData: {},
      currentTime: "",
      currentTimeInterval: null,
      inactiveTags: [],
      areMapControlsVisible: true,
      editTagDialog: {
        visible: false,
        loading: false
      },
      certifyEditTagDialogDiscard: false,
      certifyEditTagDialogSave: false,
      replaceTagDialog: {
        visible: false,
        loading: false
      },
      gatewaysAffectedDialog: false,
      gatewaysAffected: [],
      moveTagDialog: {
        visible: false,
        tagToMoveId: null,
        nextPosition: {
          x: -1,
          y: -1
        }
      },
      tagForm: {
        tagId: 0,
        label: "",
        active: false,
        threshold: "",
        battery: -1,
        lastSeen: -1,
        error: ""
      },
      // tagsToRender: null,
      // orders: {},
      moveBeaconForm: {
        label: ""
      },
      replaceTagForm: {
        tagId: null,
        newId: null,
        error: ""
      },
      heatLayerVar: null,
      activeChecked: "all",
      tagsToShow: [],
      orphanTags: [],
      tempOrphanTags: [],
      parentInfo: null,
      isStatsPage: this.$route.name === routerTypes.ROUTE_STATS_LAPS_DETAILS,
      isLiveMap: this.$route.name === routerTypes.ROUTE_DASHBOARD,
      hideEditTags:
        this.$route.name === routerTypes.ROUTE_STATS_LAPS_DETAILS ||
        this.$route.name === routerTypes.ROUTE_STATS_GLOBALS,
      rules: {
        label: [
          {
            max: 45,
            message: this.$t("messages.labelMaxLength"),
            trigger: "blur"
          }
        ]
      },
      tagBeaconInterval: null,
      tagBeaconList: [],
      splines: [],
      polyLines: [],
      beaconList: [],
      prevTagId: null,
      selectedAnchorId: "",
      selectedAnchorIds: [],
      circleTags: {},
      statusOptions: ["NO STATUS", "NOK", "OK", "PRODUCING"],
      trackerHighlightedBeacon: null,
      selectedAnchors: [],
      selectedColors: [
        "#32CD32",
        "#6a329f",
        "#ee82ee",
        "#e69138",
        "#2986cc",
        "#FF0033",
        "#45818e"
      ]
    };
  },

  watch: {
    $route(newValue) {
      if (this.isLiveMap) {
        const targetRoute = {
          name: routerTypes.ROUTE_DASHBOARD,
          params: {
            anchorId: this.selectedAnchor.anchorId
          }
        };
        if (
          newValue.name !== targetRoute.name &&
          newValue.params.anchorId !== targetRoute.params.anchorId
        ) {
          this.$router.replace(targetRoute);
        }
      }
    },

    tagToReplace(newValue) {
      if (newValue === 0) this.tagToReplace = null;
    },

    editTagLocations(newValue) {
      if (newValue) this.editTags();
    },

    saveChanges(newValue) {
      if (newValue) this.editTags();
    },

    cancelChanges(newValue) {
      if (newValue) this.discardChangesDialog = true;
    },

    search(newValue) {
      const tag = this.tags.find(tag => tag.id === newValue);
      if (tag) {
        const _self = this; // eslint-disable-line
        this.map.on("click", function() {
          _self.search = "";
          if (_self.nextPosition) {
            _self.map.removeLayer(_self.nextPosition);
          }
          _self.highlightedBeacon = null;
          _self.deEmphasizeResource();
          _self.resourceInfo.details.mapRef?.closePopup();

          if (_self.visibleAnchor >= 0) {
            _self.resourceInfo.type = "anchor";
            _self.resourceInfo.details = _self.anchors.find(
              anchor => anchor.anchorId === _self.visibleAnchor
            );

            _self.createNextPosition(_self.resourceInfo.details);
            _self.map.removeEventListener("click");
          }
        });
        this.currentRoute = this.resourceInfo.details;
        this.highlightedBeacon = tag;
        //this.resourceInfo.details = tag;
        this.highlightResource();
        this.$emit("tag-clicked", this.resourceInfo.details);
      } else return;
    },

    mapBounds(newValue) {
      if (newValue != null) this.fitMapToBounds();
    },

    tagsRemoved(newValue) {
      const index = this.tagsEdited.findIndex(
        elem => elem.id === newValue[0].id
      );
      this.tagsEdited.splice(index, 1);
      const indexTwo = this.tags.findIndex(_tag => _tag.id === newValue[0].id);
      const indexThree = this.tagsToUpdate.findIndex(
        _tag => _tag.tagId === newValue[0].id
      );
      this.tagsToUpdate.splice(indexThree, 1);
      if (newValue[1] === "ADDED") this.tags[indexTwo].active = false;
      this.removeResourceFromMap([this.tags[indexTwo]]);
      this.tags[indexTwo].mapRef = this.createMapTag(newValue[0], false, true);
      this.tags[indexTwo].mapRef.dragging.enable();
      this.setDragListenerOnTag(this.tags[indexTwo].mapRef);
      this.$forceUpdate();
    },

    //When the checkpoint frequency array's value changes, remove current heatmap and generate a new one
    checkpointFrequency(newValue) {
      if (this.heatLayerVar) this.map.removeLayer(this.heatLayerVar);
      if (this.frequencyHeatmapActive && newValue.length) {
        const heatpoints = this.generateFrequencyHeatpoints(newValue);
        this.heatmapSize = Number(localStorage.getItem("heatmap-size")) || 1;
        this.generateHeatmap(heatpoints);
      }
    },

    stayedTrip(newValue) {
      if (this.heatLayerVar) this.map.removeLayer(this.heatLayerVar);
      if (this.averageTimeHeatmapActive && newValue.length) {
        const heatpoints = this.generateStayedTripHeatpoints(newValue);
        this.heatmapSize = Number(localStorage.getItem("heatmap-size")) || 1;
        this.generateHeatmap(heatpoints);
      }
    },

    averageTimeHeatmapPoints(newValue) {
      if (this.averageTimeHeatmapActive && newValue.length > 0) {
        if (this.heatLayerVar) this.map.removeLayer(this.heatLayerVar);
        const heatpoints = this.generateStayedTripHeatpointsGlobal(newValue);
        this.generateHeatmap(heatpoints);
      } else if (!newValue.length) {
        if (this.heatLayerVar) {
          this.map.removeLayer(this.heatLayerVar);
          this.heatLayerVar = null;
        }
      }
    },

    frequencyHeatmapPoints(newValue) {
      if (this.frequencyHeatmapActive && newValue.length > 0) {
        if (this.heatLayerVar) this.map.removeLayer(this.heatLayerVar);
        const heatpoints = this.generateFrequencyHeatmapGlobal(newValue);
        this.generateHeatmap(heatpoints);
      } else if (!newValue.length) {
        if (this.heatLayerVar) {
          this.map.removeLayer(this.heatLayerVar);
          this.heatLayerVar = null;
        }
      }
    },

    averageTimeHeatmapActive(newValue) {
      if (newValue) {
        this.frequencyHeatmapActive = false;
        this.$emit("tab-switch", "checkpoints");
        if (this.heatLayerVar) this.map.removeLayer(this.heatLayerVar);
        if (this.$route.name === "stats-globals") {
          if (this.averageTimeHeatmapPoints.length > 0) {
            const heatpoints = this.generateStayedTripHeatpointsGlobal(
              this.averageTimeHeatmapPoints
            );
            this.generateHeatmap(heatpoints);
          }
        } else {
          const heatpoints = this.generateStayedTripHeatpoints(this.stayedTrip);
          this.generateHeatmap(heatpoints);
        }
        localStorage.setItem("current-heatmap", "average");
      } else {
        if (!this.frequencyHeatmapActive && this.heatLayerVar) {
          this.map.removeLayer(this.heatLayerVar);
          this.heatLayerVar = null;
          localStorage.setItem("current-heatmap", "none");
        }
      }
    },

    frequencyHeatmapActive(newValue) {
      if (newValue) {
        this.averageTimeHeatmapActive = false;
        this.$emit("tab-switch", "checkpoints");
        if (this.heatLayerVar) this.map.removeLayer(this.heatLayerVar);
        if (this.$route.name === "stats-globals") {
          if (this.frequencyHeatmapPoints.length > 0) {
            const heatpoints = this.generateFrequencyHeatmapGlobal(
              this.frequencyHeatmapPoints
            );
            this.generateHeatmap(heatpoints);
          }
        } else {
          if (this.heatLayerVar) this.map.removeLayer(this.heatLayerVar);
          const heatpoints = this.generateFrequencyHeatpoints(
            this.checkpointFrequency
          );
          this.heatmapSize = Number(localStorage.getItem("heatmap-size")) || 1;
          this.generateHeatmap(heatpoints);
        }
        localStorage.setItem("current-heatmap", "frequency");
      } else {
        if (!this.averageTimeHeatmapActive && this.heatLayerVar) {
          this.map.removeLayer(this.heatLayerVar);
          localStorage.setItem("current-heatmap", "none");
          this.heatLayerVar = null;
        }
      }
    },

    spaghettiFrequencyDiagramActive(newValue) {
      if (newValue) {
        this.spaghettiTimeDiagramActive = false;
        this.$emit("tab-switch", "segments");
        if (this.splines.length > 0) this.removeSplinesAndPolyLines();
        this.generateSpaghettiDiagram();
      } else {
        if (this.splines.length > 0 && !this.spaghettiTimeDiagramActive)
          this.removeSplinesAndPolyLines();
      }
    },

    spaghettiTimeDiagramActive(newValue) {
      if (newValue) {
        this.spaghettiFrequencyDiagramActive = false;
        this.$emit("tab-switch", "segments");
        if (this.splines.length > 0) this.removeSplinesAndPolyLines();
        this.generateSpaghettiDiagram();
      } else {
        if (this.splines.length > 0 && !this.spaghettiFrequencyDiagramActive)
          this.removeSplinesAndPolyLines();
      }
    },

    lineGraphFilters: {
      deep: true,
      handler() {
        if (this.splines.length > 0) this.removeSplinesAndPolyLines();
        if (
          this.spaghettiFrequencyDiagramActive ||
          this.spaghettiTimeDiagramActive
        )
          this.generateSpaghettiDiagram();
      }
    },

    async isEditingRoute(newValue) {
      if (newValue === false) {
        this.resourceInfo.type = "anchor";
        this.resourceInfo.details = await this.updateAnchor(
          this.selectedAnchor.anchorId
        );
        this.deEmphasizeResource();
        this.removeResourceFromMap(this.tags, "tag");
        this.addResourcesToMap(this.tags, "tag");
      }
    },
    anchorsToShow: {
      handler() {
        this.removeResourceFromMap(this.anchors, "anchor");
        this.addResourcesToMap(this.anchors, "anchor");
      }
    },
    selectedAnchors: {
      handler() {
        this.removeResourceFromMap(this.anchors, "anchor");
        this.addResourcesToMap(this.anchors, "anchor");
      }
    },
    activeChecked: {
      handler(newValue) {
        if (newValue != "all")
          this.tagsToShow = this.tags.filter(
            tag => tag.active === newValue && !tag.destroyed && tag.type == 0
          );
        else this.tagsToShow = this.tags.filter(tag => tag.type == 0);
      }
    },
    anchorMap: {
      handler() {
        this.removeResourceFromMap(this.anchors, "anchor");
        this.addResourcesToMap(this.anchors, "anchor");
      }
    },
    selectedAnchor: {
      deep: true,
      immediate: true,
      async handler(newValue) {
        if (
          this.isLiveMap &&
          this.sidebarAnchor?.id != newValue.id &&
          !this.isTracker
        ) {
          this.getLastFive();
          if (this.isLiveMap) {
            const targetRoute = {
              name: routerTypes.ROUTE_DASHBOARD,
              params: {
                anchorId: this.selectedAnchor.anchorId
              }
            };
            if (
              this.$route.name !== targetRoute.name &&
              this.$route.params.anchorId !== targetRoute.params.anchorId
            ) {
              this.$router.replace(targetRoute);
            }
          }
        }

        if (this.heatLayerVar) this.map.removeLayer(this.heatLayerVar);
        this.visibleAnchor =
          this.$route.name === "live-global-map"
            ? -1
            : this.selectedAnchor.anchorId;
        this.sidebarAnchor = cloneDeep(this.selectedAnchor);

        const _self = this; // eslint-disable-line
        this.resources.usageInfo.forEach(value => {
          if (_self.sidebarAnchor.anchorId === value.anchorId)
            _self.sidebarAnchor.usageInfo = value;
        });

        // if (this.$route.name === "dashboard") {
        //   this.getLastFive();
        // }
      }
    },

    "moveTagDialog.tagToMoveId": {
      handler(newValue) {
        if (newValue)
          this.moveBeaconForm.label = this.tags.find(
            tag => tag.id === newValue
          ).label;
      }
    },

    "editTagDialog.visible": {
      handler(newValue) {
        if (!newValue) {
          if (this.tagsSelected.length != 0 || this.tagToReplace != null) {
            this.certifyEditTagDialogDiscard = true;
            this.editTagDialog.visible = true;
          } else {
            this.discardChanges();
          }
        }
      }
    },

    "resourceInfo.details": {
      async handler(newValue, oldValue) {
        if (this.resourceInfo.details != null) {
          if (this.resourceInfo.details.activeRouteOnTime === null)
            this.validRoute = false;
          else this.validRoute = true;
          if (
            oldValue &&
            !this.isTracker &&
            this.$route.name === "dashboard" &&
            (newValue.lastPositionMetadata?.state !=
              oldValue.lastPositionMetadata?.state ||
              newValue.lastSeenTs != oldValue.lastSeenTs)
          ) {
            this.getLastFive();
          }
        }
      }
    },

    "resources.data.tags": {
      handler(newValue) {
        if (this.tagsToRender) return;
        this.removeResourceFromMap(this.tags, "tag");
        this.tags = cloneDeep(newValue);
        this.addResourcesToMap(this.tags, "tag");
      }
    },

    "resources.data": {
      deep: true,
      async handler(newValue) {
        if (this.tagsToRender && !this.isLiveMap) return;
        if (this.highlightedBeacon != null) {
          this.highlightedBeacon = newValue.tags.find(
            _tag => _tag.tagId === this.highlightedBeacon.tagId
          );
          this.highlightResource();
        } else {
          this.resourceInfo.details = newValue.anchors.find(
            _anchor => _anchor.anchorId === this.resourceInfo.details.anchorId
          );
          //this.updateSelectedAnchor(this.resourceInfo.details.anchorId);
          if (this.resourceInfo.details.nextPosition != null)
            this.updateNextPosition(this.resourceInfo.details.nextPosition);
          //await this.updateAnchor(
          //this.resourceInfo.details.anchorId
          //);
        }

        if (newValue.anchors.length) {
          this.sidebarAnchor = newValue.anchors.filter(
            anchor => anchor.anchorId === this.sidebarAnchor.anchorId
          )[0];
        }

        this.removeResourceFromMap(this.anchors, "anchor");
        this.removeResourceFromMap(this.tags, "tag");
        if (this.nextPosition) {
          this.map.removeLayer(this.nextPosition);
        }

        this.anchors = cloneDeep(newValue.anchors);
        this.tags = cloneDeep(newValue.tags);
        // this.tags.forEach(elem => {
        //   elem.childTags = [];
        // });

        const _self = this; // eslint-disable-line
        // this.tags.forEach(element => {
        //   const index = _self.tags.findIndex(
        //     elem => elem.id == element.parentId
        //   );
        //   if (index !== -1) {
        //     _self.tags[index].childTags.push({
        //       id: element.tagId,
        //       label: element.label,
        //       parentId: element.parentId
        //     });
        //   }
        // });
        if (newValue.nextPosition)
          this.selectedAnchor.nextPosition = newValue.nextPosition;

        if (newValue.lastPosition)
          this.selectedAnchor.lastPosition = newValue.lastPosition;

        this.addResourcesToMap(this.anchors, "anchor");
        this.createNextPosition(this.resourceInfo.details);
        this.addResourcesToMap(this.tags, "tag");

        this.resources.usageInfo.forEach(value => {
          if (_self.sidebarAnchor.anchorId === value.anchorId)
            _self.sidebarAnchor.usageInfo = value;
        });

        if (this.highlightedBeacon != null) this.highlightResource();

        // if (newValue) {
        //   newValue.anchors?.forEach(anchor => {
        //     this.updateResource(this.anchors, anchor, "anchor");
        //   });

        //   if (newValue.anchors.length && this.resourceInfo.type === 'anchor') {
        //     this.resourceInfo.details = newValue.anchors.find(_anchor => _anchor.anchorId === this.resourceInfo.details.anchorId)
        //   }

        //   if (newValue.anchors.length && this.nextPosition) {
        //     this.map.removeLayer(this.nextPosition);

        //     // Put back nextPosition if available
        //     if (this.resourceInfo.type === "anchor") {
        //       this.createNextPosition(this.selectedAnchor);
        //     }
        //   }

        //   newValue.tags?.forEach(tag => {
        //     this.updateResource(this.tags, tag, "tag");
        //   });
        // }
      }
    },

    "resources.usageInfo": {
      deep: true,
      async handler(newValue) {
        const _self = this; // eslint-disable-line
        newValue.forEach(value => {
          if (_self.sidebarAnchor.anchorId === value.anchorId)
            _self.sidebarAnchor.usageInfo = value;
        });
      }
    },

    "resources.globalAnchors": {
      deep: true,
      async handler(newValue) {
        this.anchorMap = new Map();
        this.anchors.forEach((anchor, index) => {
          newValue.forEach(async newAnchor => {
            if (newAnchor.anchorId === anchor.anchorId) {
              if (
                (!anchor.activeRoute && newAnchor.activeRouteId) ||
                anchor.activeRoute?.id != newAnchor.activeRouteId
              ) {
                this.getUsageInfo(this.clientData.id);
                this.getResources();
              }
              if (
                anchor.lastAnchorPositionId != newAnchor.lastAnchorPositionId
              ) {
                anchor.lastAnchorPositionId = newAnchor.lastAnchorPositionId;
                // const res = await AnchorsApi.getNextPosition(anchor.anchorId);
                // anchor.nextPosition = res.data;
                this.tags.forEach(tag => {
                  if (tag.lastPosition)
                    if (tag.lastPosition.id === newAnchor.tagPositionId)
                      anchor.lastPosition = tag.lastPosition;
                });
                if (this.resourceInfo.details.nextPosition != null)
                  this.updateNextPosition(
                    this.resourceInfo.details.nextPosition
                  );

                this.removeResourceFromMap(this.anchors, "anchor");
                this.removeResourceFromMap(this.tags, "tag");
                // if (this.nextPosition) this.map.removeLayer(this.nextPosition);
                this.addResourcesToMap(this.anchors, "anchor");
                // this.createNextPosition(this.resourceInfo.details);
                this.addResourcesToMap(this.tags, "tag");
              }
              if (
                !this.isTracker &&
                ((!anchor.lastPositionMetadata && newAnchor.state) ||
                  anchor.lastPositionMetadata.state != newAnchor.state)
              ) {
                this.getLastFive();
              }
              anchor.lastAnchorPositionId = newAnchor.lastAnchorPositionId;
              if (!anchor.lastPositionMetadata) {
                anchor.lastPositionMetadata = {};
              }
              if (this.isTracker) {
                anchor.currentTagId = newAnchor.currentTagId;
                anchor.currentTagLabel = newAnchor.currentTagLabel;
              }
              anchor.lastPositionMetadata.state = newAnchor.state;
              anchor.lastPositionMetadata.tagPositionId =
                newAnchor.tagPositionId;
              anchor.lastPositionMetadata.lastSeenTs = newAnchor.lastSeenTs;
              anchor.tagPositionId = newAnchor.tagPositionId;
              anchor.label = newAnchor.label;
              anchor.rssi = newAnchor.rssi;
              anchor.powerSource = newAnchor.powerSource;
              if (this.isTracker) {
                if (
                  (!this.isAnchorOffline(
                    anchor.powerSource,
                    anchor.offlineTs
                  ) &&
                    this.isAnchorOffline(
                      newAnchor.powerSource,
                      newAnchor.offlineTs
                    )) ||
                  !newAnchor.lastAnchorPositionId
                ) {
                  anchor.color = "#606266";
                  const isAnchorOnMap = this.selectedAnchorIds.includes(
                    anchor.anchorId
                  );
                  if (isAnchorOnMap) {
                    this.selectedAnchorIds = this.selectedAnchorIds.filter(
                      id => id !== anchor.anchorId
                    );
                    this.removeResourceFromMap([anchor], "anchor");
                  }
                } else if (
                  this.isAnchorOffline(anchor.powerSource, anchor.offlineTs) &&
                  !this.isAnchorOffline(
                    newAnchor.powerSource,
                    newAnchor.offlineTs
                  ) &&
                  newAnchor.lastAnchorPositionId &&
                  anchor.color === "#606266"
                ) {
                  anchor.color = anchor.originalColor;
                  this.selectedAnchorIds = this.anchors.map(
                    anchor => anchor.anchorId
                  );
                }
              }
              anchor.offlineTs = newAnchor.offlineTs;
              anchor.activeRouteOnTime = newAnchor.activeRouteOnTime;
              if (this.highlightedBeacon != null) this.highlightResource();
            }
          });
          if (
            this.anchorMap &&
            anchor.lastPosition?.id &&
            this.anchorMap.has(anchor.lastPosition?.id) &&
            !this.isAnchorOffline(anchor.powerSource, anchor.offlineTs)
          ) {
            anchor.positionInCircle = this.anchorMap.get(
              anchor.lastPosition.id
            );
            const currValue = this.anchorMap.get(anchor.lastPosition.id);
            this.anchorMap.set(anchor.lastPosition.id, currValue + 1);
          } else if (
            !this.isAnchorOffline(anchor.powerSource, anchor.offlineTs) &&
            anchor.lastPosition?.id
          ) {
            anchor.positionInCircle = 0;
            this.anchorMap.set(anchor.lastPosition.id, 1);
          }

          if (this.isTracker && this.selectedAnchorId === anchor.anchorId) {
            this.sidebarAnchor.currentTagId = anchor.currentTagId;
            this.sidebarAnchor.currentTagLabel = anchor.currentTagLabel;
            this.sidebarAnchor.lastPositionMetadata =
              anchor.lastPositionMetadata;
            this.sidebarAnchor.powerSource = anchor.powerSource;
            this.sidebarAnchor.offlineTs = anchor.offlineTs;
            this.sidebarAnchor.lastHeartbeat = anchor.lastHeartbeat;
            this.sidebarAnchor.rssi = anchor.rssi;
            this.resourceInfo.details = this.sidebarAnchor;
          }
        });
      }
    },

    "resources.sidebarAnchor": {
      deep: true,
      async handler(newValue) {
        this.sidebarAnchor.currentTagId = newValue.currentTagId;
        if (this.isTracker && this.tagBeaconList.length) {
          this.tagBeaconList.sort((a, b) => {
            const currentTagId = newValue.currentTagId;
            if (a.tagId === currentTagId) return -1;
            if (b.tagId === currentTagId) return 1;
            return 0;
          });
        }
        if (
          !this.isTracker &&
          this.$route.name === "dashboard" &&
          (newValue.state != this.sidebarAnchor.lastPositionMetadata?.state ||
            newValue.lastSeenTs != this.sidebarAnchor.lastSeenTs)
        ) {
          this.getLastFive();
        }
        this.sidebarAnchor.currentTagLabel = newValue.currentTagLabel;
        this.sidebarAnchor.currentTagStartTs = newValue.currentTagStartTs;
        this.sidebarAnchor.lastSeenTs = newValue.lastSeenTs;
        if (
          this.sidebarAnchor.lastAnchorPositionId !=
          newValue.lastAnchorPositionId
        ) {
          if (!this.isTracker && this.$route.name === "dashboard") {
            this.getLastFive();
          }
          this.sidebarAnchor.lastAnchorPositionId =
            newValue.lastAnchorPositionId;
          const res = await AnchorsApi.getNextPosition(
            this.selectedAnchor.anchorId
          );
          this.sidebarAnchor.nextPosition = res.data;
          this.tags.forEach(tag => {
            if (tag.lastPosition)
              if (tag.lastPosition.id === newValue.tagPositionId)
                this.sidebarAnchor.lastPosition = tag.lastPosition;
          });
          if (this.resourceInfo.details.nextPosition != null)
            this.updateNextPosition(this.resourceInfo.details.nextPosition);

          this.anchors.forEach(element => {
            if (element.anchorId === newValue.anchorId) {
              element.lastAnchorPositionId = newValue.lastAnchorPositionId;
              element.lastPosition = this.sidebarAnchor.lastPosition;
              if (!element.lastPositionMetadata) {
                element.lastPositionMetadata = {};
              }
              element.lastPositionMetadata.state = newValue.state;
              element.lastPositionMetadata.tagPositionId =
                newValue.tagPositionId;
            }
          });
          if (!this.sidebarAnchor.lastPositionMetadata) {
            this.sidebarAnchor.lastPositionMetadata = {};
          }
          this.sidebarAnchor.lastPositionMetadata.state = newValue.state;
          this.sidebarAnchor.lastPositionMetadata.tagPositionId =
            newValue.tagPositionId;
          this.removeResourceFromMap(this.anchors, "anchor");
          this.removeResourceFromMap(this.tags, "tag");
          if (this.nextPosition) this.map.removeLayer(this.nextPosition);
          this.addResourcesToMap(this.anchors, "anchor");
          this.createNextPosition(this.resourceInfo.details);
          this.addResourcesToMap(this.tags, "tag");
        }
        if (
          this.sidebarAnchor.lastRouteInstanceId != newValue.lastRouteInstanceId
        ) {
          this.getUsageInfo(this.clientData.id); //TODO: endpoint para today info apenas
          this.sidebarAnchor.lastRouteInstanceId = newValue.lastRouteInstanceId;
          const res = await AnchorsApi.getRecentRoutes(
            this.selectedAnchor.anchorId
          );
          //TODO: endpoint para recent routes
          this.sidebarAnchor.recentRoutes = res.data;
        }
        this.sidebarAnchor.label = newValue.label;
        this.sidebarAnchor.rssi = newValue.rssi;
        this.sidebarAnchor.powerSource = newValue.powerSource;
        if (this.sidebarAnchor.currentRouteInstance)
          this.sidebarAnchor.currentRouteInstance.startDate =
            newValue.startDate;

        this.sidebarAnchor.offlineTs = newValue.offlineTs;
        this.sidebarAnchor.activeRouteOnTime = newValue.activeRouteOnTime;
        if (this.highlightedBeacon != null) this.highlightResource();

        if (
          this.sidebarAnchor.lastPositionMetadata &&
          this.sidebarAnchor.lastPositionMetadata.state != newValue.state
        ) {
          this.anchors.forEach(element => {
            if (element.anchorId === newValue.anchorId)
              if (!element.lastPositionMetadata) {
                element.lastPositionMetadata = {};
              }
            element.lastPositionMetadata.state = newValue.state;
          });
          if (!this.sidebarAnchor.lastPositionMetadata) {
            this.sidebarAnchor.lastPositionMetadata = {};
          }
          this.sidebarAnchor.lastPositionMetadata.state = newValue.state;
          this.removeResourceFromMap(this.anchors, "anchor");
          this.addResourcesToMap(this.anchors, "anchor");
          if (this.nextPosition) this.map.removeLayer(this.nextPosition);
          this.createNextPosition(this.resourceInfo.details);
        }
        this.resourceInfo.details = this.sidebarAnchor;
      }
    },

    tagsToRender: {
      deep: true,
      handler(newValue) {
        if (newValue?.length) {
          this.tagsGroup.clearLayers();
          this.setTagsToRender();
        }
      }
    },

    resourcesSize(newValue) {
      localStorage.setItem("map-resource-size", newValue);

      if (!this.isEditing) {
        this.removeResourceFromMap(this.anchors, "anchor");
      }

      this.removeResourceFromMap(this.tags, "tag");
      if (this.nextPosition) {
        this.map.removeLayer(this.nextPosition);
      }

      if (!this.isEditing) {
        this.addResourcesToMap(this.anchors, "anchor");
        this.createNextPosition(this.resourceInfo.details);
      }

      this.addResourcesToMap(this.tags, "tag");

      if (this.highlightedBeacon != null) {
        this.highlightResource();
      }
    },

    heatmapSize(newValue) {
      if (this.heatLayerVar) {
        this.heatLayerVar.setOptions({
          radius: this.getRadius(),
          blur: this.getBlur(),
          maxZoom: this.getHeatMapMaxZoom()
        });
        // render the new options
        this.heatLayerVar.redraw();
      }
      localStorage.setItem("heatmap-size", newValue);
    },

    areMapControlsVisible(newValue) {
      if (newValue) {
        this.map.addControl(this.map.zoomControl);
      } else {
        this.map.removeControl(this.map.zoomControl);
      }
    },

    showNumberedOnly() {
      this.removeResourceFromMap(this.tags, "tag");
      this.addResourcesToMap(this.tags, "tag");
    },

    enumerateTags() {
      this.removeResourceFromMap(this.tags, "tag");
      this.addResourcesToMap(this.tags, "tag");
    },

    dashboardSlotOpen() {
      this.$nextTick(() => {
        this.map.invalidateSize();
      });
    },

    showSidebar(value) {
      this.$emit("toggle-sidebar", value);
    },

    async visibleAnchor(newAnchorIdSelected) {
      if (this.isLiveMap) {
        if (newAnchorIdSelected >= 0) {
          this.resourceInfo.type = "anchor";
          this.resourceInfo.details = await this.updateAnchor(
            newAnchorIdSelected
          );
          this.removeResourceFromMap(this.anchors, "anchor");
          if (this.nextPosition) {
            this.map.removeLayer(this.nextPosition);
          }

          this.addResourcesToMap(this.anchors, "anchor");
          this.createNextPosition(this.resourceInfo.details);
          // this.highlightResource();
        } else if (this.resourceInfo.type === "anchor") {
          this.resourceInfo.type = "";
          this.resourceInfo.details = {};
          this.removeResourceFromMap(this.anchors, "anchor");
          if (this.nextPosition) {
            this.map.removeLayer(this.nextPosition);
          }
        }
      } else {
        this.resourceInfo.details = await this.updateAnchor(
          this.selectedAnchor.anchorId
        );
        this.resourceInfo.type = "anchor";
      }
    },

    tagToShowOnMap(newValue) {
      if (newValue && this.$route.name === "live-global-map") {
        this.showTagLabelOnMap(newValue);
      }
    }
  },

  async created() {
    localStorage.setItem("current-radius", 10);

    if (!this.$route.params.anchorId && this.isLiveMap && this.selectedAnchor) {
      this.$router.replace({
        name: routerTypes.ROUTE_DASHBOARD,
        params: {
          anchorId: this.selectedAnchor.anchorId
        }
      });
    }
    if (this.heatLayerVar) this.map.removeLayer(this.heatLayerVar);
    this.visibleAnchor =
      this.$route.name === "live-global-map"
        ? -1
        : this.selectedAnchor.anchorId;
    this.sidebarAnchor = cloneDeep(this.selectedAnchor);

    this.resources.usageInfo.forEach(value => {
      if (this.sidebarAnchor.anchorId === value.anchorId)
        this.sidebarAnchor.usageInfo = value;
    });

    if (this.$route.name === "dashboard") {
      this.selectedAnchorId = this.sidebarAnchor.anchorId;
      if (!this.isTracker) {
        this.getLastFive();
      }
    }

    this.updateChildTags(this.resources.data.tags);

    // let totalTags = [];
    // if (this.clientAnchors.data)
    //   this.clientAnchors.data.forEach(elem => {
    //     if (elem.activeRoute)
    //       if (elem.activeRoute.tags)
    //         totalTags = totalTags.concat(elem.activeRoute.tags);
    //   });
    // else
    //   this.clientAnchors.forEach(elem => {
    //     if (elem.activeRoute)
    //       if (elem.activeRoute.tags)
    //         totalTags = totalTags.concat(elem.activeRoute.tags);
    //   });

    // this.visibleAnchor = this.selectedAnchor?.anchorId;
    // this.sidebarAnchor = this.selectedAnchor;

    // if (this.isLiveMap || this.$route.name == "stats-globals") {
    //   this.resources.data.tags = this.resources.data.tags.filter(
    //     tag => tag.lastPosition
    //   );
    //   let biggestOrd = 0;
    //   if (this.selectedAnchor.activeRoute) {
    //     this.selectedAnchor.activeRoute.tags.forEach(tag => {
    //       if (this.orders[tag.tagPositionId])
    //         this.orders[tag.tagPositionId] = this.orders[tag.tagPositionId] +=
    //           " | " + tag.ord.toString();
    //       else this.orders[tag.tagPositionId] = tag.ord.toString();
    //       if (tag.ord > biggestOrd) biggestOrd = tag.ord;
    //     });
    //   }
    //   this.resources.data.tags.sort(function(a, b) {
    //     if (b.lastPosition.y == a.lastPosition.y)
    //       return a.lastPosition.x - b.lastPosition.x;
    //     return b.lastPosition.y - a.lastPosition.y;
    //   });
    //   this.tagsToRender = cloneDeep(this.resources.data.tags);

    //   this.tagsToRender.forEach(tag => {
    //     if (tag.parentId == null) {
    //       tag.renderIdentifier = this.orders[tag.lastPosition.id];
    //     }
    //   });
    //   this.tagsToRender.forEach(tag => {
    //     if (tag.parentId == null && !this.orders[tag.lastPosition.id]) {
    //       tag.renderIdentifier = (biggestOrd + 1).toString();
    //       biggestOrd += 1;
    //     }
    //   });

    //   this.$emit("update-tags-to-render", this.tagsToRender);
    //   this.$emit("update-orders", this.orders);
    // } else {
    //   this.tagsToRender = null;
    // }

    if (!this.tagsToRender) {
      this.anchors = cloneDeep(this.resources.data.anchors);
      this.tags = cloneDeep(this.resources.data.tags);

      if (this.isTracker) {
        this.anchors.forEach((anchor, index) => {
          if (index > this.selectedColors.length) {
            anchor.originalColor = this.getRandomColor();
          } else {
            anchor.originalColor = this.selectedColors[index];
          }
          if (
            this.isAnchorOffline(anchor.powerSource, anchor.offlineTs) ||
            !anchor.lastPosition
          ) {
            anchor.color = "#606266";
          } else {
            anchor.color = anchor.originalColor;
          }
        });

        const onlineAnchors = this.anchors.filter(
          anchor =>
            !this.isAnchorOffline(anchor.powerSource, anchor.offlineTs) &&
            anchor.lastPosition
        );
        this.selectedAnchorIds = onlineAnchors.length
          ? onlineAnchors.map(anchor => anchor.anchorId)
          : [];
      }
      // this.tags.forEach(elem => {
      //   elem.childTags = [];
      // });

      // const _self = this; // eslint-disable-line
      // this.tags.forEach(element => {
      //   const index = _self.tags.findIndex(elem => elem.id == element.parentId);
      //   if (index !== -1) {
      //     _self.tags[index].childTags.push({
      //       id: element.tagId,
      //       label: element.label,
      //       parentId: element.parentId
      //     });
      //   }
      // });
    }

    //this.getUsageInfo(this.clientData.id);
    // if (this.$route.name === "dashboard") {
    //   this.getLastFive();
    // }

    // this.tempOrphanTags = this.tags.filter(
    //   tag =>
    //     tag.numberOfChildren === 0 &&
    //     tag.parentId == null &&
    //     tag.active &&
    //     !totalTags.some(t => t.tagPosition.tagId === tag.id)
    // );

    // this.tags.forEach(elem => {
    //   elem.childTags = [];
    // });

    this.currentTimeInterval = setInterval(this.updateCurrentTime, 2000);

    // const _self = this; // eslint-disable-line
    // this.tags.forEach(element => {
    //   const index = _self.tags.findIndex(elem => elem.id == element.parentId);
    //   if (index !== -1) {
    //     _self.tags[index].childTags.push({
    //       id: element.tagId,
    //       label: element.label,
    //       parentId: element.parentId
    //     });
    //   }
    // });

    //ONLY DO THIS QUERY IN TRACKER CLIENTS
    if (this.isTracker && this.isLiveMap) {
      this.updateSelectedAnchors();
      this.getTagBeaconList();
      this.getBeaconList();
      this.tagBeaconInterval = setInterval(
        function(_self) {
          _self.getTagBeaconList();
        },
        5000,
        this
      );
    }
  },

  mounted() {
    if (this.selectedAnchor) {
      this.resourceInfo.details = this.selectedAnchor;
      this.resourceInfo.type = "anchor";
    } else {
      this.resourceInfo.details = {};
    }

    this.windowWidth = window.innerWidth;

    window.addEventListener("resize", this.updateWindowWidth);

    if (this.clientData.mapImages.length) {
      this.initMap();
      this.baseRadius = 150 / (Math.pow(2, this.map.options.maxZoom) * 2);
      if (!this.tagsToRender) {
        this.addResourcesToMap(this.anchors, "anchor");
        this.createNextPosition(this.selectedAnchor);
        this.addResourcesToMap(this.tags, "tag");

        this.$nextTick(() => {
          if (this.map != null) this.map.invalidateSize();
        });
      }
    }
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.updateWindowWidth);

    if (this.interval) {
      clearInterval(this.interval);
    }

    if (this.intervalResources) {
      clearInterval(this.intervalResources);
    }

    if (this.intervalUsageInfo) {
      clearInterval(this.intervalUsageInfo);
    }

    if (this.intervalLastFive) {
      clearInterval(this.intervalLastFive);
    }

    if (this.tagBeaconInterval) {
      clearInterval(this.tagBeaconInterval);
    }
  },

  methods: {
    ...mapActions("user", {
      getResources: actionTypes.USER_GET_RESOURCES,
      getUsageInfo: actionTypes.USER_GET_USAGE_INFO,
      getEssentialInformation: actionTypes.USER_GET_ESSENTIAL_INFORMATION,
      getGlobalInformation: actionTypes.USER_GET_GLOBAL_INFORMATION,
      setIsChanging: actionTypes.USER_SET_IS_CHANGING,
      userSetResourcesTags: actionTypes.USER_SET_RESOURCES_TAGS
    }),

    ...mapActions("clients", {
      getClient: actionTypes.CLIENTS_GET_CLIENT,
      getAnchors: actionTypes.CLIENTS_GET_ANCHORS,
      updateSelectedAnchor: actionTypes.CLIENTS_UPDATE_SELECTED_ANCHOR,
      updateNextPosition: actionTypes.CLIENTS_UPDATE_NEXT_POSITION
    }),

    // finalSaveTag() {
    //   this.saveTag(false);
    //   this.certifyEditTagDialogSave = false;
    // },

    toHex(str, type) {
      if (type === "timestamp") return this.getTimeStampTransformed(str);
      else {
        return str
          .toString(16)
          .toUpperCase()
          .match(/.{1,2}/g)
          .join(type === "anchor" ? ":" : "");
      }
    },

    async getTagHistory(tag, pageSize) {
      try {
        const res = await tagsApi.getTagHistory(tag, pageSize);
        this.tagHistory.history = this.tagHistory.history.concat(res.data);
        this.tagHistory.history.sort(function(a, b) {
          if (a.lastSeenTs === b.lastSeenTs) return 0;
          else return a.lastSeenTs < b.lastSeenTs ? 1 : -1;
        });
      } catch (err) {
        this.$notify.error({
          title: this.$t("statusMessages.error"),
          message: this.$t("messages.genericError")
        });
      } finally {
        this.tagHistoryDialog.loading = false;
      }
    },

    async fetchAssetHistory(obj) {
      this.tagHistoryDialog.visible = true;
      this.getTagHistory(obj.tagId, 1000);
      this.tagHistory.tag = obj;
    },

    generateHeatmap(heatpoints) {
      this.heatLayerVar = L.heatLayer(heatpoints, {
        minOpacity: 0.0,
        radius: this.getRadius(),
        blur: this.getBlur(),
        maxZoom: this.getHeatMapMaxZoom(),
        gradient: {
          0.15: "blue",
          0.35: "#30AB0C",
          0.7: "#FDCD5A",
          0.9: "#FEA202",
          1.0: "red"
        }
      }).addTo(this.map);
    },

    async getTagBeaconList() {
      try {
        const res = await tagsApi.getTagBeaconList();
        this.tagBeaconList = res.data;

        this.tagBeaconList.sort((a, b) => {
          return b.lastTs - a.lastTs;
        });

        this.tagBeaconList.sort((a, b) => {
          const currentTagId = this.resources.sidebarAnchor?.currentTagId;
          if (a.tagId === currentTagId) return -1;
          if (b.tagId === currentTagId) return 1;
          return 0;
        });
      } catch (error) {
        console.error("Error fetching tag beacon list:", error);
      }
    },

    getBeaconList() {
      this.beaconList = this.tags.filter(
        tag => tag.active === true && tag.type == 0
      );
    },

    generateStayedTripHeatpointsGlobal(arr) {
      const values = arr.map(time => time[0]);
      const { mean, stdDev } = this.calculateMeanAndStdDev(values);

      const heatpoints = [];
      for (let i = 0; i < this.averageTimeHeatmapPoints.length; i++) {
        const zScore = (this.averageTimeHeatmapPoints[i][0] - mean) / stdDev;
        const colorValue = this.mapToColorRange(zScore); // Map z-score to color range
        heatpoints.push([
          this.tags[this.averageTimeHeatmapPoints[i][1]].lastPosition.y,
          this.tags[this.averageTimeHeatmapPoints[i][1]].lastPosition.x,
          colorValue
        ]);
      }

      return heatpoints;
    },

    generateFrequencyHeatmapGlobal(arr) {
      const heatpoints = [];
      const _self = this; // eslint-disable-line
      const dict = {};
      let currentKey = "";
      let currTag = null;
      for (let i = 0; i < arr.length; i++) {
        if (arr[i][1] < _self.tags.length) {
          currTag = _self.tags[arr[i][1]];
          currentKey =
            currTag.lastPosition.x.toString() +
            currTag.lastPosition.y.toString();
          if (currentKey in dict) {
            dict[currentKey][2] += arr[i][0];
          } else {
            dict[currentKey] = [
              currTag.lastPosition.x,
              currTag.lastPosition.y,
              arr[i][0]
            ];
          }
        } else {
          continue;
        }
      }

      const values = Object.values(dict).map(freq => freq[2]);
      const { mean, stdDev } = this.calculateMeanAndStdDev(values);

      // eslint-disable-next-line
      for (const [key, freq] of Object.entries(dict)) {
        const zScore = (freq[2] - mean) / stdDev;
        const colorValue = this.mapToColorRange(zScore); // Map z-score to color range
        heatpoints.push([freq[1], freq[0], colorValue]);
      }
      return heatpoints;
    },

    // Function to calculate mean and standard deviation
    calculateMeanAndStdDev(values) {
      const mean =
        values.reduce((sum, value) => sum + value, 0) / values.length;
      const stdDev = Math.sqrt(
        values
          .map(value => Math.pow(value - mean, 2))
          .reduce((sum, value) => sum + value, 0) / values.length
      );
      return { mean, stdDev };
    },

    // Function to map z-scores to the 0-1 range based on standard deviation ranges
    mapToColorRange(zScore) {
      if (!zScore) return 0.5;
      if (zScore < -1) return 0.15; // Blue
      if (zScore < -0.5) return 0.35; // Green
      if (zScore < 0.5) return 0.7; // Yellow to Orange
      if (zScore < 1) return 0.9; // Orange to Red
      return 1.0; // Red
    },

    generateStayedTripHeatpoints(stayedTrip) {
      const heatpoints = [];
      const dict = this.generateHeatmapDictionary(stayedTrip);

      // Extract the relevant values for mean and std dev calculation
      const values = Object.values(dict).map(time => time[2]);
      const { mean, stdDev } = this.calculateMeanAndStdDev(values);

      // eslint-disable-next-line
      for (const [key, time] of Object.entries(dict)) {
        const zScore = (time[2] - mean) / stdDev;
        const colorValue = this.mapToColorRange(zScore); // Map z-score to color range
        heatpoints.push([time[1], time[0], colorValue]);
      }
      return heatpoints;
    },

    generateFrequencyHeatpoints(frequencyCheckpoints) {
      const heatpoints = [];
      const dict = this.generateHeatmapDictionary(frequencyCheckpoints);

      // Extract the relevant values for mean and std dev calculation
      const values = Object.values(dict).map(freq => freq[2]);
      const { mean, stdDev } = this.calculateMeanAndStdDev(values);

      // eslint-disable-next-line
      for (const [key, freq] of Object.entries(dict)) {
        const zScore = (freq[2] - mean) / stdDev;
        const colorValue = this.mapToColorRange(zScore); // Map z-score to color range
        heatpoints.push([freq[1], freq[0], colorValue]);
      }
      return heatpoints;
    },

    generateHeatmapDictionary(arr) {
      const _self = this; // eslint-disable-line
      const dict = {};
      let currentKey = "";
      for (let i = 0; i < arr.length; i++) {
        _self.routeSelected.tags.forEach(tag => {
          if (tag.ord === i + 1) {
            currentKey =
              tag.lastPosition.x.toString() + tag.lastPosition.y.toString();
            if (currentKey in dict) {
              dict[currentKey][2] += arr[i];
            } else {
              dict[currentKey] = [
                tag.lastPosition.x,
                tag.lastPosition.y,
                arr[i]
              ];
            }
          }
        });
      }
      return dict;
    },

    removeSplinesAndPolyLines() {
      this.splines.forEach(spline => {
        this.map.removeLayer(spline);
      }, this);
      this.polyLines.forEach(polyLine => {
        this.map.removeLayer(polyLine);
      }, this);
    },

    calculateSpaghettiColor(ratio) {
      let color = "";
      if (ratio <= 0.15) color = "#84b7ff";
      if (ratio > 0.15 && ratio <= 0.35) color = "#5b9fff";
      if (ratio > 0.35 && ratio <= 0.7) color = "#3388ff";
      if (ratio > 0.7 && ratio <= 0.9) color = "#286ccc";
      if (ratio > 0.9 && ratio <= 1) color = "#1e5199";
      return color;
    },

    generateSpaghettiDiagram() {
      const latLngs = [];
      let max = 0;

      for (const key in this.tripFrequency) {
        this.tripFrequency[key].forEach(obj => {
          const avgTime = obj.totalTime / 60 / obj.count;
          //console.log('avgTime ', avgTime, 'maxavgtime ', this.lineGraphFilters.maxAvgTime, 'key ', key, 'tag id ', obj.tagId)
          if (
            obj.count <= this.lineGraphFilters.maxFreq &&
            obj.count >= this.lineGraphFilters.minFreq &&
            avgTime <= this.lineGraphFilters.maxAvgTime &&
            avgTime >= this.lineGraphFilters.minAvgTime
          ) {
            if (this.spaghettiFrequencyDiagramActive) {
              if (obj.count > max) max = obj.count;
            } else {
              if (avgTime > max) max = avgTime;
            }
            latLngs.push([
              parseInt(key),
              parseInt(obj.tagId),
              obj.count,
              avgTime
            ]);
          }
        });
      }

      const tags = cloneDeep(this.tags);
      const result = latLngs.flatMap(pair => {
        // Find the tag with the first tag ID in the pair
        const tag1 = tags.find(tag => tag.id === pair[0]);
        // Find the tag with the second tag ID in the pair
        const tag2 = tags.find(tag => tag.id === pair[1]);

        // Extract last_position.x and last_position.y from both tags
        const y1 = tag1 ? tag1.mapRef._latlng.lat : null;
        const x1 = tag1 ? tag1.mapRef._latlng.lng : null;
        const y2 = tag2 ? tag2.mapRef._latlng.lat : null;
        const x2 = tag2 ? tag2.mapRef._latlng.lng : null;

        // Return an array containing the coordinates
        return [
          [y1, x1, pair[2], pair[3]],
          [y2, x2, pair[2], pair[3]]
        ];
      });

      // Assuming `map` is your Leaflet map object
      this.splines = [];

      const values = this.spaghettiFrequencyDiagramActive
        ? result.map(time => time[2])
        : result.map(time => time[3]);
      const { mean, stdDev } = this.calculateMeanAndStdDev(values);

      // Iterate through the result array, taking every two elements
      for (let i = 0; i < result.length - 1; i += 2) {
        // Extract pairs of points
        const startPoint = result[i];
        const endPoint = result[i + 1];

        if (!startPoint[0] || !startPoint[1] || !endPoint[0] || !endPoint[1])
          continue;

        // The Middle Curvature Point
        const middlePoint = this.calculateCurvePoint(startPoint, endPoint);
        const newyoffset = middlePoint[2];
        const newxoffset = middlePoint[3];

        // The Ratio between the current count value and the max count value
        // const ratio = this.spaghettiFrequencyDiagramActive
        //   ? startPoint[2] / max
        //   : startPoint[3] / max;

        const zScore = this.spaghettiFrequencyDiagramActive
          ? (startPoint[2] - mean) / stdDev
          : (startPoint[3] - mean) / stdDev;

        const ratio = this.mapToColorRange(zScore);

        const color = this.calculateSpaghettiColor(ratio);
        console.log(ratio);

        // Create a spline between the points
        const spline = L.spline(
          [
            [startPoint[0], startPoint[1]],
            [middlePoint[0], middlePoint[1]],
            [endPoint[0], endPoint[1]]
          ],
          {
            color: color,
            weight: this.calculateSplineWeight(this.map.getZoom(), ratio),
            smoothness: 0,
            ratio: ratio
          }
        ).addTo(this.map);

        const decorator = L.polylineDecorator(
          [
            [middlePoint[0], middlePoint[1]],
            [endPoint[0] + newyoffset, endPoint[1] - newxoffset]
          ],
          {
            ratio: ratio,
            patterns: [
              // defines a pattern of 10px-wide dashes, repeated every 20px on the line
              {
                //offset: "50%",
                repeat: 0, // To guarantee that it does not repeat and only draws one arrow in the middle
                symbol: L.Symbol.arrowHead({
                  pixelSize: this.calculateArrowHeadSize(ratio),
                  polygon: false,
                  pathOptions: { stroke: true, color: color }
                })
              }
            ]
          }
        ).addTo(this.map);

        this.splines.push(spline);
        this.polyLines.push(decorator);
      }
    },

    calculateCurvePoint(P1, P2, curvature = 0.1) {
      // Calculate midpoint between P1 and P2
      const midPoint = [(P1[0] + P2[0]) / 2, (P1[1] + P2[1]) / 2];
      const slope = (P1[0] - P2[0]) / (P1[1] - P2[1]);

      const angle = slope ? Math.atan(slope) : Math.PI / 2;

      const newxoffset = Math.sin(angle) * 10;
      const newyoffset = Math.cos(angle) * 10;

      // Calculate perpendicular vector to the line segment P1-P2
      const dy = P2[0] - P1[0];
      const dx = P2[1] - P1[1];

      // Adjust curvature direction based on the line segment direction
      curvature = 1;
      if (dx < 0) {
        curvature *= -1;
      } else if (dx == 0) if (dy > 0) curvature *= -1;
      //if (dy < 0) {
      //curvature *= -1;
      //}
      // if(Math.abs(dx)<Math.abs(dy)){
      //   if (dx < 0) {
      //     curvature *= -1; // Reverse curvature for right/down arch
      //   } else if (dx == 0) {
      //     if (dy < 0) curvature *= -1;
      //   }
      // }else{

      //   if (dy > 0) {
      //     curvature *= -1; // Reverse curvature for right/down arch
      //   } else if (dy == 0) {
      //     if (dx < 0) curvature *= -1;
      //   }
      // }

      const controlPoint = [
        midPoint[0] + newyoffset * curvature,
        midPoint[1] - newxoffset * curvature,
        newyoffset * curvature,
        newxoffset * curvature
      ];

      return controlPoint;
    },

    getRouteTotalHours() {
      const currentTime = moment.tz(this.clientData.timezone);
      let info = this.resourceInfo.details;
      if (this.resourceInfo.type === "tag") info = this.currentRoute;
      if (info != null && info.currentRouteInstance?.startDate) {
        const routeStart = moment.tz(
          moment.unix(info.currentRouteInstance.startDate),
          this.clientData.timezone
        );

        if (Math.floor(currentTime.diff(routeStart, "seconds") / 3600) >= 1)
          return (
            Math.floor(
              currentTime.diff(routeStart, "seconds") / 3600
            ).toString() + "h"
          );
        else return "";
      } else return "";
    },

    getRouteTotalMinutes() {
      let info = this.resourceInfo.details;
      if (this.resourceInfo.type === "tag") info = this.currentRoute;
      if (info != null && info.currentRouteInstance?.startDate) {
        const minutes = Math.floor(
          (moment()
            .utc()
            .diff(
              moment.tz(
                moment.unix(info.currentRouteInstance.startDate),
                this.clientData.timezone
              ),
              "seconds"
            ) %
            3600) /
            60
        );

        const finalMinutes = minutes < 10 ? "0" + minutes : minutes;
        return finalMinutes + "m";
      } else return "";
    },

    getRouteTotalSeconds() {
      if (
        this.resourceInfo.details != null &&
        this.resourceInfo.details.currentRouteInstance?.startDate
      ) {
        const secondsDiff = Math.floor(
          (moment()
            .utc()
            .diff(
              moment.tz(
                moment.unix(
                  this.resourceInfo.details.currentRouteInstance.startDate
                ),
                this.clientData.timezone
              ),
              "seconds"
            ) %
            3600) %
            60
        );
        if (secondsDiff < 10) return "0" + secondsDiff + "s";
        else return secondsDiff + "s";
      } else return "";
    },

    updateCurrentTime() {
      const hours = this.getRouteTotalHours();
      const minutes = this.getRouteTotalMinutes();
      const seconds = this.getRouteTotalSeconds();

      this.currentTime = hours + minutes + seconds;
    },

    setTagsToRender() {
      this.tags = cloneDeep(this.tagsToRender);
      // this.tags.forEach(elem => {
      //   elem.childTags = [];
      // });

      // const _self = this; // eslint-disable-line
      // this.tags.forEach(element => {
      //   const index = _self.tags.findIndex(elem => elem.id == element.parentId);
      //   if (index !== -1) {
      //     _self.tags[index].childTags.push({
      //       id: element.tagId,
      //       label: element.label,
      //       parentId: element.parentId
      //     });
      //   }
      // });
      this.addResourcesToMap(this.tags, "tag");
    },

    // confirmSave() {
    //   if (this.tagsSelected.length != 0 || this.tagToReplace != null)
    //     this.certifyEditTagDialogSave = true;
    //   else this.saveTag(false);
    // },

    discardChanges() {
      this.tagsSelected = [];
      this.tagToReplace = null;
      this.parentInfo = null;
      this.certifyEditTagDialogDiscard = false;
      this.editTagDialog.visible = false;
    },

    verifyChanges() {
      if (this.tagsEdited.length && this.isEditing)
        this.discardChangesDialog = true;
      else this.closeEditMode(true);
    },

    discardBeaconChanges() {
      this.isEditing = false;
      this.discardChangesDialog = false;
      this.closeEditMode(true);
      this.$emit("reset-tags");
    },

    // removeTagFromSelected(tag) {
    //   this.tagsSelected = this.tagsSelected.filter(elem => elem.id !== tag.id);
    //   this.tagsToSend = this.tagsToSend.filter(elem => elem !== tag.id);
    //   this.orphanTags.push(
    //     this.tags[this.tags.findIndex(elem => elem.tagId === tag.id)]
    //   );
    //   this.$forceUpdate();
    // },

    // removeTag(tag) {
    //   this.tagToEdit.childTags = this.tagToEdit.childTags.filter(
    //     elem => elem.id !== tag.id
    //   );
    //   this.tagsToDelete.push(tag.id);
    //   this.orphanTags.push(
    //     this.tags[this.tags.findIndex(elem => elem.tagId === tag.id)]
    //   );
    //   this.$forceUpdate();
    // },

    setImage(event) {
      this.imageToUpload = event.target.files[0];
    },

    async getLastFive() {
      if (this.selectedAnchor) {
        const res = await AnchorsApi.getLastFive(this.selectedAnchor.anchorId);
        this.lastFive = res.data;
      }
    },

    async getEssentialInformationInterval() {
      if (this.sidebarAnchor)
        this.getEssentialInformation(this.sidebarAnchor.anchorId);
    },

    async getGlobalInformationInterval() {
      this.getGlobalInformation(this.clientData?.id);
    },

    async uploadImage() {
      const data = new FormData();
      data.append("image", this.imageToUpload);

      try {
        await clientsApi.uploadImage(data, this.clientData.id);
        this.$notify({
          title: this.$t("statusMessages.success"),
          message: this.$t("messages.imageUploadSuccess"),
          type: "success"
        });
        this.$router.go();
      } catch (err) {
        this.$notify.error({
          title: this.$t("statusMessages.error"),
          message: this.$t("messages.genericError")
        });
      }
    },

    getHeatMapMaxZoom() {
      return this.map.getZoom();
    },

    getRadius() {
      if (this.map.getZoom() < 0) {
        this.currentHeatmapRadius =
          this.baseRadius / Math.pow(2, Math.abs(this.map.getZoom()));
      } else if (this.map.getZoom() == 0) {
        this.currentHeatmapRadius = this.baseRadius;
      } else if (this.map.getZoom() > 0) {
        this.currentHeatmapRadius =
          this.baseRadius * Math.pow(2, Math.abs(this.map.getZoom()));
      }
      localStorage.setItem("current-radius", this.currentHeatmapRadius);
      const finalRadius =
        this.currentHeatmapRadius * this.heatmapSize < 100
          ? this.currentHeatmapRadius * this.heatmapSize
          : 95;

      return parseFloat(finalRadius.toFixed(4));
    },

    getBlur() {
      return this.currentHeatmapRadius * this.heatmapSize * 0.3;
    },

    calculateSplineWeight(zoomLevel, ratio) {
      // Define weight values corresponding to different ranges of the ratio variable
      let weight;
      if (ratio > 0.9 && ratio <= 1) {
        weight = 10;
      } else if (ratio > 0.7 && ratio <= 0.9) {
        weight = 8;
      } else if (ratio > 0.35 && ratio <= 0.7) {
        weight = 6;
      } else if (ratio > 0.15 && ratio <= 0.35) {
        weight = 4;
      } else {
        weight = 2;
      }

      if (zoomLevel < 0) {
        // Adjust the weight based on the zoom level
        const baseWeight = 1.05; // Weight when zoom level is -1.5
        const scaleFactor = Math.pow(1.4, Math.abs(zoomLevel));
        const adjustedWeight = baseWeight / scaleFactor;

        // Return the weighted value based on the ratio
        return Math.max(2, weight * adjustedWeight);
      }
      return weight;
    },

    calculateArrowHeadSize(ratio) {
      let pixelSize = 20;
      if (this.map.getZoom() < 0) {
        const basePixelSize = 13; // Adjust as needed
        const scaleFactor = Math.pow(1.3, Math.abs(this.map.getZoom())); // Adjust the scaling factor as needed
        pixelSize = basePixelSize / scaleFactor + ratio * 5; // Calculate the adjusted pixel size
      }
      return pixelSize;
    },

    async initMap() {
      const startingZoom = localStorage.getItem("map-zoom");
      const startingCenter = JSON.parse(localStorage.getItem("map-center"));

      if (localStorage.getItem("map-resource-size") != null)
        this.resourcesSize = Number(localStorage.getItem("map-resource-size"));
      else this.resourcesSize = 5;

      if (localStorage.getItem("current-radius") != null)
        this.currentHeatmapRadius = Number(
          localStorage.getItem("current-radius")
        );
      else this.currentHeatmapRadius = 60;

      if (localStorage.getItem("heatmap-size") != null)
        this.heatmapSize = Number(localStorage.getItem("heatmap-size"));
      else this.heatmapSize = 1;

      const heatmapType = localStorage.getItem("current-heatmap");
      if (heatmapType === "average") {
        this.averageTimeHeatmapActive = true;
        this.frequencyHeatmapActive = false;
      } else if (heatmapType === "frequency") {
        this.averageTimeHeatmapActive = false;
        this.frequencyHeatmapActive = true;
      }

      this.map = L.map("image-map", {
        minZoom: -2,
        maxZoom: 1.2,
        zoomSnap: 0.5,
        zoomDelta: 1,
        wheelPxPerZoomLevel: 120,
        center: startingCenter || [0, 0],
        zoom: startingZoom || 0,
        crs: L.CRS.Simple,
        zoomControl: this.areMapControlsVisible
      });
      this.addImageToMap();
      this.baseRadius = 150 / (Math.pow(2, this.map.options.maxZoom) * 2);

      const _self = this; // eslint-disable-line

      this.map.on("zoomend", function() {
        if (
          _self.heatLayerVar &&
          _self.$route.name !== routerTypes.ROUTE_DASHBOARD
        ) {
          _self.heatLayerVar.setOptions({
            radius: _self.getRadius(),
            blur: _self.getBlur(),
            maxZoom: _self.getHeatMapMaxZoom()
          });
          _self.splines.forEach(spline => {
            const newWeight = _self.calculateSplineWeight(
              _self.map.getZoom(),
              spline.options.ratio
            );
            spline.setStyle({
              weight: newWeight
            });
            spline.redraw();
          });
          _self.polyLines.forEach(head => {
            const pixelSize = _self.calculateArrowHeadSize(head.options.ratio);
            const color = _self.calculateSpaghettiColor(head.options.ratio);

            // Update the arrowhead size for each PolylineDecorator in the array
            head.setPatterns([
              {
                //offset: "50%",
                repeat: 0, // To guarantee that it does not repeat and only draws one arrow in the middle
                symbol: L.Symbol.arrowHead({
                  pixelSize: pixelSize,
                  polygon: false,
                  pathOptions: { stroke: true, color: color }
                })
              }
            ]);
          });
          _self.heatLayerVar.redraw();
        }
      });

      // Create groups and attach event listeners
      this.anchorsGroup = L.featureGroup().addTo(this.map);
      this.tagsGroup = L.featureGroup().addTo(this.map);
      // this.anchorsGroup.on("click", this.onAnchorCLick);
      this.tagsGroup.on("click", this.onTagClick);

      this.handleMapProperties();

      if (this.tagsToRender) {
        this.setTagsToRender();
      }

      if (
        this.intervalUpdate &&
        this.$route.name === "dashboard" &&
        !this.isTracker
      ) {
        this.interval = setInterval(
          function(_self) {
            _self.getEssentialInformationInterval();
          },
          2000,
          this
        );
      } else if (
        this.$route.name === "live-global-map" ||
        (this.$route.name === "dashboard" && this.isTracker)
      ) {
        this.getGlobalInformationInterval();
        this.interval = setInterval(
          function(_self) {
            _self.getGlobalInformationInterval();
          },
          2000,
          this
        );
      }
    },

    // addTagToGroup() {
    //   const tagSelected = this.childTagToAdd;
    //   const index = this.tags.findIndex(elem => elem.tagId === tagSelected);
    //   this.tagsSelected.push({
    //     id: tagSelected,
    //     label: this.tags[index].label
    //   });
    //   this.tagsToSend.push(tagSelected);
    //   this.childTagToAdd = null;
    //   this.orphanTags = this.orphanTags.filter(
    //     elem => elem.tagId !== tagSelected
    //   );
    // },

    addImageToMap(url) {
      const ghostImage = new Image();
      if (this.clientData.mapImages.length)
        ghostImage.src = this.clientData.mapImages[0].url;

      const _self = this; // eslint-disable-line
      ghostImage.onload = function() {
        const height = ghostImage.height;
        const width = ghostImage.width;

        _self.image.url = url;
        _self.image.width = width;
        _self.image.height = height;
        const mapPadding = 100;
        _self.currentWidth = width;

        L.imageOverlay(ghostImage, [
          [0, 0],
          [_self.image.height, _self.image.width]
        ]).addTo(_self.map);

        _self.map.setMaxBounds([
          [-mapPadding, -mapPadding],
          [_self.image.height + mapPadding, _self.image.width + mapPadding]
        ]);

        _self.mapBounds = [
          [-mapPadding, -mapPadding],
          [_self.image.height + mapPadding, _self.image.width + mapPadding]
        ];

        _self.map.options.minZoom =
          Math.sqrt(Math.max(width, height) / 400) * -1;
        _self.map.options.maxZoom = 1.2; //Math.sqrt(Math.max(width, height) / 400);

        _self.baseRadius = 150 / (Math.pow(2, _self.map.options.maxZoom) * 2);
      };
    },

    fitMapToBounds() {
      this.map.fitBounds(this.mapBounds);
    },

    createNextPosition(anchor) {
      if (!anchor || this.$route.name !== routerTypes.ROUTE_DASHBOARD) return;

      if (anchor.nextPosition?.y && anchor.nextPosition?.x) {
        this.nextPosition = L.circle(
          [anchor.nextPosition.y, anchor.nextPosition.x],
          {
            id: anchor.id,
            anchorId: anchor.anchorId,
            lastPosition: true,
            color: "#ffb833",
            fillColor: "#ffb833",
            opacity: anchor.lastPositionMetadata?.state === "LEFT" ? 1 : 0.2,
            fillOpacity: 0.4,
            //anchor.lastPositionMetadata?.state === "LEFT" ? 1 : 0.1,
            radius: this.resourcesSize * 2.5 + 18,
            className:
              anchor.lastPositionMetadata?.state === "LEFT"
                ? "next-position"
                : ""
          }
        ).addTo(this.anchorsGroup);

        this.nextPosition.bringToBack();
      }
    },

    createMapAnchor(anchor) {
      if (
        this.$route.name === "live-global-map" ||
        (this.isTracker && this.isLiveMap)
      ) {
        let belongs = false;
        let currElement;
        if (this.isTracker) {
          currElement = this.selectedAnchors.length
            ? this.selectedAnchors.find(
                element => element.anchorId === anchor.anchorId
              )
            : null;
        } else {
          currElement = this.anchorsToShow
            ? this.anchorsToShow.find(
                element => element[0].anchorId === anchor.anchorId
              )
            : null;
        }
        if (currElement != null) belongs = true;
        /*this.anchorsToShow.forEach(element => {
          if (element[0].anchorId === anchor.anchorId) belongs = true;
        });*/
        if (!belongs) return;
        else if (
          ((this.isTracker &&
            !this.isAnchorOffline(anchor.powerSource, anchor.offlineTs)) ||
            !this.isTracker) &&
          anchor.lastPosition?.x &&
          anchor.lastPosition?.y
        ) {
          let overlappingAnchors = 1;
          if (this.anchorMap && this.anchorMap.has(anchor.lastPosition?.id))
            overlappingAnchors = this.anchorMap.get(anchor.lastPosition.id);
          const angleDivided = 360 / overlappingAnchors;
          const positionInCircle = anchor.positionInCircle ?? 0;
          const circleAnchor = L.semiCircle(
            [anchor.lastPosition.y, anchor.lastPosition.x],
            {
              id: anchor.id,
              anchorId: anchor.anchorId,
              color: this.isTracker ? currElement.color : currElement[1],
              fillColor: this.isTracker ? currElement.color : currElement[1],
              opacity:
                anchor.lastPositionMetadata?.state === "ENTERED" ? 1 : 0.2,
              fillOpacity: 0.4,
              radius: this.resourcesSize * 5 + 18,
              startAngle: angleDivided * positionInCircle,
              stopAngle: angleDivided * (positionInCircle + 1)
            }
          ).addTo(this.anchorsGroup);
          circleAnchor.bringToBack();

          return circleAnchor;
        }
      } else if (this.visibleAnchor === anchor.anchorId && anchor.activeRoute) {
        if (anchor.lastPosition?.x && anchor.lastPosition?.y) {
          let shouldReturn = false;
          this.tags.forEach(tag => {
            if (tag.id === anchor.lastPosition.tagId)
              if (!tag.active) shouldReturn = true;
          });
          if (shouldReturn) return;

          const circleAnchor = L.circle(
            [anchor.lastPosition.y, anchor.lastPosition.x],
            {
              id: anchor.id,
              anchorId: anchor.anchorId,
              color: "#32CD32",
              fillColor: "#32CD32",
              opacity:
                anchor.lastPositionMetadata?.state === "ENTERED" ? 1 : 0.2,
              fillOpacity: 0.4,
              radius: this.resourcesSize * 5 + 18
            }
          ).addTo(this.anchorsGroup);

          circleAnchor.bringToBack();

          return circleAnchor;
        }
      }
    },

    isEmpty(obj) {
      return Object.keys(obj).length === 0;
    },

    updateHighlightedBeacon(beacon = null, prevTagId = null) {
      if (!beacon) return;

      const circleTagToAdd = L.circle(
        [beacon.lastPosition.y, beacon.lastPosition.x],
        {
          id: beacon.id,
          tagId: beacon.tagId,
          color: beacon.colorToHighlight,
          fillColor: beacon.colorToHighlight,
          opacity: beacon.parentId != null ? 0.5 : 1,
          fillOpacity: 0.7,
          radius: this.resourcesSize * 5 + 18,
          draggable: false
        }
      );

      circleTagToAdd.bindPopup(`${beacon.label ?? beacon.id}`);
      circleTagToAdd.on("mouseover", function() {
        this.openPopup();
      });
      circleTagToAdd.on("mouseout", function() {
        this.closePopup();
      });

      if (prevTagId == beacon.tagId && this.currentCircle) {
        this.map.removeLayer(this.currentCircle);
        this.currentCircle = null;
        this.trackerHighlightedBeacon = null;
      } else {
        if (this.currentCircle) {
          this.map.removeLayer(this.currentCircle);
        }
        this.currentCircle = circleTagToAdd;
        this.map.addLayer(circleTagToAdd);
        circleTagToAdd.bringToBack();
        circleTagToAdd.openPopup();
        this.trackerHighlightedBeacon = beacon;
      }
    },

    createMapTag(tag, edited, beaconLocation = false) {
      if (
        tag.active &&
        tag.lastPosition?.x &&
        tag.lastPosition?.y &&
        !tag.destroyed
      ) {
        const highlight =
          !this.isEditing &&
          this.highlightedBeacon != null &&
          this.highlightedBeacon.tagId === tag.tagId;

        const position = {
          x: tag.lastPosition.x,
          y: tag.lastPosition.y
        };

        if (this.isEditing && !beaconLocation) {
          const tagToUpdate = this.tagsToUpdate.find(
            _tag => _tag.tagId === tag.id
          );

          if (tagToUpdate) {
            position.x = tagToUpdate.x;
            position.y = tagToUpdate.y;
          }
        }
        let radius = 0;
        if (tag.parentId == null)
          radius = highlight
            ? this.resourcesSize * 3 + 18
            : this.resourcesSize * 3;
        else
          radius = highlight
            ? this.resourcesSize * 1.5 + 18
            : this.resourcesSize * 1.5;
        const circleTag = L.circle([position.y, position.x], {
          id: tag.id,
          tagId: tag.tagId,
          color: tag.color ? "yellow" : "#1F4258",
          fillColor: edited ? "green" : "#1F4258",
          opacity: tag.parentId != null ? 0.5 : 1,
          fillOpacity: 0.7,
          radius: radius,
          draggable: true
        });
        if (this.$route.name === "live-global-map" || this.isLiveMap) {
          this.circleTags[tag.tagId] = circleTag;
          circleTag.bindPopup(`${tag.label ?? tag.id}`);
          circleTag.on("mouseover", function() {
            this.openPopup();
          });
          circleTag.on("mouseout", function() {
            this.closePopup();
          });
        }
        if (this.enumerateTags.length || this.tagsToRender?.length) {
          let tagsFound;

          if (this.tagsToRender?.length) {
            tagsFound = this.tagsToRender.filter(_tag => {
              return _tag.id === tag.id;
            });
          } else {
            tagsFound = this.enumerateTags.filter(_tag => {
              return (
                _tag.tagPositionId === tag.lastPosition.id ||
                _tag.tagPositionId.id === tag.lastPosition.id
              );
            });
          }

          if (tagsFound.length) {
            if (tagsFound[0].color)
              circleTag.setStyle({
                fillColor: "rgba(255, 184, 51, 0.7)",
                fillOpacity: 1.0
              });
            let tooltipText = "";
            if (tagsFound.length > 1) tooltipText = tagsFound[0].ord.toString();
            if (this.orders[tag.lastPosition.id]) {
              if (this.orders[tag.lastPosition.id].includes("|"))
                tooltipText = tagsFound[0].renderIdentifier
                  ?.toString()
                  .split(" | ")[0];
            }
            if (!this.isLiveMap) {
              circleTag.bindTooltip(
                tagsFound.length > 1 || tooltipText != ""
                  ? `<u>${tooltipText}</u>`
                  : this.tagsToRender?.length
                  ? tagsFound[0].renderIdentifier?.toString().split(" | ")[0]
                  : tagsFound[0].ord
                  ? tagsFound[0].ord.toString()
                  : "N/A",
                {
                  permanent: true,
                  direction: "center",

                  className: this.isEmpty(this.orders)
                    ? "leaflet-tooltip__numbered"
                    : this.orders[tag.lastPosition.id]
                    ? "leaflet-tooltip__numbered"
                    : "leaflet-tooltip__numbered_color"
                }
              );
            }

            if (tagsFound.length > 1) {
              const popupContent = tagsFound.map(_tag => _tag.ord);
              if (this.$route.name == "stats-laps-details")
                circleTag.bindPopup(
                  `<b>${popupContent.join(" | ")}.</b><br>${tag.label}`
                );
              else if (!this.isLiveMap) {
                circleTag.bindPopup(popupContent.join(" | "));
              }
              circleTag.on("mouseover", function() {
                this.openPopup();
              });
              circleTag.on("mouseout", function() {
                this.closePopup();
              });
            } else if (this.orders) {
              let popupContent = "";
              if (this.$route.name == "stats-laps-details") {
                popupContent = `<b>${
                  tagsFound[0].ord ? tagsFound[0].ord : "N/A"
                }</b><br>${tag.label}`;
              } else if (!this.isLiveMap) {
                popupContent = `<b>${
                  tag.renderIdentifier ? tag.renderIdentifier : "N/A"
                }</b><br>${tag.label}`;
              }
              // else if (this.isLiveMap) {
              //   popupContent = `${tag.label}`;
              // }
              if (popupContent) {
                circleTag.bindPopup(popupContent);
                circleTag.on("mouseover", function() {
                  this.openPopup();
                });
                circleTag.on("mouseout", function() {
                  this.closePopup();
                });

                // if (this.isLiveMap) {
                //   this.circleTags[tag.tagId] = circleTag;
                // }
              }
            }

            if (this.showNumberedOnly) {
              circleTag.addTo(this.tagsGroup);
            }
          }
        }

        if (!this.showNumberedOnly) {
          circleTag.addTo(this.tagsGroup);
        }

        if (this.isEditing) {
          circleTag.dragging.enable();
        } else {
          circleTag.dragging.disable();
        }
        return circleTag;
      }
    },

    createResource(resourceType) {
      return {
        tag: this.createMapTag,
        anchor: this.createMapAnchor
      }[resourceType];
    },

    setTagsEditable() {
      const _self = this; // eslint-disable-line
      //this.dashboardSlotOpen = false;
      this.tagsBeforeEdit = cloneDeep(this.tags);
      this.removeResourceFromMap(this.anchors, "anchor");
      if (this.nextPosition) {
        this.map.removeLayer(this.nextPosition);
      }
      this.tags.forEach(tag => {
        const tagRef = tag.mapRef;
        if (tagRef) {
          tagRef.dragging.enable();
          this.setDragListenerOnTag(tagRef);
        }
        _self.isEditing = true;
      });

      this.map.on("click", function(e) {
        const x = Math.round(e.latlng.lng);
        const y = Math.round(e.latlng.lat);

        if (
          x >= 0 &&
          x <= _self.image.width &&
          y >= 0 &&
          y <= _self.image.height
        ) {
          _self.moveTagDialog.visible = true;
          _self.moveTagDialog.nextPosition.x = parseInt(e.latlng.lng);
          _self.moveTagDialog.nextPosition.y = parseInt(e.latlng.lat);
        }
      });
      if (this.activeChecked != "all")
        this.tagsToShow = this.tags.filter(
          tag =>
            tag.active === this.activeChecked && !tag.destroyed && tag.type == 0
        );
      else this.tagsToShow = this.tags.filter(tag => tag.type == 0);
    },

    updateResource(array, resource, resourceType) {
      const resourceIndex = array.findIndex(
        _resource => _resource.id === resource.id
      );

      if (resourceIndex >= 0) {
        if (array[resourceIndex].mapRef) {
          this.map.removeLayer(array[resourceIndex].mapRef);
        }
        resource.mapRef = this.createResource(resourceType)(resource);
        array[resourceIndex] = resource;
      } else {
        resource.mapRef = this.createResource(resourceType)(resource);
        array.push(resource);
      }
    },

    updateResources() {
      this.tags.forEach(tag => {
        this.updateResource(this.tags, tag, "tag");
      });

      if (!this.isEditing) {
        this.anchors.forEach(anchor => {
          this.updateResource(this.anchors, anchor, "anchor");
        });
      }
    },

    removeResourceFromMap(array, resourceType) {
      if (
        resourceType === "anchor" &&
        !this.showAnchors &&
        !this.isTracker &&
        !this.isLiveMap
      ) {
        return;
      }
      array.forEach(resource => {
        if (resource.mapRef) {
          this.map.removeLayer(resource.mapRef);
        }
      });
    },

    addResourcesToMap(array, resourceType) {
      if (
        resourceType === "anchor" &&
        !this.showAnchors &&
        !this.isTracker &&
        !this.isLiveMap
      ) {
        return;
      }

      const resources = {
        anchor: this.createMapAnchor,
        tag: this.createMapTag
      };

      array.forEach(resource => {
        resource.mapRef = resources[resourceType](resource);
      });
      if (this.highlightedBeacon) this.highlightResource();
    },

    async closeEditMode(discard) {
      if (discard) {
        this.resourceInfo.type = "anchor";
        this.resourceInfo.details = await this.updateAnchor(
          this.selectedAnchor.anchorId
        );
        this.removeResourceFromMap(this.tags, "tag");
        this.removeResourceFromMap(this.tagsEditedCircles, "tags");
        this.tagsToUpdate = [];
        this.tagsEdited = [];
        this.tags = cloneDeep(this.tagsBeforeEdit);
        // this.tags.forEach(elem => {
        //   elem.childTags = [];
        // });

        // const _self = this; // eslint-disable-line
        // this.tags.forEach(element => {
        //   const index = _self.tags.findIndex(
        //     elem => elem.id == element.parentId
        //   );
        //   if (index !== -1) {
        //     _self.tags[index].childTags.push({
        //       id: element.tagId,
        //       label: element.label,
        //       parentId: element.parentId
        //     });
        //   }
        // });
        this.tagsBeforeEdit = [];
        this.isEditing = false;
        this.addResourcesToMap(this.tags, "tag");
        this.addResourcesToMap(this.anchors, "anchor");
        this.createNextPosition(this.resourceInfo.details);
      } else {
        //this.tags.forEach(tag => {
        //  if (tag.mapRef) {
        //    tag.mapRef.dragging.disable();
        //   }
        //});

        this.addResourcesToMap(this.anchors, "anchor");

        // Put back nextPosition if available
        if (this.resourceInfo.type === "anchor") {
          this.createNextPosition(this.resourceInfo.details);
        }
      }

      this.map.removeEventListener("click");
      this.isEditing = false;
      this.dashboardSlotOpen = true;
    },

    updateChildTags(tagList) {
      const updatedTagList = tagList;
      updatedTagList.forEach(elem => {
        elem.childTags = [];
      });
      updatedTagList.forEach(element => {
        const index = updatedTagList.findIndex(
          elem => elem.id == element.parentId
        );
        if (index !== -1) {
          updatedTagList[index].childTags.push({
            id: element.tagId,
            label: element.label,
            parentId: element.parentId
          });
        }
      });

      this.userSetResourcesTags(updatedTagList);
    },

    async updateTagList() {
      try {
        const { data } = await tagsApi.getRefreshStatus();
        this.updateChildTags(data);
      } catch (_) {
        // DO nothing
      }
    },

    async editTags() {
      this.dashboardSlotOpen = true;
      if (this.isEditing) {
        if (this.tagsToUpdate.length) {
          try {
            this.gatewaysAffected = [];
            let tagsChanged = null;
            let filteredTag = null;
            const tagsAffected = [];

            this.tagsToUpdate.forEach(_tagToUpdate => {
              filteredTag = this.tags.filter(
                _tag => _tag.id === _tagToUpdate.tagId
              );
              tagsAffected.push(filteredTag[0]);
            });
            const res = await this.getAnchors(
              this.resourceInfo.details.clientId
            );
            this.anchors = cloneDeep(res.data);
            this.anchors.forEach(anchor => {
              if (anchor.activeRoute) {
                anchor.activeRoute.tags.forEach(tag => {
                  tagsChanged = tagsAffected.filter(
                    _tag => _tag.lastPosition.id === tag.tagPositionId
                  );
                  if (tagsChanged.length > 0) {
                    this.gatewaysAffected.push(anchor.label);
                  }
                });
                this.gatewaysAffected = uniq(this.gatewaysAffected);
              }
            });
            if (this.gatewaysAffected.length > 0) {
              this.gatewaysAffectedDialog = true;
            } else {
              this.nextTimestamp = Math.floor(
                (new Date().getTime() - 3000) / 1000
              );
              await positionLogsApi.createTags(this.tagsToUpdate);
              this.updateTagList();
              this.tagsToUpdate = [];
              this.closeEditMode(false);
              this.tagsToShow = [];
            }
            this.$emit("reset-tags");
            this.setIsChanging(false);
          } catch (err) {
            this.$notify.error({
              title: this.$t("statusMessages.error"),
              message: this.$t("messages.genericError")
            });
          }
        }
      } else {
        this.setTagsEditable();
      }
    },

    setDragListenerOnTag(tagRef) {
      const _self = this; // eslint-disable-line
      tagRef.on("dragend", function(e) {
        const tagToUpdate = {
          tagId: e.target.options.id,
          x: parseInt(e.target._latlng.lng),
          y: parseInt(e.target._latlng.lat)
        };

        const findIndex = _self.tagsToUpdate.findIndex(
          _tag => _tag.tagId === tagToUpdate.tagId
        );

        if (findIndex >= 0) {
          _self.tagsToUpdate[findIndex] = tagToUpdate;
        } else {
          _self.tagsToUpdate.push(tagToUpdate);
          const index = _self.tags.findIndex(
            _tag => _tag.id === tagToUpdate.tagId
          );
          _self.$emit("tag-dragged", _self.tags[index], "MOVED");
          tagRef.setStyle({ fillColor: "green" });
          /*_self.removeResourceFromMap([_self.tags[index]]);
          _self.tags[index].mapRef = _self.createMapTag(
            _self.tags[index],
            true
          );
          _self.tags[index].mapRef.dragging.enable();
          _self.setDragListenerOnTag(_self.tags[index].mapRef);*/
          _self.tagsEdited.push(_self.tags[index]);
        }
      });
    },

    thresholdToRange(threshold) {
      return threshold >= -16 ? 0 : -threshold - 16;
    },

    editTag() {
      const tag = this.tags.find(
        tag => tag.tagId === this.beaconToHighlight.tagId
      );
      this.tagToEdit = tag;
      this.editTagDialog.visible = true;
      // const _self = this; // eslint-disable-line
      // this.orphanTags = this.tempOrphanTags.filter(t => t.id !== tag.id);
      // this.inactiveTags = this.tags.filter(tag => tag.active === false);
      // this.inactiveTags = [
      //   {
      //     tagId: 0,
      //     id: 0,
      //     label: "Reset Selection"
      //   }
      // ].concat(this.inactiveTags);
      // this.parentInfo = this.tags.find(function(element) {
      //   return element.id === tag.parentId;
      // });
      // this.tagForm.tagId = tag.tagId;
      // this.tagForm.label = tag.label;
      // this.tagForm.active = tag.active;
      // this.tagForm.threshold = this.thresholdToRange(tag.threshold);
      // this.tagForm.battery = tag.battery;
      // this.tagForm.lastSeen = tag.lastUpdate;
      // if (this.isTracker) {
      //   this.tagForm.status = tag.status;
      // }
      // this.replaceTagForm.tagId = tag.id;
    },

    // async replaceTag() {
    //   this.replaceTagDialog.loading = true;
    //   try {
    //     const tag = this.tags.find(tag => tag.id === this.tagToReplace);
    //     const oldTag = this.tags.find(
    //       tag => tag.id === this.highlightedBeacon.tagId
    //     );

    //     const lastPosition = oldTag.lastPosition;
    //     await positionLogsApi.updateTagPosition(this.highlightedBeacon.id, {
    //       tagId: tag.id,
    //       x: lastPosition.x,
    //       y: lastPosition.y
    //     });

    //     this.$notify({
    //       title: this.$t("statusMessages.success"),
    //       message: `Beacon Replaced`,
    //       type: "success"
    //     });
    //   } catch (err) {
    //     this.$notify.error({
    //       title: this.$t("statusMessages.error"),
    //       message: this.$t("messages.genericError")
    //     });
    //   } finally {
    //     this.replaceTagDialog.loading = false;
    //     this.replaceTagDialog.visible = false;
    //   }
    // },

    handleTagDestroyed(tagId, destroyedStatus) {
      const tag = this.tags.find(tag => tag.tagId === tagId);
      tag.destroyed = destroyedStatus;
      this.removeResourceFromMap([tag], "tags");
    },

    handleUpdateTag(tagsSent, tagsDeleted) {
      if (tagsSent.length) {
        this.removeResourceFromMap(
          this.tags.filter(tag => tagsSent.includes(tag.tagId)),
          "tags"
        );
      } else if (tagsDeleted.length) {
        this.addResourcesToMap(
          this.tags.filter(tag => tagsDeleted.includes(tag.tagId)),
          "tag"
        );
      }
    },

    async saveTagLabel(label) {
      try {
        const tagToMove = this.tags.find(
          tag => tag.id === this.moveTagDialog.tagToMoveId
        );
        const result = await tagsApi.updateTag(tagToMove.tagId, {
          label: label,
          childBeacons: [],
          beaconsToRemove: []
        });

        if (result.data) {
          this.removeResourceFromMap(
            this.tags.filter(tag => this.tagsToSend.includes(tag.tagId)),
            "tags"
          );
          this.addResourcesToMap(
            this.tags.filter(tag => this.tagsToDelete.includes(tag.tagId)),
            "tag"
          );
        }
      } catch (err) {
        this.$notify.error({
          title: this.$t("statusMessages.error"),
          message: this.$t("messages.genericError")
        });
      }
      // if (!destroy)
      //   this.$refs.tagForm.validate(async valid => {
      //     if (valid) {
      // this.editTagDialog.loading = true;
      // let threshold = 0;

      // if (this.isClientDefault) {
      //   threshold = -this.tagForm.threshold - 16;
      // } else {
      //   if (this.tagForm.threshold == 0) threshold = -16;
      //   else threshold = -32 + (16 - this.tagForm.threshold);
      // }

      // try {
      //   // const tag = this.tags.find(
      //   //   tag => tag.tagId === this.highlightedBeacon.tagId
      //   // );
      //   if (this.tagToReplace != null) this.replaceTag();

      //   const payload = {
      //     label: this.tagForm.label,
      //     active: this.tagForm.active,
      //     threshold: threshold,
      //     childBeacons: this.tagsToSend,
      //     beaconsToRemove: this.tagsToDelete
      //   };

      //   if (this.isTracker) {
      //     payload.status = this.tagForm.status;
      //   }

      //   const result = await tagsApi.updateTag(
      //     this.tagToEdit.tagId,
      //     payload
      //   );

      //   const _self = this; // eslint-disable-line

      //   _self.tags.forEach(elem => {
      //     if (elem.id === _self.tagToEdit.id) {
      //       elem = result;
      //       elem.childTags = _self.tagToEdit.childTags;
      //     }
      //   });

      //   this.tagForm = {
      //     tagId: 0,
      //     label: "",
      //     active: false,
      //     threshold: "",
      //     battery: -1,
      //     lastSeen: -1,
      //     error: ""
      //   };
      //   this.removeResourceFromMap(
      //     this.tags.filter(tag => this.tagsToSend.includes(tag.tagId)),
      //     "tags"
      //   );
      //   this.addResourcesToMap(
      //     this.tags.filter(tag => this.tagsToDelete.includes(tag.tagId)),
      //     "tag"
      //   );
      //   this.tagsToSend = [];
      //   this.tagsSelected = [];
      //   this.tagsToDelete = [];
      //   this.editTagDialog.visible = false;
      //   this.$forceUpdate();
      // } catch (err) {
      //   this.$notify.error({
      //     title: this.$t("statusMessages.error"),
      //     message: this.$t("messages.genericError")
      //   });
      // } finally {
      //   this.editTagDialog.loading = false;
      // }
      // }
      //   });
      // else {
      //   try {
      //     const tag = this.tags.find(
      //       tag => tag.tagId === this.resourceInfo.details.tagId
      //     );
      //     const res = await tagsApi.updateTag(this.resourceInfo.details.tagId, {
      //       destroyed: true
      //     });
      //     tag.destroyed = res.data.destroyed;
      //     this.removeResourceFromMap([tag], "tags");
      //     this.$notify({
      //       title: this.$t("statusMessages.success"),
      //       message: "Beacon deleted",
      //       type: "success"
      //     });
      //   } catch (err) {
      //     this.$notify.error({
      //       title: this.$t("statusMessages.error"),
      //       message: this.$t("messages.genericError")
      //     });
      //   } finally {
      //     console.log("deleted");
      //   }
      // }
    },

    // onAnchorCLick(e) {
    //   // LEGACY_CODE
    //   // if (
    //   //   this.resourceInfo.type === "anchor" &&
    //   //   this.resourceInfo.details.anchorId === e.layer.options.anchorId
    //   // ) {
    //   //   this.resourceInfo.type = "";
    //   //   this.deEmphasizeResource();
    //   //   this.resourceInfo.details = {};
    //   //   if (this.nextPosition) {
    //   //     this.map.removeLayer(this.nextPosition);
    //   //   }
    //   // } else {
    //   //   this.resourceInfo.type = "anchor";
    //   //   this.resourceInfo.details = this.anchors.find(
    //   //     anchor => anchor.anchorId === e.layer.options.anchorId
    //   //   );
    //   //   this.createNextPosition(this.resourceInfo.details);
    //   //   this.highlightResource();
    //   // }
    // },

    async onTagClick(e) {
      const _self = this; // eslint-disable-line
      L.DomEvent.stopPropagation(e);
      if (this.resourceInfo.details == null) return;

      // When you click on a highlighted tag
      if (this.highlightedBeacon != null) {
        if (this.highlightedBeacon.tagId === e.layer.options.tagId) {
          // Deselect Tag
          this.highlightedBeacon.mapRef?.closePopup();
          this.highlightedBeacon = null;
          this.deEmphasizeResource();

          // For the Routes Page
          this.$emit("tag-clicked", this.highlightedBeacon);

          // Highlight back visible anchor from the sidebar
          if (this.visibleAnchor >= 0) {
            this.resourceInfo.type = "anchor";
            this.resourceInfo.details = await this.updateAnchor(
              this.visibleAnchor
            );
            this.createNextPosition(this.resourceInfo.details);
            this.highlightResource();
          }
        } else {
          this.highlightedBeacon = this.tags.find(
            tag => tag.tagId === e.layer.options.tagId
          );

          this.highlightResource();
          this.$emit("tag-clicked", this.highlightedBeacon);
          this.resourceInfo.details = this.selectedAnchor;
        }
      } else {
        this.map.on("click", function() {
          _self.search = "";
          if (_self.nextPosition) {
            _self.map.removeLayer(_self.nextPosition);
          }
          _self.deEmphasizeResource();
          _self.highlightedBeacon.mapRef?.closePopup();
          _self.highlightedBeacon = null;
          _self.resourceInfo.type = "";

          if (_self.visibleAnchor >= 0) {
            _self.resourceInfo.type = "anchor";
            _self.resourceInfo.details = _self.anchors.find(
              anchor => anchor.anchorId === _self.visibleAnchor
            );

            _self.createNextPosition(_self.resourceInfo.details);
            _self.map.removeEventListener("click");
          }
          _self.$emit("map-clicked");
        });
        this.currentRoute = this.resourceInfo.details;
        // If anchor is highlighted, let's remove it
        if (this.resourceInfo.type === "anchor") {
          this.resourceInfo.type = "";
          this.deEmphasizeResource();
          this.resourceInfo.details = {};
        }
        //this.resourceInfo.type = "tag";
        this.highlightedBeacon = this.tags.find(
          tag => tag.tagId === e.layer.options.tagId
        );

        this.highlightResource();
        this.$emit("tag-clicked", this.highlightedBeacon);
        this.resourceInfo.details = this.selectedAnchor;
      }
    },

    closeEditTagDialog() {
      this.editTagDialog.visible = false;
    },

    closeMoveTagDialog() {
      this.moveTagDialog = {
        visible: false,
        tagToMoveId: null,
        nextPosition: {
          x: -1,
          y: -1
        }
      };
    },

    closegatewaysAffectedDialog() {
      this.gatewaysAffectedDialog = false;
    },

    async submitGatewaysAffectedDialog() {
      try {
        await positionLogsApi.createTags(this.tagsToUpdate);
        this.tagsToUpdate.forEach(tagToUpdate => {
          this.tags.forEach(tag => {
            tag.lastPosition.x = tagToUpdate.x;
            tag.lastPosition.y = tagToUpdate.y;
          });
        });
      } catch (err) {
        this.$notify.error({
          title: this.$t("statusMessages.error"),
          message: this.$t("messages.genericError")
        });
      } finally {
        this.gatewaysAffectedDialog = false;
        this.tagsToUpdate = [];
        this.closeEditMode(false);
        this.tagsToShow = [];
      }
    },

    async moveTagToPosition() {
      this.moveTagDialog.visible = false;
      const tagToMove = this.tags.find(
        tag => tag.id === this.moveTagDialog.tagToMoveId
      );

      if (tagToMove) {
        tagToMove.lastPosition = this.moveTagDialog.nextPosition;
        let hasMapRef = false;

        if (tagToMove.mapRef) {
          tagToMove.mapRef.setLatLng([
            this.moveTagDialog.nextPosition.y,
            this.moveTagDialog.nextPosition.x
          ]);
          tagToMove.mapRef.setStyle({ fillColor: "green" });
          hasMapRef = true;

          // Remove old drag listener to avoid multiple event bindings
          tagToMove.mapRef.off("dragend");
        } else {
          tagToMove.lastPosition.x = this.moveTagDialog.nextPosition.x;
          tagToMove.lastPosition.y = this.moveTagDialog.nextPosition.y;
          tagToMove.active = true;
          tagToMove.mapRef = this.createMapTag(tagToMove, true);

          // Attach drag listener only to newly created map reference
          if (tagToMove.mapRef) {
            this.setDragListenerOnTag(tagToMove.mapRef);
          }
        }

        const tagToUpdate = {
          tagId: this.moveTagDialog.tagToMoveId,
          x: this.moveTagDialog.nextPosition.x,
          y: this.moveTagDialog.nextPosition.y
        };

        const findIndex = this.tagsToUpdate.findIndex(
          _tag => _tag.id === tagToUpdate.tagId
        );

        if (findIndex >= 0) {
          this.tagsToUpdate[findIndex] = tagToUpdate;
        } else {
          this.tagsToUpdate.push(tagToUpdate);
          const index = this.tags.findIndex(
            _tag => _tag.id === tagToUpdate.tagId
          );
          if (hasMapRef) this.$emit("tag-dragged", this.tags[index], "MOVED");
          else this.$emit("tag-dragged", this.tags[index], "ADDED");
          this.tagsEdited.push(this.tags[index]);
        }
      }
      if (this.activeChecked != "all") {
        this.tagsToShow = this.tags.filter(
          tag =>
            tag.active === this.activeChecked && !tag.destroyed && tag.type == 0
        );
      } else {
        this.tagsToShow = this.tags.filter(tag => tag.type == 0);
      }

      //EDIT TAG LABEL
      try {
        await tagsApi.updateTag(tagToMove.tagId, {
          label: this.moveBeaconForm.label,
          childBeacons: [],
          beaconsToRemove: []
        });
        const tagEdited = this.tags.find(tag => tag.id === tagToMove.id);
        tagEdited.label = this.moveBeaconForm.label;
      } catch (error) {
        this.$notify.error({
          title: this.$t("statusMessages.error"),
          message: this.$t("messages.genericError")
        });
      } finally {
        this.moveBeaconForm.label = "";
      }
    },

    handleMapProperties() {
      const _self = this; // eslint-disable-line
      this.map.on("zoomend", function() {
        localStorage.setItem("map-zoom", _self.map.getZoom());
        localStorage.setItem(
          "map-center",
          JSON.stringify(_self.map.getCenter())
        );
      });
      this.map.on("moveend", function() {
        localStorage.setItem(
          "map-center",
          JSON.stringify(_self.map.getCenter())
        );
      });
    },

    highlightResource() {
      if (!this.isEditing) {
        this.deEmphasizeResource();
        const mapRef = this.highlightedBeacon.mapRef;

        this.highlightResourceOriginalData = {
          mapRef,
          radius: mapRef?.getRadius()
        };

        mapRef?.setRadius(mapRef.getRadius() + 18);
      }
    },

    deEmphasizeResource() {
      const mapRef = this.highlightResourceOriginalData.mapRef;

      if (mapRef) {
        mapRef.setRadius(this.highlightResourceOriginalData.radius);
      }

      this.highlightResourceOriginalData = {};
    },

    isAnchorPowerSourceDisconnected(heartbeat) {
      if (heartbeat) if (!heartbeat.powerSource) return true;
      return false;
    },

    isAnchorOffline(powerSource, offlineTs) {
      if (!offlineTs) return false;
      else {
        const sixMinutesAgo = moment()
          .utc()
          .subtract(6, "minutes");

        if (!powerSource) {
          if (moment.utc(moment.unix(offlineTs)).isBefore(sixMinutesAgo)) {
            return true;
          }
          return false;
        } else {
          const oneMinuteAgo = moment()
            .utc()
            .subtract(60, "seconds");

          if (moment.utc(moment.unix(offlineTs)).isBefore(oneMinuteAgo)) {
            return true;
          }
        }
      }
      return false;
    },

    findTagOnLastAnchorPosition(lastTagPositionId) {
      if (lastTagPositionId) {
        const tag = this.tags.find(
          _tag =>
            _tag.lastPosition && _tag.lastPosition.id === lastTagPositionId
        );
        if (tag)
          if (tag.label) return tag.label;
          else
            return tag.tagId
              .toString(16)
              .toUpperCase()
              .match(/.{1,2}/g)
              .join("");
      }
    },

    async updateAnchor(anchorId) {
      try {
        const { data } = await AnchorsApi.getAnchor(anchorId);
        return data;
      } catch (_) {
        // DO nothing
      }
    },

    onDashboardSlotOpenClick() {
      this.dashboardSlotOpen = !this.dashboardSlotOpen;
      if (this.$route.name === "stats-globals") {
        this.$emit("update-show-stats", this.dashboardSlotOpen);
      }
    },

    showTagLabelOnMap(tagData) {
      const tagId = tagData.tagId;

      const circleTag = this.circleTags[tagId];

      if (circleTag) {
        circleTag.openPopup();

        setTimeout(() => {
          circleTag.closePopup();
        }, 5000);
      }
    },

    getRandomInt(max) {
      return Math.floor(Math.random() * max);
    },

    getRandomColor() {
      let randomColor = "#";
      const letters = "0123456789ABCDEF";
      for (let i = 0; i < 6; i++) {
        randomColor += letters[this.getRandomInt(16)];
      }
      const colorIsSelected = this.selectedColors.find(
        color => color === randomColor
      );
      if (colorIsSelected || randomColor === "#FFFFFF") {
        this.getRandomColor();
      } else {
        this.selectedColors.push(randomColor);
        return randomColor;
      }
    },

    updateSelectedAnchors() {
      if (this.selectedAnchorIds?.length) {
        this.selectedAnchors = this.anchors.filter(anchor =>
          this.selectedAnchorIds.includes(anchor.anchorId)
        );
      } else {
        this.selectedAnchors = [];
      }
    },

    updateWindowWidth() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth > 1740 && this.isStatsPage) {
        this.dashboardSlotOpen = true;
        this.$emit("update-show-tabs", true);
      }
      if (this.windowWidth > 1740 && this.$route.name === "stats-globals") {
        this.dashboardSlotOpen = true;
        this.$emit("update-show-stats", true);
      }
    }
  }
};
</script>

<style lang="scss">
.dashboard {
  position: relative;
  padding-top: 44px;
  overflow: hidden;

  @media (max-width: $md) {
    padding-top: 78px;
  }

  .no-layout-message {
    display: flex;
    justify-content: center;

    h4 {
      margin: 20px;
      padding: 24px;
      background: #ffffff;
      border: 1px solid #dcdfe6;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12), 0 0 6px 0 rgba(0, 0, 0, 0.04);
      max-width: 550px;
    }
  }

  // .background-animation {
  //   -webkit-animation-name: animation;
  //   -webkit-animation-duration: 4s;
  //   -webkit-animation-timing-function: ease-in-out;
  //   -webkit-animation-iteration-count: infinite;
  //   -webkit-animation-play-state: running;

  //   animation-name: animation;
  //   animation-duration: 4s;
  //   animation-timing-function: ease-in-out;
  //   animation-iteration-count: infinite;
  //   animation-play-state: running;
  //   background-color: white;
  //   width: 83%;
  // }

  @-webkit-keyframes animation {
    0% {
      background-color: white;
    }
    50.0% {
      background-color: #8bec8b;
    }
    100.0% {
      background-color: white;
    }
  }

  @keyframes animation {
    0% {
      background-color: white;
    }
    50.0% {
      background-color: #8bec8b;
    }
    100.0% {
      background-color: white;
    }
  }

  .search-input {
    height: 15px;

    @media (max-width: $md) {
      height: auto;
    }
  }

  .edit-beacon-values {
    display: grid;
    grid-template-columns: 33% 34% 33%;
    margin-bottom: 2%;
  }

  .edited-tags {
    margin-top: 20px;

    &__element {
      margin-top: 3px;
    }
  }

  .parent-info {
    justify-content: left;
    display: flex;
    padding-left: 3px;
  }

  .edit-instructions {
    li {
      text-align: justify;
      padding-left: 40px;
      list-style: disc;
      padding-right: 40px;
      line-height: 1.3;
    }
  }

  .edit-beacon-move {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .edit-beacon-form {
    display: grid;
    grid-template-columns: 50% 50%;
    border-top: 1px solid black;
    padding-top: 3%;
    margin-top: 20px;

    .el-form-item {
      margin-bottom: 15px;
    }

    .edit-beacon-range-level {
      .el-form-item__label {
        padding-top: 10px;
      }
    }

    .edit-beacon-replace {
      margin-top: 13%;
      display: flex;
      align-items: center;

      .select-tags {
        width: 95%;
        margin-left: 32px;
      }
    }

    .edit-beacon-status-range {
      margin-top: 20px;
      display: flex;
      align-items: center;

      .select-status {
        width: 95%;
        margin-left: 32px;
      }
    }

    .edit-beacon-select-tags {
      padding-left: 22px;
      width: 115%;
    }

    .add-tag-btn {
      padding-left: 67px;
    }

    .edit-beacon-child-tags {
      display: grid;
      grid-template-columns: 32% 68%;
      margin-bottom: 3%;
    }

    .edit-beacon-children {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .edit-beacon-label {
      display: flex;
      justify-content: left;
      align-items: center;

      .el-input {
        margin-left: 12%;
      }
    }

    .edit-beacon-status {
      display: flex;
      justify-content: left;
      align-items: center;

      .el-checkbox {
        margin-left: 45%;
      }
    }

    .el-slider {
      position: absolute;
      left: 147px;
      width: 43%;

      &__marks-text {
        width: 100px;
        color: black;
      }
    }
  }

  .anchor-list {
    display: flex;
    position: relative;

    .label-div {
      width: 83%;
    }

    &__id {
      float: left;
      margin-bottom: 3%;
      padding-top: 6px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
      display: flex;
      justify-content: left;
    }

    &__delete {
      float: right;
      position: absolute;
      right: 10px;
    }
  }

  .upload-first-image {
    margin-top: 20%;

    .file-upload {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 2%;
      padding-right: 4.5%;

      input {
        display: flex;
        text-align: center;
        justify-content: center;
        margin-left: 6%;
      }
    }
    .upload-button {
      margin-top: 2%;
    }
  }

  .select-route-list {
    margin-top: 4%;
  }

  .checkbox-active {
    margin-top: 6%;
  }

  .move-beacon {
    width: 420px;

    @media (max-width: $sm) {
      width: 350px;
    }
  }

  .edit-beacons-button {
    position: absolute;
    top: 245px;
    left: 14px;
    z-index: 30;
    font-size: 20px;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
    border-radius: 4px;
    background: white;
    padding: 4px 4px 0 4px;
    cursor: pointer;
  }

  .save-beacons-button {
    position: absolute;
    bottom: 30px;
    right: 15px;
    z-index: 30;
  }

  .discard-beacons-button {
    position: absolute;
    bottom: 30px;
    right: 90px;
    z-index: 30;
  }

  .toggle-dashboard-slot--close {
    position: fixed;
    top: 113px;
    right: 0px;
    padding: 8px 12px;
    font-size: 18px;
    border-radius: 8px 0px 0px 8px;
    cursor: pointer;
    font-weight: bold;
    z-index: 1000;

    @media (max-width: $md) {
      top: 148px;
    }
    @media (max-width: 470px) {
      top: 187px;
    }
  }

  .toggle-dashboard-slot {
    position: fixed;
    top: 110px;
    right: 0px;
    padding: 8px 12px;
    font-size: 14px;
    border-radius: 8px 0px 0px 8px;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65) !important;
    cursor: pointer;
    font-weight: bold;
    z-index: 1000;

    @media (max-width: $md) {
      top: 148px;
    }
    @media (max-width: 500px) {
      top: 76px;

      &__bg-white {
        background-color: #ffffff;
      }
    }

    i {
      position: relative;
      top: 1px;
      font-weight: bold;
    }
  }

  .button-select-route {
    margin-top: 5%;
  }

  .select-route-intro {
    word-wrap: break-word;
  }

  .radio-div {
    margin: 5% 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5%;
  }

  .radio-label {
    display: flex;
    gap: 4px;
    align-items: center;
    justify-content: center;
  }

  .radio-input {
    display: inline-block;
  }

  .select-tags {
    margin-top: 3%;
  }

  &__route-options-bar {
    display: grid;
    grid-template-columns: 50% 15% 20% 15%;
    position: fixed;
    top: 60px;
    width: calc(100% - 64px);
    padding: 4px 52px 4px 32px;
    height: 36px;
    background: $--border-color-base;
    text-align: right;
    color: $--color-primary;
    z-index: 500;

    .show-beacons {
      position: absolute;
      right: 300px;
      top: 4px;
    }

    .search-span {
      position: absolute;
      right: 30px;
      top: 4px;
    }

    .header-separator {
      margin-left: 10px;
    }

    .header-element {
      display: flex;
      justify-content: left;
      align-items: center;
      margin-right: 10px;

      .selected-anchor-label {
        max-width: 10vw;
      }

      .selected-route-label {
        max-width: 10vw;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .route {
        margin-right: 5px;
      }
    }
  }

  .margin-left {
    margin-left: 5px;
  }

  &__options-bar {
    display: grid;
    grid-template-columns: 50% 25% 25%;
    position: fixed;
    top: 60px;
    width: calc(100% - 64px);
    padding: 4px 52px 4px 32px;
    height: 36px;
    background: $--border-color-base;
    text-align: right;
    color: $--color-primary;
    z-index: 500;
    font-size: 14px;

    @media (max-width: 1700px) {
      padding: 6px 52px 10px 32px;
      height: auto;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      align-items: center;
      gap: 5px;

      > :nth-child(2) {
        order: 3;
      }
      > :nth-child(3) {
        order: 2;
      }
    }

    &__resource--rssi {
      margin-left: 5px;
    }

    .header-separator {
      margin-left: 10px;

      @media (max-width: $md) {
        display: none;
      }
    }

    .header-element {
      display: flex;
      justify-content: left;
      align-items: center;
      margin-right: 10px;

      .selected-anchor-label {
        max-width: 10vw;

        @media (max-width: $lg) {
          max-width: 20vw;
        }
      }

      .route {
        margin-right: 5px;
      }

      .gateway {
        display: flex;
        align-items: center;

        .gateway-label {
          margin-right: 5px;
        }

        .gateway-selector {
          .el-select {
            width: 150px;
          }
        }
      }

      .route {
        max-width: 200px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      .route-time {
        margin-right: 5px;
        white-space: nowrap;

        .total-hours {
          max-width: 6vw;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          display: inline-block;
          padding-top: 4px;
        }

        &__no-time {
          white-space: nowrap;

          @media (max-width: $md) {
            display: none;
          }
        }

        &__no-time-small {
          display: none;

          @media (max-width: $md) {
            display: block;
          }
        }
      }
    }

    &__left-div {
      display: flex;
      max-height: 36px;

      @media (max-width: $md) {
        flex-direction: column;
        max-height: 62px;
      }
    }

    p {
      float: left;
      line-height: 36px;
      font-size: 13px;
      color: $--color-primary;
    }

    .el-icon-edit-close {
      cursor: pointer;
    }

    .el-button {
      margin-top: 4px;
    }

    .options-bar {
      &__resource {
        display: inline-block;
        float: none;
        margin-right: 6px;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        position: relative;
        top: 4px;

        &--anchor {
          border: 3px solid rgb(50, 205, 50);
          background-color: rgba(50, 205, 50, 0.5);
        }

        &--tag {
          border: 3px solid $--color-primary;
          background-color: rgba(31, 66, 88, 0.5);
        }

        &--rssi {
          margin-right: 30px;
        }

        &--next-position {
          border: 3px solid rgb(255, 184, 51);
          background-color: rgba(255, 184, 51, 0.5);
          float: none;
          margin: 0;
          padding: 0;
          display: inline-block;
          position: relative;
          top: 4px;
        }
      }

      &__edit {
        cursor: pointer;
        margin-right: 5px;
        margin-left: 5px;
      }

      &__highlighted-beacon {
        display: flex;
        justify-content: right;
        align-items: center;

        &__label {
          margin-left: 5px;
          max-width: 5vw;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          @media (max-width: $lg) {
            max-width: 32vw;
          }
        }
        &__tag_id {
          margin-left: 5px;
        }
      }

      &__trash-span {
        cursor: pointer;
        color: red;
      }
    }

    .rssi__offline {
      color: $--color-danger;
      font-weight: bold;
    }

    .wifi {
      &__no-connection {
        top: 5px;
        left: 2px;
        width: 24px;
        height: 3px;
      }

      svg {
        position: absolute;
        top: -22px;
        left: -1px;
        width: 30px;
      }
    }

    .battery {
      position: relative;
      top: 4px;
      left: -1px;
      width: 30px;
    }
  }

  &__map.leaflet-container {
    background-color: $--border-color-extra-light;
    z-index: 10;
    width: calc(100% - 8px); // 4px border
    height: calc(100vh - 112px); // header + options bar + 4px border
    border: 4px solid $--border-color-extra-light;
  }

  &--editing .dashboard__map.leaflet-container {
    position: relative;
    border: 4px solid $--color-warning;

    // &::after {
    //   content: "Editing";
    //   text-transform: uppercase;
    //   position: absolute;
    //   bottom: 0;
    //   left: 0;
    //   padding: 8px;
    //   border-bottom-left-radius: 8px;
    //   background-color: $--color-warning;
    //   font-weight: bold;
    //   z-index: 5000;
    // }
  }

  &__map-wrapper {
    position: relative;
  }

  .map-select {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 1000;
  }

  .map-select-slot {
    position: absolute;
    top: 50px;
    right: 5px;
    z-index: 1000;

    @media (max-width: $sm) {
      top: 67px;
    }
  }

  &__checkbox-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    gap: 5px;
    position: absolute;
    top: 15px;
    right: 10px;
    z-index: 30;

    @media (max-width: 500px) {
      top: 30px;
      right: 30px;
    }

    .checkbox-container-left {
      display: flex;
      flex-direction: column;
      gap: 5px;

      .checkbox-item {
        background-color: #ffffff;

        @media (max-width: 500px) {
          width: 250px;
        }
      }
    }
    .checkbox-container-right {
      display: flex;
      flex-direction: column;
      gap: 5px;

      .checkbox-item {
        background-color: #ffffff;

        @media (max-width: 500px) {
          width: 250px;
        }
      }
    }
  }

  // &__checkbox {
  //   position: absolute;
  //   top: 15px;
  //   right: 240px;
  //   z-index: 30;
  //   background: white;

  //   @media (max-width: 500px) {
  //     top: 142px;
  //     right: 30px;
  //   }

  //   &__model {
  //     width: 220px;
  //     text-align: left;
  //   }
  // }

  // &__checkbox-two {
  //   position: absolute;
  //   top: 60px;
  //   right: 240px;
  //   z-index: 30;
  //   background: white;

  //   @media (max-width: 500px) {
  //     top: 100px;
  //     right: 30px;
  //   }

  //   &__model {
  //     width: 220px;
  //     display: flex;
  //     text-align: left;
  //   }
  // }

  // &__checkbox-three {
  //   position: absolute;
  //   top: 15px;
  //   right: 0;
  //   z-index: 30;
  //   background: white;

  //   @media (max-width: 500px) {
  //     right: 30px;
  //     top: 20px;
  //   }

  //   &__model {
  //     width: 220px;
  //     display: flex;
  //     text-align: left;
  //   }
  // }

  // &__checkbox-four {
  //   position: absolute;
  //   top: 60px;
  //   right: 0;
  //   z-index: 30;
  //   background: white;

  //   @media (max-width: 500px) {
  //     right: 30px;
  //   }

  //   &__model {
  //     width: 220px;
  //     display: flex;
  //     text-align: left;
  //   }
  // }

  &__slider {
    position: absolute;
    top: 120px;
    right: 0;
    z-index: 30;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
    border-radius: 4px;
    background: white;
    padding: 15px 0;

    .el-slider {
      height: 100px;
    }

    .el-slider__runway {
      margin: 0 10px !important;
    }
  }

  &__tag-slider {
    position: absolute;
    top: 138px;
    left: 14px;
    z-index: 30;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
    border-radius: 4px;
    background: white;
    padding: 15px 0;

    @media (max-width: 470px) {
      top: 178px;
    }

    .el-slider {
      height: 100px;
    }

    .el-slider__runway {
      margin: 0 10px !important;
    }
  }

  &__tag-slider-no-anchor {
    position: absolute;
    top: 184px;
    left: 14px;
    z-index: 30;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
    border-radius: 4px;
    background: white;
    padding: 15px 0;

    @media (max-width: $md) {
      top: 200px;
    }
    @media (max-width: 470px) {
      top: 280px;
    }

    .el-slider {
      height: 100px;
    }

    .el-slider__runway {
      margin: 0 10px !important;
    }
  }

  &__tag-slider-settings {
    position: absolute;
    top: 138px;
    left: 14px;
    z-index: 30;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
    border-radius: 4px;
    background: white;
    padding: 15px 0;

    @media (max-width: $sm) {
      top: 135px;
    }

    .el-slider {
      height: 100px;
    }

    .el-slider__runway {
      margin: 0 10px !important;
    }
  }

  &__fit-to-bounds {
    position: absolute;
    top: 78px;
    left: 13px;
    z-index: 30;
    font-size: 20px;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
    border-radius: 4px;
    background: white;
    padding: 4px 4px 0 4px;
    cursor: pointer;

    @media (max-width: $sm) {
      top: 118px;
    }
  }

  &__fit-to-bounds-no-anchor {
    position: absolute;
    top: 123px;
    left: 13px;
    z-index: 30;
    font-size: 20px;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
    border-radius: 4px;
    background: white;
    padding: 4px 4px 0 4px;
    cursor: pointer;

    @media (max-width: $md) {
      top: 163px;
    }
    @media (max-width: 470px) {
      top: 243px;
    }
  }

  &__fit-to-bounds-settings {
    position: absolute;
    top: 78px;
    left: 13px;
    z-index: 30;
    font-size: 20px;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
    border-radius: 4px;
    background: white;
    padding: 4px 4px 0 4px;
    cursor: pointer;

    @media (max-width: $sm) {
      top: 100px;
    }
  }

  &__close-edit {
    position: fixed;
    top: 115px;
    right: 490px;
    padding: 8px 12px;
    font-size: 14px;
    border-radius: 8px;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65) !important;
    cursor: pointer;
    font-weight: bold;
    z-index: 1000;

    &__sidebar {
      position: fixed;
      top: 115px;
      right: 5px;
      padding: 8px 12px;
      font-size: 14px;
      border-radius: 8px;
      box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65) !important;
      cursor: pointer;
      font-weight: bold;
      z-index: 1000;
    }

    i {
      font-weight: bold;
    }
  }

  &__anchors-list {
    padding: 12px 24px;
    text-align: left;

    p {
      text-align: left;
      font-size: 16px;
      margin-bottom: 14px;
    }

    .el-radio {
      display: block;
      text-align: left;
      margin-bottom: 12px;

      &__label {
        font-size: 16px;
      }
    }
  }

  .leaflet-control-zoom.leaflet-bar.leaflet-control {
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65) !important;
    border: none;

    @media (max-width: 470px) {
      top: 80px;
    }

    a {
      width: 26px !important;
    }
  }

  .leaflet-tooltip__numbered {
    background-color: transparent;
    border: none;
    box-shadow: none;
    font-weight: bold;
    font-size: 14px;
    color: #fff;
  }

  .leaflet-tooltip__numbered_color {
    background-color: transparent;
    border: none;
    box-shadow: none;
    font-weight: bold;
    font-size: 16px;
    color: #ffcd70;
  }

  &--has-slot {
    .dashboard__map-wrapper {
      height: calc(100vh - 104px);
      width: calc(100% - 480px);
      &__tracker {
        width: 30vw;
      }
      float: left;
      border-right: 1px solid $--border-color-base;
    }

    // .dashboard__map-wrapper-routes {
    //   height: calc(100vh - 104px);
    //   width: calc(100% - 638px) !important;
    //   &__tracker {
    //     width: calc(100% - 751px);
    //   }
    //   float: left;
    //   border-right: 1px solid $--border-color-base;
    // }

    .dashboard__map-wrapper-no-anchors {
      height: calc(100vh - 104px);
      width: calc(100% - 480px);
      &__tracker {
        width: 30vw;
      }
      float: left;
    }

    .dashboard__slot {
      position: relative;
      padding-top: 24px;
      width: 480px;
      height: calc(100vh - 164px);
      max-height: calc(100vh - 104px);
      float: left;
      overflow: auto;

      &__tracker {
        width: 70vw;

        @media (max-width: $sm) {
          width: 100%;
        }
      }
    }
  }

  .dashboard__slot__close {
    height: 0 !important;
  }

  .el-dialog__footer {
    @media (max-width: 500px) {
      padding: 20px 10px;
    }
  }

  .el-dialog-asset-history {
    margin: 3vh auto 0px !important;
    width: 1200px;

    @media (max-width: 1300px) {
      width: 98%;
    }
    @media (max-width: 500px) {
      width: 93%;
    }
  }

  .conditional-blue-text {
    color: #2986cc;
  }
}
.color-indicator-container {
  display: flex;
  align-items: center;
  height: 100%;
  float: left;
  margin-right: 10px;

  .color-indicator {
    border-radius: 100%;
    width: 12px;
    height: 12px;
  }
}
</style>
