<template>
  <el-tooltip
    v-if="isTruncated"
    :content="content"
    :placement="placement"
    :hide-after="hideAfter"
  >
    <span
      ref="textElement"
      class="truncated-text"
      :style="`max-width: ${maxWidth}`"
      @mouseenter="checkTruncation"
    >
      <slot></slot>
    </span>
  </el-tooltip>
  <span
    v-else
    ref="textElement"
    class="truncated-text"
    :style="`max-width: ${maxWidth}`"
    @mouseenter="checkTruncation"
  >
    <slot></slot>
  </span>
</template>

<script>
export default {
  name: "TruncatedTooltip",
  props: {
    content: {
      type: String,
      required: true
    },
    placement: {
      type: String,
      default: "top"
    },
    hideAfter: {
      type: Number,
      default: 2000
    },
    maxWidth: {
      type: String,
      default: "100%"
    }
  },
  data() {
    return {
      isTruncated: false
    };
  },
  methods: {
    checkTruncation() {
      const element = this.$refs.textElement;
      if (element) {
        this.isTruncated = element.scrollWidth > element.clientWidth;
      }
    }
  },
  mounted() {
    window.addEventListener("resize", this.checkTruncation);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.checkTruncation);
  }
};
</script>

<style lang="scss">
.truncated-text {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: middle;
}
</style>
