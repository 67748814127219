<template>
  <div class="fullscreen-wrapper">
    <div class="container">
      <h4 v-if="anchors.isEmpty">
        {{ $t("messages.errorGatewayRequired") }}
      </h4>
    </div>

    <template v-if="anchor">
      <div class="container fullscreen-wrapper__details">
        <div class="gateway-header">
          <div class="gateway-selector">
            <h3>{{ $tc("labels.vehicle", 0) }}</h3>
            <el-select
              v-model="selectedAnchorId"
              :placeholder="$tc('placeholder.selectVehicle', 1)"
              @change="updateSelectedAnchor"
            >
              <el-option
                v-for="anchor in resources.data.anchors"
                :key="anchor.anchorId"
                :label="anchor.label"
                :value="anchor.anchorId"
              >
              </el-option>
            </el-select>
          </div>
          <el-tooltip
            placement="right"
            :content="$t('tooltip.overallPredictions')"
            :hide-after="2000"
            v-if="resources.data.anchors.some(anchor => anchor.activeRoute)"
          >
            <el-button @click="redirectToGlobalPredictions" size="mini">
              <img src="@/assets/globe.svg" :alt="$t('alt.globe')" />
            </el-button>
          </el-tooltip>
        </div>
        <div v-if="anchor.activeRoute" class="fullscreen-wrapper__anchor">
          <b>{{ $t("labels.label") }}: </b> {{ anchor.label }} <b>ID: </b>
          {{
            anchor.anchorId
              .toString(16)
              .padStart(12, "0")
              .toUpperCase()
              .match(/.{1,2}/g)
              .join(":")
          }}
          <WifiIcon
            :signal="anchor.rssi"
            :noConnection="
              anchor.lastHeartbeat
                ? isAnchorConnected(anchor.lastHeartbeat.createdDate)
                : false
            "
          />
          <span
            v-if="
              anchor.lastHeartbeat
                ? isAnchorConnected(anchor.lastHeartbeat.createdDate)
                : false
            "
            class="anchor__offline"
          >
            {{ $t("statusMessages.offline") }}
          </span>
        </div>
        <div class="fullscreen-wrapper__options">
          <template v-if="anchor.activeRoute">
            <div class="fullscreen-wrapper__options--left">
              <p>{{ $t("labels.tableTopEntry") }}:</p>
              <el-checkbox
                v-model="basedOnGatewayPosition"
                @change="setData"
                border
                >{{ $t("labels.basedOnGatewayPosition") }}</el-checkbox
              >
              <el-select
                v-model="tagOnTopOrd"
                :placeholder="$t('placeholder.basedOnTag')"
              >
                <el-option
                  v-for="tag in selectData"
                  :key="`(${tag.ord}) ${tag.label || tag.tagId}`"
                  :label="`(${tag.ord}) ${tag.label || tag.tagId}`"
                  :value="tag.ord || ''"
                >
                </el-option>
              </el-select>
            </div>
            <div class="fullscreen-wrapper__options--right">
              <p>{{ $t("labels.numberOfTableElements") }}:</p>
              <el-input-number
                v-model="numberOfRows"
                @change="setTableData"
                :min="1"
                :max="Math.min(anchor.activeRoute.tags.length, 10)"
              ></el-input-number>
              <el-button type="primary" @click="toggle"
                ><i class="el-icon-full-screen" />
                {{ $t("actions.goFullscreen") }}</el-button
              >
            </div>
          </template>
          <h4 v-else>
            {{ $t("messages.errorGatewayRoutes.line1") }}
            <router-link
              :to="{
                name: 'user-settings',
                params: { tab: 'routes', anchorId: $route.params.anchorId }
              }"
              >{{ $t("messages.errorGatewayRoutes.line2") }}</router-link
            >
            {{ $t("messages.errorGatewayRoutes.line3") }}
          </h4>
        </div>
        <fullscreen
          v-if="anchor.activeRoute"
          class="fullscreen__on"
          ref="predictions-fullscreen-mode"
          @change="fullscreenChange"
        >
          <div
            v-if="predictions - fullscreen - mode"
            class="fullscreen-wrapper__anchor"
          >
            <b>{{ $t("labels.label") }}: </b> {{ anchor.label }} <b>ID: </b>
            {{
              anchor.anchorId
                .toString(16)
                .padStart(12, "0")
                .toUpperCase()
                .match(/.{1,2}/g)
                .join(":")
            }}

            <WifiIcon
              :signal="anchor.rssi"
              :noConnection="
                anchor.lastHeartbeat
                  ? isAnchorConnected(anchor.lastHeartbeat.createdDate)
                  : false
              "
            />
            <template v-if="anchor.activeRouteStart">
              | <b>{{ $t("labels.routeTime") }}: </b>
              <span
                v-bind:style="{
                  color: computedColor,
                  fontWeight: computedWeight
                }"
              >
                <span>
                  {{ getRouteTotalHours() }}{{ getRouteTotalMinutes() }}m:{{
                    getRouteTotalSeconds()
                  }}s
                </span>
                <span v-if="anchor.activeRouteOnTime">
                  ({{ getRouteDelay() }})
                </span>
              </span>
            </template>
          </div>
          <div class="fullscreen-wrapper__details__clock">
            <i class="el-icon-timer" />
            {{ moment.tz(this.clientData.timezone).format("HH:mm") }}
          </div>
          <div class="warning-message-wrapper" v-if="isNewRoute">
            <div class="warning-message">
              <i class="el-icon-warning"></i>
              <span>{{ $t("messages.predictionsNewRoutes") }}</span>
            </div>
          </div>
          <div
            v-if="predictions - fullscreen - mode"
            class="fullscreen__close"
            @click="toggle()"
          >
            <i class="el-icon-close" />
          </div>
          <el-table
            ref="predictions"
            highlight-current-row
            :class="
              `table__rows--${Math.min(
                Math.max(5, tableData.length),
                Math.max(numberOfRows, 5)
              )}`
            "
            :row-class-name="tableRowClassName"
            :data="tableData"
            border
            style="width: 100%"
          >
            <template slot="empty">
              {{ $t("labels.noData") }}
            </template>
            <el-table-column :resizable="false" label="ID">
              <template slot-scope="scope">
                {{
                  getTagInfo(scope.row.ord)
                    .tagId.toString(16)
                    .toUpperCase()
                    .match(/.{1,2}/g)
                    .join("")
                }}
              </template>
            </el-table-column>
            <el-table-column :resizable="false" :label="$t('labels.label')">
              <template slot-scope="scope">
                {{ getTagInfo(scope.row.ord).label }}
              </template>
            </el-table-column>
            <el-table-column
              :resizable="false"
              :label="$t('labels.arrivalTime')"
            >
              <template slot-scope="scope">
                {{
                  moment
                    .tz(moment.unix(scope.row.prediction), clientData.timezone)
                    .format("HH:mm")
                }}
              </template>
            </el-table-column>
            <el-table-column :resizable="false" :label="$t('labels.status')">
              <template slot-scope="scope">
                <span v-if="scope.row.state === 'LEFT'">
                  <i class="el-icon-check" />
                  {{ $t("statusMessages.left") }}
                </span>
                <span v-else-if="scope.row.state === 'CURRENTLY_HERE'">
                  <i class="el-icon-check" />
                  {{ $t("statusMessages.currentlyHere") }}
                </span>
                <span v-else-if="scope.row.state === 'ON_THE_WAY'">
                  <i class="el-icon-loading" />
                  {{ $t("statusMessages.onTheWay") }}
                </span>
                <span v-else-if="scope.row.state === 'WAITING'">
                  <i class="el-icon-timer" />
                  {{ $t("statusMessages.waiting") }}
                </span>
              </template>
            </el-table-column>
          </el-table>
        </fullscreen>
        <PaymentWarning
          :isVisible="clientData.warning == 1 && showPaymentWarning"
          @hide-payment-warning="showPaymentWarning = false"
        />
      </div>
    </template>
  </div>
</template>

<script>
import moment from "moment-timezone";
import { mapState, mapActions } from "vuex";
import * as actionTypes from "@/store/action-types";
import * as routerTypes from "@/router/router.types";
import AnchorsApi from "@/modules/anchors/api/anchors.api";
import WifiIcon from "@/components/icons/WifiIcon.vue";
import PaymentWarning from "@/components/global/PaymentWarning.vue";
import { isDateToday } from "@/utils/utils";

export default {
  components: {
    WifiIcon,
    PaymentWarning
  },

  computed: {
    ...mapState("clients", {
      anchors: "anchors",
      selectedAnchor: "selectedAnchor",
      clientData: "data"
    }),
    ...mapState("auth", {
      user: "user"
    }),
    ...mapState("user", {
      resources: "resources"
    }),
    computedColor() {
      if (this.anchor.activeRouteOnTime === null) return "#1F4258";
      else {
        if (this.anchor.activeRouteOnTime[0] === 0) return "#1F4258";
        else if (this.anchor.activeRouteOnTime[0] === -1) return "green";
        else return "red";
      }
    },
    computedWeight() {
      if (this.anchor.activeRouteOnTime === null) return "normal";
      else {
        return this.anchor.activeRouteOnTime[0] === 0 ? "normal" : "bold";
      }
    },
    isNewRoute() {
      const routeCreatedDate = this.anchor.activeRoute.createdDate;
      return isDateToday(routeCreatedDate, this.clientData);
    }
  },
  data() {
    return {
      moment,
      currentTime: "##:##",
      currentTimeInterval: null,
      fullscreen: false,
      anchor: null,
      tableData: [],
      showPaymentWarning: true,
      selectData: [],
      prediction: [],
      numberOfRows: 5,
      predictionAvgs: [],
      basedOnGatewayPosition: true,
      tagOnTopOrd: "",
      interval: null,
      anchorInterval: null,
      predictionTableInterval: null,
      highligtedRow: false,
      currentRouteIndex: 0,
      emptyData: false,
      selectedAnchorId: ""
    };
  },

  watch: {
    selectedAnchor: {
      deep: true,
      handler() {
        if (this.selectedAnchor) {
          this.$router.push({
            name: "predictions-fullscreen-mode",
            params: {
              anchorId: this.selectedAnchor.anchorId
            }
          });
          this.anchor = this.selectedAnchor;
        }
      }
    },
    anchor(newValue) {
      if (
        newValue.activeRoute &&
        newValue.activeRoute.id != this.currentRouteIndex
      ) {
        this.getAnchorPrediction();
        this.currentRouteIndex = newValue.activeRoute.id;
      }
    },
    tagOnTopOrd() {
      this.basedOnGatewayPosition = false;
      this.setData();
    }
  },
  methods: {
    ...mapActions("clients", {
      updateSelectedAnchor: actionTypes.CLIENTS_UPDATE_SELECTED_ANCHOR
    }),
    ...mapActions("clients", {
      getAnchors: actionTypes.CLIENTS_GET_ANCHORS
    }),
    ...mapActions("user", {
      getResources: actionTypes.USER_GET_RESOURCES
    }),
    isDateToday,
    toggle() {
      this.$refs["predictions-fullscreen-mode"].toggle();
    },

    fullscreenChange(fullscreen) {
      this.fullscreen = fullscreen;
    },

    setCurrent(row) {
      if (this.$refs.predictions) {
        this.$refs.predictions.setCurrentRow(row);
      }
    },

    getRouteTotalHours() {
      const currentTime = moment().utc();
      const routeStart = moment.unix(this.anchor.activeRouteStart);
      if (Math.floor(currentTime.diff(routeStart, "seconds") / 3600) >= 1)
        return (
          Math.floor(
            currentTime.diff(routeStart, "seconds") / 3600
          ).toString() + "h:"
        );
      else return null;
    },

    getRouteTotalMinutes() {
      return Math.floor(
        (moment()
          .utc()
          .diff(moment.unix(this.anchor.activeRouteStart), "seconds") %
          3600) /
          60
      );
    },

    getRouteTotalSeconds() {
      return Math.floor(
        (moment()
          .utc()
          .diff(moment.unix(this.anchor.activeRouteStart), "seconds") %
          3600) %
          60
      );
    },

    getRouteDelay() {
      let hours;
      let minutes;
      if (this.anchor.activeRouteOnTime[1] >= 0) {
        hours = Math.floor(this.anchor.activeRouteOnTime[1] / 3600);
        minutes = Math.floor((this.anchor.activeRouteOnTime[1] % 3600) / 60);
      } else {
        hours = Math.ceil(this.anchor.activeRouteOnTime[1] / 3600);
        minutes = Math.ceil((this.anchor.activeRouteOnTime[1] % 3600) / 60);
      }

      if (Math.abs(hours) >= 1)
        return (
          (minutes > 0 ? "+" + hours.toString() : hours.toString()) +
          "h:" +
          minutes.toString() +
          "m"
        );
      else
        return (
          (minutes > 0 ? "+" + minutes.toString() : minutes.toString()) + "m"
        );
    },

    getTimeDiff(unixDate, unixPrediction) {
      const date = moment(moment.unix(unixDate), "DD/MM/YYYY HH:mm:ss");
      const now = moment(moment.unix(unixPrediction), "DD/MM/YYYY HH:mm:ss");
      const diff = moment.duration(date.diff(now));
      const days = parseInt(diff.asDays());
      const hours = parseInt(diff.asHours());
      let minutes = parseInt(diff.asMinutes());
      minutes = minutes - (days * 24 * 60 + hours * 60);

      const isNegative = hours < 0 || minutes < 0;
      let finalDate = "";

      if (hours != 0) {
        finalDate += `${Math.abs(hours)}h `;
      }

      finalDate += `${Math.abs(minutes)} min`;
      finalDate = isNegative ? `-${finalDate}` : finalDate;

      return {
        status: isNegative ? "negative" : "positive",
        diff: finalDate
      };
    },

    calculatePrectionTable() {
      const positionInfo = this.anchor.currentRoutePositionLog;
      const lastPosition = this.anchor.lastPositionMetadata;

      const orderedArray = this.predictionAvgs.data
        .filter(elem => elem.ord > positionInfo.ord)
        .concat(
          this.predictionAvgs.data.filter(elem => elem.ord <= positionInfo.ord)
        );

      const newPredictions = [];

      if (lastPosition.state == "ENTERED") {
        let nextTime =
          lastPosition.enterTs +
          orderedArray[orderedArray.length - 1].avgStay +
          orderedArray[orderedArray.length - 1].avgTrip;
        if (
          nextTime <
          Math.ceil(
            moment()
              .utc()
              .valueOf() / 1000
          )
        )
          nextTime =
            Math.ceil(
              moment()
                .utc()
                .valueOf() / 1000
            ) + orderedArray[orderedArray.length - 1].avgTrip;

        newPredictions.push({
          tagPositionId: orderedArray[0].tagPositionId,
          ord: orderedArray[0].ord,
          prediction: nextTime,
          state: "WAITING"
        });
      } else if (lastPosition.state == "LEFT") {
        let nextTime =
          lastPosition.lastSeenTs +
          orderedArray[orderedArray.length - 1].avgTrip;
        if (
          nextTime <
          Math.ceil(
            moment()
              .utc()
              .valueOf() / 1000
          )
        )
          nextTime = Math.ceil(
            moment()
              .utc()
              .valueOf() / 1000
          );

        newPredictions.push({
          tagPositionId: orderedArray[0].tagPositionId,
          ord: orderedArray[0].ord,
          prediction: nextTime,
          state: "ON_THE_WAY"
        });
      } else {
        //this.updateAnchor();
        this.emptyData = true;
        return;
      }

      for (let i = 1; i < orderedArray.length; i++) {
        newPredictions.push({
          tagPositionId: orderedArray[i].tagPositionId,
          ord: orderedArray[i].ord,
          prediction:
            newPredictions[i - 1].prediction +
            orderedArray[i].avgStay +
            orderedArray[i].avgTrip,
          state: "WAITING"
        });
      }

      if (lastPosition.state == "ENTERED") {
        newPredictions[newPredictions.length - 1].prediction =
          lastPosition.enterTs;
        newPredictions[newPredictions.length - 1].state = "CURRENTLY_HERE";
      } else if (lastPosition.state == "LEFT") {
        newPredictions[newPredictions.length - 1].state = "LEFT";
      }

      newPredictions.unshift(newPredictions.pop());

      /*newRes.data.forEach(elem => {
        const obj = {}
        obj['ord'] = elem.ord
        obj['expected_time'] = moment.unix(Math.ceil(moment().utc() / 1000) + elem.avgStay + elem.avgTrip).format("HH:mm")
        newPredictions.push(obj)
      })*/

      // Keep updating anchor info to get the connection status
      //this.updateAnchor();

      if (newPredictions.length > 0) {
        this.prediction = newPredictions;
        this.setSelectData();
        this.setTableData();
      } else {
        this.emptyData = true;
      }
    },

    async getAnchorPrediction() {
      if (this.anchor.activeRoute) {
        try {
          this.predictionAvgs = await AnchorsApi.getAnchorPredictionAvgs(
            this.anchor.anchorId
          );

          this.calculatePrectionTable();
        } catch (err) {
          if (err.response.status !== 422 && err.response.status !== 404) {
            this.$notify.error({
              title: this.$t("statusMessages.error"),
              message: this.$t("messages.supportErrorMessage")
            });
          }
        }
      }
    },

    setData() {
      if (this.basedOnGatewayPosition) {
        this.selectData;
      }

      this.setSelectData();
      this.setTableData();
    },

    setSelectData() {
      this.selectData = [];
      this.resources.data.tags.forEach(_tag => {
        let prediction = this.prediction.filter(
          prediction => prediction.tagPositionId === _tag.lastPosition?.id
        );

        if (prediction.length) {
          prediction = prediction.map(_entry => {
            _entry.tagId = _tag.tagId;
            _entry.label = _tag.label;
            return _entry;
          });
          this.selectData.push(...prediction);
        }
      });

      // ord data on select by "ord" in active route
      this.selectData = this.selectData.sort((a, b) =>
        a.ord > b.ord ? 1 : -1
      );
    },

    setTableData() {
      if (this.emptyData) return false;

      this.tableData = [];
      let topTagIndex = -1;

      if (this.basedOnGatewayPosition) {
        topTagIndex = this.prediction.findIndex(
          prediction => prediction.state === "CURRENTLY_HERE"
        );

        if (topTagIndex < 0) {
          topTagIndex = this.prediction.findIndex(
            prediction => prediction.state === "LEFT"
          );
        }
      } else {
        topTagIndex = this.prediction.findIndex(
          prediction => prediction.ord === this.tagOnTopOrd
        );
      }

      let l = 0,
        i = Math.max(0, topTagIndex);

      if (this.selectData.filter(element => element.ord === 0).length !== 0)
        this.numberOfRows = this.selectData.length;

      while (l < this.numberOfRows) {
        if (i === this.prediction.length) i = 0;
        if (
          this.tableData.filter(element => element === this.prediction[i])
            .length === 0
        )
          this.tableData.push(this.prediction[i]);
        l++;
        i++;
      }
    },

    getTagInfo(ord) {
      const _tag = this.selectData.find(tag => tag.ord === ord);
      return { label: _tag.label, tagId: _tag.tagId };
    },

    isAnchorConnected(createdDate) {
      const oneMinuteAgo = moment()
        .utc()
        .subtract(60, "seconds");

      return !moment.unix(createdDate).isAfter(oneMinuteAgo);
    },

    tableRowClassName(prediction) {
      if (prediction.row.state === "CURRENTLY_HERE") {
        return "currently-here";
      }

      if (prediction.row.state === "ON_THE_WAY") {
        return "on-the-way";
      }

      return "";
    },

    async updateAnchor() {
      try {
        const { data } = await AnchorsApi.getAnchor(this.anchor.anchorId);
        this.anchor = data;
      } catch (_) {
        // DO nothing
      }
    },

    redirectToGlobalPredictions() {
      this.$router.push({ name: routerTypes.ROUTE_PREDICTIONS_GLOBAL });
    }
  },

  async created() {
    if (!this.$route.params.anchorId && this.selectedAnchor) {
      this.$router.replace({
        name: "predictions-fullscreen-mode",
        params: {
          anchorId: this.selectedAnchor.anchorId
        }
      });
    }

    this.anchor = this.selectedAnchor;
    this.selectedAnchorId = this.selectedAnchor.anchorId;

    if (this.anchor) {
      this.getAnchorPrediction();

      //this.interval = setInterval(this.getAnchorPrediction, 2000);
      this.anchorInterval = setInterval(this.updateAnchor, 2000);

      this.predictionTableInterval = setInterval(
        this.calculatePrectionTable,
        2000
      );

      this.currentTime = moment().format("HH:mm");
      this.currentTimeInterval = setInterval(
        function(_self) {
          _self.currentTime = moment().format("HH:mm");
        },
        60000,
        this
      );
    }
  },

  beforeDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }

    if (this.anchorInterval) clearInterval(this.anchorInterval);

    if (this.predictionTableInterval)
      clearInterval(this.predictionTableInterval);

    if (this.currentTimeInterval) {
      clearInterval(this.currentTimeInterval);
    }
  }
};
</script>

<style lang="scss">
.fullscreen-wrapper {
  padding: 24px 0;

  &__anchors {
    .el-table__body td {
      cursor: pointer;
    }
  }

  &__anchor {
    .wifi__no-connection {
      top: 0;
    }

    svg {
      position: relative;
      top: 20px;
      left: 7px;
    }
  }

  .anchor__offline {
    margin-left: 12px;
    color: $--color-danger;
    font-weight: bold;
  }

  .warning-message-wrapper {
    display: flex;
    justify-content: center;
    color: #c21d1a;
    margin: 10px 10px 15px;

    .warning-message {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }

  &__details {
    .gateway-header {
      display: flex;
      gap: 10px;
      justify-content: center;
      align-items: center;

      .gateway-selector {
        display: flex;
        align-items: center;
        gap: 10px;
      }

      .el-button {
        margin-bottom: 0;
      }
    }
    &__clock {
      display: inline-block;
      color: white;
      background-color: #2f2d2d91;
      border-radius: 4px;
      padding: 8px;
      margin-bottom: 16px;
    }

    .el-table {
      max-width: 98%;
      margin: 0 auto;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12), 0 0 6px 0 rgba(0, 0, 0, 0.04);
    }

    .el-table__header-wrapper .el-table__header thead tr th {
      background-color: $--color-primary;

      > .cell {
        text-align: center;
        color: white;
      }
    }

    .el-table__body-wrapper .el-table__body tbody tr td {
      background-color: #f7fcff;

      > .cell {
        text-align: center;
        color: $--color-primary;

        .entry--negative {
          color: $--color-danger;
        }

        .entry--positive {
          color: $--color-success;
        }
      }
    }

    .el-table__row td {
      background-color: transparent !important;
    }

    .el-table__row.currently-here {
      background: rgba(50, 205, 50, 0.7);

      .cell {
        color: #2c3e50;
        font-weight: bold;
      }
    }

    .el-table__row.on-the-way {
      background: rgba(255, 184, 51, 0.7);
      animation: highlightOnTheWay 1s infinite;

      .cell {
        color: #2c3e50;
        font-weight: bold;
      }
    }

    .el-button {
      margin-bottom: 20px;
    }

    .fullscreen.fullscreen__on {
      position: relative;
      background: white !important;

      .fullscreen-wrapper__anchor {
        position: relative;
        top: -18px;
        background: white;
      }

      .fullscreen-wrapper__details__clock {
        position: absolute;
        bottom: 0;
        right: 15px;
        z-index: 1000;
      }

      .el-table {
        height: calc(100vh - 65px);
        max-width: 100%;

        .cell {
          line-height: 50px;
        }
      }

      .el-table.table__rows--5
        .el-table__header-wrapper
        .el-table__header
        thead
        tr
        th {
        height: calc(16.66vh - 11px) !important;
        font-size: 3vmin;
      }

      .el-table.table__rows--5
        .el-table__body-wrapper
        .el-table__body
        tbody
        tr
        td {
        height: calc(16.66vh - 11px) !important;
        font-size: 3vmin;

        > .cell {
          i {
            font-size: 6vmin;
          }
        }
      }

      .el-table.table__rows--6
        .el-table__header-wrapper
        .el-table__header
        thead
        tr
        th {
        height: calc(14.28vh - 10px) !important;
        font-size: 3vmin;
      }

      .el-table.table__rows--6
        .el-table__body-wrapper
        .el-table__body
        tbody
        tr
        td {
        height: calc(14.28vh - 10px) !important;
        font-size: 3vmin;

        > .cell {
          i {
            font-size: 6vmin;
          }
        }
      }

      .el-table.table__rows--7
        .el-table__header-wrapper
        .el-table__header
        thead
        tr
        th {
        height: calc(12.5vh - 9px) !important;
        font-size: 3vmin;
      }

      .el-table.table__rows--7
        .el-table__body-wrapper
        .el-table__body
        tbody
        tr
        td {
        height: calc(12.5vh - 9px) !important;
        font-size: 3vmin;

        > .cell {
          i {
            font-size: 6vmin;
          }
        }
      }

      .el-table.table__rows--8
        .el-table__header-wrapper
        .el-table__header
        thead
        tr
        th {
        height: calc(11.11vh - 8px) !important;
        font-size: 3vmin;
      }

      .el-table.table__rows--8
        .el-table__body-wrapper
        .el-table__body
        tbody
        tr
        td {
        height: calc(11.11vh - 8px) !important;
        font-size: 3vmin;

        > .cell {
          i {
            font-size: 6vmin;
          }
        }
      }

      .el-table.table__rows--9
        .el-table__header-wrapper
        .el-table__header
        thead
        tr
        th {
        height: calc(10vh - 7px) !important;
        font-size: 3vmin;
      }

      .el-table.table__rows--9
        .el-table__body-wrapper
        .el-table__body
        tbody
        tr
        td {
        height: calc(10vh - 7px) !important;
        font-size: 3vmin;

        > .cell {
          i {
            font-size: 6vmin;
          }
        }
      }

      .el-table.table__rows--10
        .el-table__header-wrapper
        .el-table__header
        thead
        tr
        th {
        height: calc(9.09vh - 6px) !important;
        font-size: 3vmin;
      }

      .el-table.table__rows--10
        .el-table__body-wrapper
        .el-table__body
        tbody
        tr
        td {
        height: calc(9.09vh - 6px) !important;
        font-size: 3vmin;

        > .cell {
          i {
            font-size: 6vmin;
          }
        }
      }
    }

    .fullscreen__close {
      position: absolute;
      top: 10px;
      right: 40px;
      z-index: 1000;
      border: 1px solid #dcdfe6;
      padding: 10px 12px;
      font-size: 20px;
      background: rgba(255, 255, 255, 0.8);
      border-radius: 50%;
      cursor: pointer;
    }
  }

  &__options {
    max-width: 606px;
    display: block;
    margin: 24px auto;
    padding: 24px;
    background: #ffffff;
    border: 1px solid #dcdfe6;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12), 0 0 6px 0 rgba(0, 0, 0, 0.04);
    display: flex;

    &--left {
      flex: 0 0 50%;

      p {
        text-align: left;
        font-size: 13px;
      }

      .el-checkbox {
        width: 100%;
        margin: 14px 0;
        text-align: left;
      }

      .el-select {
        width: 100%;
      }
    }

    &--right {
      p {
        text-align: left;
        font-size: 13px;
        margin-left: 56px;
      }

      .el-input-number {
        width: 100%;
        margin: 14px 0;
        max-width: 190px;
      }
    }
  }
}

@keyframes highlightOnTheWay {
  0% {
    background: rgba(255, 184, 51, 0.7) !important;
  }

  50% {
    background: transparent;
  }

  100% {
    background: rgba(255, 184, 51, 0.7) !important;
  }
}
</style>
